export const environment = {
  production: true,
  app_version: '2.0.1',
  api_v: 'api/v1',
  ESCALAS_WSPREFIX: 'https://api.escalas.io',
  AWS_REST_WSPREFIX: 'https://api.escalas.io',
  TASK_REST_WSPREFIX: 'https://tasks.escalas.io',
  SUPPORT_REST_WSPREFIX: 'https://srv.iomx.co/',
  MAPBOX_TOKEN: 'pk.eyJ1IjoicmljaG9sb25hIiwiYSI6ImNsM24wb3dwdDA5dmczZHBhb3BiYmRnZGYifQ.D0tt4iTHsGf4riM2cazCog',
  FIREBASE_WSPREFIX: 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=',
  FIREBASE_TOKEN: 'AIzaSyCdXA6EqFzjOZscCmP3sMwP4iRWBrFapCU',
  siteKey: '6Le1Ac0qAAAAAAzeXhCH93V9s-8y6893kIsivgKD', //'6LfGuwcqAAAAAC20kjI7v1lJOYvaoGy0br_S4y4I', // DD781CFD-26C1-472A-B73F-65C9C7323C05 // 
  FIREBASE_APPCHECK_DEBUG_TOKEN: true,
  firebaseConfig: {
    apiKey: "AIzaSyB57sShWssxfxtK0nGl7rAfc98zNixt6Ew",
    authDomain: "escalas-ac05f.firebaseapp.com",
    projectId: "escalas-ac05f",
    storageBucket: "escalas-ac05f.appspot.com",
    messagingSenderId: "450034301345",
    appId: "1:450034301345:web:f5b4d1914624b9a17a33e0",
    measurementId: "G-ZKSL3G3SSF",
    vapidKey: 'BNTjEgmxxC4ZlBHv4kRND5Jr3n5lBaU5FNuh7E-tcFHqktbc-3VL8T7HlOlOR0Rt1mF8c_9XG1M1YNaALmAhCVc',
    appCheckSiteKey: '6Le1Ac0qAAAAAAzeXhCH93V9s-8y6893kIsivgKD'
  }
};