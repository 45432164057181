import { faEdit, faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-users',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Administradores</h1>
            <p>Administra a los usuarios administradores del sistema.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <button class="btn btn-primary btn-lg rounded-5" routerLink="create" *ngIf="permissions?.modules.config.admins.write">Agregar usuario</button>
          </div>
        </div>
        <div class="card border-0 rounded-4 shadow">
          <div class="card-body py-4 px-5">
          <div class="row">
              <div class="col-md-7">
              </div>
              <div class="col-md-5">
                <form [formGroup]="searchBox" (ngSubmit)="searchUsers($event)">
                  <input class="form-control form-control-lg bg-transparent shadow" placeholder="Buscar..." formControlName="search">
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-5">
                <div class="list-group rounded-4 shadow">
                  <div class="list-group-item bg-light py-3 px-4" *ngFor="let user of users | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                    <div class="row">
                      <div class="col-md-1 pt-2 text-center">
                        <!-- app-item-tiny-user [user]="user"></!-->
                      </div>
                      <div class="col-md-8 mt-1">
                        <h4 class="mb-0">{{user.name}} {{user.last_name}}</h4>
                        <p class="mb-0 text-secondary">{{user.email}}</p>
                      </div>
                      <div class="col-md-3 text-end pt-1" *ngIf="user.role_id != 1">
                        <button type="button" routerLink="profile/{{user.id}}" *ngIf="permissions?.modules.config.admins.write" class="btn btn-link pt-0 pe-0">
                          <fa-icon [icon]="faEdit" size="1x"></fa-icon>
                        </button>
                        <button type="button" class="btn btn-link text-danger ms-1 pt-0 pe-0" *ngIf="permissions?.modules.config.admins.write" (click)="removeUser(user.id)">
                          <fa-icon [icon]="faTrashAlt"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group rounded-4 shadow" *ngIf="users.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="faCircleNotch" size="2x" animation="spin" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-end mt-4" >
                <pagination-controls
                  (pageChange)="p = $event"
                  (pageChange)="pageChanged($event)"
                  previousLabel="Prev."
                  nextLabel="Sig."
                  screenReaderPaginationLabel="Pagination"
                  screenReaderPageLabel="page"
                  *ngIf="users.length>0"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class UsersComponent implements OnInit {
  loading: boolean = false;
  users: any[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  searchBox: FormGroup = new FormGroup({});

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;

  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faCircleNotch = faCircleNotch;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit() {    
    this.loadUsers(this.payload);
    this.initForm(); 
  }

  loadUsers(payload: any) {
    this.loading = true;
    // this.store.getUsers(payload).subscribe({
      // next: (users: any) => {
        // this.users = users.data;
        // this.meta = users.meta;
        // this.loading = false;
      // },
      // error: (err: any) => {
        // this.toastr.warning('No se encontraron registros', 'Error');
        // this.users = [];
        // this.loading = false;
      // }
    // });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  searchUsers(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadUsers(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadUsers(this.payload);
  }

  removeUser(id: number) {
    // this.store.destroyUser(id).subscribe({
      // next: (data: any) => {
        // this.toastr.success('Usuario eliminado correctamente.', 'Éxito');
        // this.loadUsers(this.payload);
      // },
      // error: (err: any) => {
        // this.toastr.error('Ocurrió un error al eliminar el usuario.', 'Error');        
      // },
    // });
  }
}
