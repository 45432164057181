import { Component, Input, SimpleChanges, TemplateRef } from '@angular/core';
import * as bs from 'bootstrap';
import { IconsService } from '../services/icons.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { OffcanvasNavbarComponent } from 'src/app/components/offcanvas/offcanvas-navbar.component';

@Component({
  selector: 'app-navbar',
  template: `
    <header class="navbar navbar-expand-lg bd-navbar bg-white shadow-sm sticky-top d-sm-block d-md-none">
      <nav class="container-xxl bd-gutter flex-wrap flex-lg-nowrap justify-content-between">
        <div class="bd-navbar-toggle">
          <button class="navbar-toggler border-0 text-dark" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation" (click)="open()">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
        <a class="navbar-brand p-0 me-0 me-lg-2" href="#"><img src="assets/images/logo-escalas-r512x512.png" width="44px"></a>
        <div class="d-flex"></div>        
      </nav>
    </header>
  `,
  styleUrls: []
})
export class NavbarComponent {
  @Input() user: any;
  
  @Input() profileLogo: any;

  permissions: any;
  school: any;

  roleLS: any = {};

  isNavbarCollapsed = true;

  protected version = environment.app_version;

  avatarURL: string = '';

  constructor(
    private router: Router,
    public icons: IconsService,
    private offcanvasService: NgbOffcanvas
  ) {
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
  }

  open() {
    const offcanvasRef = this.offcanvasService.open(OffcanvasNavbarComponent, { 
      position: 'end', 
      animation: true, 
      backdrop: true, 
      keyboard: true,
      scroll: true, 
      backdropClass: 'bg-dark', 
      panelClass: 'bg-light' 
    });
    offcanvasRef.componentInstance.school = this.school;
    offcanvasRef.componentInstance.permissions = this.permissions;
    offcanvasRef.result.then((result) => {
      
    }).catch((error) => {
      
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user'] && changes['user'].currentValue) {
      this.user = this.user;
    }
  }

  signOut() {
    let redirect = this.roleLS?.group_id == 3 ? '/user/sign-in' : '/ingresar';
    localStorage.removeItem('preferences');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('fcm_token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('permissions');
    localStorage.removeItem('school');
    localStorage.removeItem('campuses');
    //
    window.location.href = redirect;
    // window.location.reload();
  }
}
