import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: '[app-tr-families]',
  template: `
    <td class="align-middle">{{family.name}}</td>
    <td class="align-middle">{{family.code}}</td>
    <td class="align-middle">{{family.email}}</td>
    <td class="align-middle">{{family.name}}</td>
    <td class="text-end">
      <button class="btn btn-sm btn-outline-primary" routerLink="{{family.family_id}}">
      <fa-icon [icon]="icons.faEdit"></fa-icon>
      </button>
    </td>
  `,
  styleUrls: []
})
export class TrFamiliesComponent {
  @Input() family: any;
  @Input() permissions: any;

  @Output() updateFamily = new EventEmitter<any>();
  @Output() deleteFamily = new EventEmitter<any>();

  constructor(
    public icons: IconsService
  ) { }

}
