import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  doDateRangesOverlap(startDate1: Date | string, endDate1: Date | string, startDate2: Date | string, endDate2: Date | string): boolean {
    // Convert strings to Date objects if necessary
    const start1 = new Date(startDate1);
    const end1 = new Date(endDate1);
    const start2 = new Date(startDate2);
    const end2 = new Date(endDate2);

    // Check if the date ranges overlap
    return start1 < end2 && start2 < end1;
  }

  addMinutesToDate(dateString: string, minutes: number) {    
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + minutes);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const mins = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

    // Constructing the timezone part "-06:00"
    // This part is tricky because JavaScript does not provide a direct way to get the timezone offset in hours and minutes in the format "+HH:MM" or "-HH:MM"
    // The getTimezoneOffset() method returns the difference, in minutes, from UTC to local time. Note that this is inverted; e.g., for UTC-6, it returns 360.
    const timezoneOffset = date.getTimezoneOffset();
    const sign = timezoneOffset > 0 ? "-" : "+";
    const offsetHours = Math.abs(Math.floor(timezoneOffset / 60)).toString().padStart(2, '0');
    const offsetMinutes = Math.abs(timezoneOffset % 60).toString().padStart(2, '0');
    const timezoneFormat = `${sign}${offsetHours}:${offsetMinutes}`;
    const newDate: string = `${year}-${month}-${day}T${hours}:${mins}:${seconds}.${milliseconds}${timezoneFormat}`;

    return newDate;
  }

  getStartAndEndDateOfMonth(year: number, month: number) {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);

    return {
      start_date: startDate.toISOString().substring(0, 10), // Format as "YYYY-MM-DD"
      end_date: endDate.toISOString().substring(0, 10) // Format as "YYYY-MM-DD"
    }
  }

  getPeriodo(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return month;
  }

  formatYMD(data: string) {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}
