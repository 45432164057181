import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as bs from 'bootstrap';
import { IconsService } from '../services/icons.service';
import { School } from 'src/app/models/school.model';

@Component({
  selector: 'app-sidebar',
  template: `

    <div class="main-nav nav-holder-for-fixed"></div>

    <div #sidebar id="sidebar" class="main-nav d-flex flex-column flex-shrink-0 p-3 d-none d-md-block fixed-top border border-0 border-dark" style="height: 98vh;">
      <div class="d-flex text-dark main-nav" >
        <div>
          <img src="assets/images/logo-escalas-r512x512.png" width="34px">
        </div>
        <div class="flex-fill text-start align-middle ms-3">
          <h2 class="mb-0 mt-1" style="font-size: 1.4em;">Escalas</h2>
        </div>
        <div class="ms-auto me-3" style="margin-top: 5px;">
          <small style="font-size: 13px;"><b>v{{version}}</b></small>
          <fa-icon [icon]="icons.faUpRightFromSquare" class="ms-2 text-secondary" style="font-size: 0.8em;" routerLink=""></fa-icon>
        </div>
      </div>

      <ul class="nav nav-pills flex-column mb-auto mt-3" *ngIf="school">    
        <li class="nav-item" *ngIf="permissions?.menu?.dashboard">
          <a #dashboard id="dashboard" class="d-flex nav-link active"
            routerLink="dashboard"
            [style.background-color]="dashboard.classList.contains('active') ? school.color : ''"
            [style.color]="dashboard.classList.contains('active') ? school.text_color +' !important' : ''"
            (click)="navigate($event)"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faDatabase"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Dashboard</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.schools&&school?.school_id">
          <a #schools id="schools" class="d-flex nav-link" 
            routerLink="/schools/{{school.school_id}}" 
            [style.background-color]="schools.classList.contains('active') ? school.color : ''" 
            [style.color]="schools.classList.contains('active') ? school.text_color +' !important' : ''" 
            (click)="navigate($event)"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faGraduationCap"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Mi escuela</span>
            </div>
          </a>
        </li>
        <li class="nav-item " *ngIf="permissions?.menu?.notices">
          <a #notices id="notices" class="d-flex nav-link"
            routerLink="/notices/bulletins"
            [style.background-color]="notices.classList.contains('active') ? school.color : ''"
            [style.color]="notices.classList.contains('active') ? school.text_color +' !important' : ''"
            (click)="navigate($event)"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faBullhorn"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Comunicados</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.calendar">
          <a #calendar id="calendar" class="d-flex nav-link"
            routerLink="/calendar"
            [style.background-color]="calendar.classList.contains('active') ? school.color : ''"
            [style.color]="calendar.classList.contains('active') ? school.text_color +' !important' : ''"
            (click)="navigate($event)"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faCalendar"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Calendario</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.professors">
          <a #professors id="professors" class="d-flex nav-link"
            routerLink="/professors" 
            [style.background-color]="professors.classList.contains('active') ? school.color : ''"
            [style.color]="professors.classList.contains('active') ? school.text_color +' !important' : ''"
            (click)="navigate($event)"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faChalkboardUser"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Profesores</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.students">
          <a #students id="students" class="d-flex nav-link"
            routerLink="/students"
            [style.background-color]="students.classList.contains('active') ? school.color : ''"
            [style.color]="students.classList.contains('active') ? school.text_color +' !important' : ''"
            (click)="navigate($event)"
          >
            <div class="ms-auto text-center" style="width: 30px;">
            <fa-icon [icon]="icons.faChild"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Alumnos</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.tutors">
          <a #tutors id="tutors" class="d-flex nav-link"
            routerLink="/tutors"
            [style.background-color]="tutors.classList.contains('active') ? school.color : ''"
            [style.color]="tutors.classList.contains('active') ? school.text_color +' !important' : ''"
            (click)="navigate($event)"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faUser"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Padres</span>
            </div>
          </a>
        </li>
      </ul>
      <div style="position: absolute; bottom: 8px;">
        <ul class="nav nav-pills flex-column" *ngIf="permissions?.menu?.notices">
          <li class="nav-item ps-2">
            <a #uploader id="uploader"
              routerLink=""
              [style.background-color]="uploader.classList.contains('active') ? school?.color : ''"
              [style.color]="uploader.classList.contains('active') ? school?.text_color +' !important' : ''"
              class="d-flex nav-link"
              (click)="navigate($event)"
            >
              <div class="ms-auto text-center" style="width: 30px;">
                <fa-icon [icon]="icons.faUpload"></fa-icon>
              </div>
              <div class="flex-fill ms-3">
                <span>Cargar datos</span>
              </div>
            </a>
          </li>
          <li class="nav-item ps-2" *ngIf="permissions?.menu?.settings">
            <a #settings id="settings" class="d-flex nav-link"
              routerLink="/settings"
              [style.background-color]="settings.classList.contains('active') ? school?.color : ''"
              [style.color]="settings.classList.contains('active') ?  school?.text_color +' !important' : ''"
              (click)="navigate($event)"
            >
              <div class="ms-auto text-center" style="width: 30px;">
                <fa-icon [icon]="icons.faCog"></fa-icon>
              </div>
              <div class="flex-fill ms-3">
                <span>Configuración</span>
              </div>
            </a>
          </li>
          <li class="nav-item ps-2" *ngIf="permissions?.menu?.notifications">
            <a #notifications id="notifications" class="d-flex nav-link"
              routerLink="/notifications"
              [style.background-color]="notifications.classList.contains('active') ? school?.color : ''"
              [style.color]="notifications.classList.contains('active') ? school?.text_color +' !important' : ''"
              (click)="navigate($event)"
            >
              <div class="ms-auto text-center" style="width: 30px;">
                <fa-icon [icon]="icons.faBell"></fa-icon>
              </div>
              <div class="flex-fill ms-3">
                <span>Notificaciones</span>
              </div>
              <div class="text-end me-2 mt-1">
                <div class="rounded-5 bg-primary text-light text-center" style="width: 18px; height: 18px; font-size: 10px; padding-top: 2px;">2</div>
              </div>
            </a>
          </li>
          <li class="nav-item pb-3 px-2">
            <a class="d-flex nav-link" (click)="signOut()">
              <div class="ms-auto text-center" style="width: 30px;">
                <fa-icon [icon]="icons.faSignOutAlt"></fa-icon>
              </div>
              <div class="flex-fill ms-3">
                <span>Cerrar sesión</span>
              </div>
            </a>
          </li>
        </ul>
        <div class="d-flex" *ngIf="school">
          <div class="d-flex border border-1 border-secondary bg-white rounded-4 ps-2 pe-3 py-2 shadow-sm">
            <div class="ms-2" style="margin-top: 2px;">
              <img src="https://api.escalas.io{{school!.logo!.url}}" width="44" *ngIf="school">
            </div>
            <div class="flex-fill ms-3 mt-1">
              <h4 class="mb-0" style="line-height: 0.9; font-size: 1.1rem;">{{school.name}}</h4>
              <p class="mb-0 text-sm text-wrap" style="font-size: 0.8em;">{{school.description}}</p>
            </div>
          </div>
        </div>
        <div class="d-flex mt-3 text-center">
          <div class="flex-fill">
            <p class="text-sm mb-0" style="font-size: 0.7em;">Powered by <a href="#">Input Output Co.</a></p>
          </div>
          <!-- div class="flex-fill text-end"><p class="text-sm text-muted">© 2025 Todos los derechos reservados</p></!-->
        </div>
      </div>
      
      
    </div>
  `,
  styleUrls: []
})
export class SidebarComponent {
  @Input() user: any;
  @Input() profile: any;
  @Input() profileLogo: any;
  @ViewChild('sidebar') sidebar !: ElementRef;
  @ViewChild('dashboard') dashboard !: ElementRef;

  userLS: any = {};
  roleLS: any = {};
  permissionsLS: any = {};
  avatarLS: any = {};

  permissions: any;
  school: School | null = null;
  currentRoute: string = '';
  isActive: boolean = false;

  protected version = environment.app_version;

  constructor(
    public icons: IconsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.userLS = JSON.parse(localStorage.getItem('user') || '{}');
    this.avatarLS = JSON.parse(localStorage.getItem('avatar') || '{}');
    this.roleLS = JSON.parse(localStorage.getItem('role') || '{}').role;
    this.permissionsLS = JSON.parse(localStorage.getItem('permissions') || '{}');

    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url.split("/")[1];
        if (this.currentRoute == '') {
          this.currentRoute = 'dashboard';
        }
        this.sidebar?.nativeElement.querySelectorAll('.nav-link').forEach((el: any) => {
          el.classList.remove('active');
        });
        let link: any = this.sidebar?.nativeElement.querySelector(`#${this.currentRoute}`);
        if (link) {
          link.classList.add('active');
        }
      }
    });
    
  }

  navigate(e: any, collapse: string = '') {
    let link: any = e.target.closest('a');
    this.sidebar?.nativeElement.querySelectorAll('.nav-link').forEach((el: any) => {
      el.classList.remove('active');
    });

    if (collapse) {
      if (this.sidebar?.nativeElement.querySelector(`#${collapse}`).classList.contains('collapse')) {
        this.sidebar?.nativeElement.querySelector(`#${collapse}`).classList.remove('collapse')
        this.sidebar?.nativeElement.querySelector(`#${collapse}`).classList.add('active');
      } else {
        this.sidebar?.nativeElement.querySelector(`#${collapse}`).classList.remove('active')
        this.sidebar?.nativeElement.querySelector(`#${collapse}`).classList.add('collapse');
      }
    }
    
    link.classList.add('active');    
  }

  signOut() {
    let redirect = this.roleLS?.group_id == 3 ? '/user/sign-in' : '/ingresar';
    localStorage.removeItem('preferences');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('fcm_token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('permissions');
    localStorage.removeItem('school');
    localStorage.removeItem('campuses');
    //
    window.location.href = redirect;
    // window.location.reload();
  }

  toggleNavbar() {

  }
}
