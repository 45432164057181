import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-role-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 mt-4">
      
        <div class="row mb-4">
          <div class="col-md-8">
            <h1 class="mt-4">Rol</h1>
            <p>Editar</p>
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="card border-0 rounded-4 mb-5">
          <div class="card-body p-4">
            <div class="row">
              <div class="col-md-12">
                <app-form-role (roleData)="updateRole($event)" [role]="role"></app-form-role> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class RoleEditComponent implements OnInit {
    role: any = null;
    
    constructor(
      private toastr: ToastrService,
      private router: Router,
      private activateRoute: ActivatedRoute,
      private breadcrumbService: BreadcrumbService,
    ) {
      
     }

    ngOnInit(): void {
      const ctl = this;
      this.activateRoute.params.subscribe(params => {
        // this.store.getRole(params['id']).subscribe({
          // next: (res: any) => {
            // ctl.role = res;
            // this.breadcrumbService.set('@roleEdit', res.name);
          // },
          // error: (err: any) => {
            // this.toastr.error('Ocurrió un error al obtener el rol.', 'Error');
          // }
        // });
      });
    }

    updateRole(event: any) {

      // this.store.updateRole(this.role.id, event).subscribe({
        // next: (res: any) => {
          // this.toastr.success('El rol se actualizó correctamente.', 'Actualizado');
          // this.router.navigate(['/roles']);
        // },
        // error: (err: any) => {
          // this.toastr.error('Ocurrió un error al actualizar el rol.', 'Error');
        // }
      // });
    }


}
