import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ImageService } from './shared/services/image.service';
import { IconsService } from './shared/services/icons.service';
import { AppCheckService } from './services/app-check.service';

@Component({
  selector: 'app-root',
  template: `
    <app-navbar *ngIf="user" [user]="user" [profileLogo]="profileLogo"></app-navbar>
    <div class="d-flex clearfix">
      <div class="d-none d-sm-none d-md-block">
        <app-sidebar *ngIf="user" [user]="user" [profile]="profile" [profileLogo]="profileLogo"></app-sidebar>
      </div>
      <div class="main-container flex-fill mx-3 my-5 m-md-3 mb-0" role="main" (window:resize)="onResize($event)">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-notify-floater></app-notify-floater>
  `,
  styleUrls: []
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  private apiUrl: string = environment.AWS_REST_WSPREFIX;
  protected version = environment.app_version;

  ux: any = localStorage.getItem('user')
  user: any = this.ux != null ? JSON.parse(this.ux) : null;
  profile: any = localStorage.getItem('profile');
  profiles: any = [];
  role: any = {};
  permissions: any = {};
  profileLogo: any = '';
  school: any = {};

  superadmin: boolean = false;
  avatarURL: string = '';
  avatarLS: any = null;

  captchaResponse: string = '';

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private imgService: ImageService,
    public icons: IconsService,
    private appCheck: AppCheckService
  ) {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ux = localStorage.getItem('user');
        this.user = this.ux != null ? JSON.parse(this.ux) : null;
        this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
        this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
        this.role = localStorage.getItem('role') != null ? JSON.parse(localStorage.getItem('role')!).role : null;
        this.permissions = JSON.parse(localStorage.getItem('permissions')!);
        this.superadmin = this.role?.id == 1 ? true : false;

        if (this.profile != null) {
          this.profileLogo = this.profile.logo != null ? this.imgService.buildURL(this.profile.logo) : 'assets/images/logo-zazz.png';
          if (this.avatarLS == null) {
            /*this.store.getUser(this.user.user_id).subscribe((data: any) => {
              let el = data.avatar;
              if (el != null) {
                this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
                localStorage.setItem('avatar', JSON.stringify({ avatar: this.avatarURL}));
              } else {
                this.avatarURL = 'assets/images/logo-zazz.png';
              }
            });*/
          } else {
            this.avatarLS = JSON.parse(localStorage.getItem('avatar') || '{}');
          }
        }
      }
    });

    // this.app = initializeApp(environment.firebaseConfig);
    // this.appCheck = initializeAppCheck(this.app, {
      // provider: new ReCaptchaV3Provider(environment.siteKey),
      // isTokenAutoRefreshEnabled: true
    // });
    
  }

  ngOnInit(): void {
      console.info(`
        ESCALAS
   @@@@@@@@@@@@@@@@@@ 
  @@=--------------=@@
  @#----+******+----#@
  @#---+@+====+@=---#@
  @#---+@==%%%@@=---#@
  @#---+@=====+@=---#@
  @#---+@==%@@@@=---#@
  @#---+@======@+---#@
  @#----+******+----#@
  @@=--------------=@@
   @@@@@@@@@@@@@@@@@@ 
        `);
  }

  onResize(event: any) {}

  changeProfile(profile: any) {
    this.profile = profile;
    let currProfile = this.profiles.find((el: any) => profile.id == el.id);
    localStorage.setItem('profile', JSON.stringify({ profile: currProfile}));   
  }

  signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    localStorage.removeItem('avatar');
    localStorage.removeItem('permissions');
    localStorage.removeItem('role');
    this.router.navigate(['sign-in']);
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  dispatchHelp() {
    // let btn: any = this.btnOptionsPay.nativeElement.classList;    
    // new bs.Modal(this.btnOptionsPay.nativeElement).show();
    // new bs.Modal(<HTMLInputElement>document.getElementById('modalVideoPlayer')).show();
  }
}
