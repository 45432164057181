import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveOffcanvas, NgbOffcanvasConfig, NgbOffcanvas, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, switchMap } from 'rxjs';
import { OffcanvasStudentFilterComponent } from 'src/app/components/offcanvas/offcanvas-student-filter.component';
import { Campus } from 'src/app/models/campus.model';
import { School } from 'src/app/models/school.model';
import { Section } from 'src/app/models/section.model';
import { Student } from 'src/app/models/student.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-index-students',
  template: `
      <div class="bg-white p-4 rounded-4 shadow">
        <div class="d-flex mb-2 mb-md-4">
          <img src="https://api.escalas.io{{school.logo!.url}}" width="18" height="18" class="ms-2" *ngIf="school">
          <span class="slashy">/</span>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="d-flex mb-4">
          <div>
            <h1 class="mb-0">Alumnos <span class="text-muted" style="font-size: 0.5em;">({{meta.total_entries}})</span></h1>
          </div>
          <div class="flex-fill text-end mt-0">
            <div class="float-end">
              <!-- div class="btn-group border border-1 border-secondary me-2" role="group" aria-label="Basic example">
                <button id="btnList" #btnList type="button" class="btn btn-outline-secondary" (click)="selectView('btnList')">
                  <fa-icon [icon]="icons.faList"></fa-icon>
                </button>
                <button id="btnBloc" #btnBloc type="button" class="btn btn-outline-secondary" (click)="selectView('btnBloc')">
                <fa-icon [icon]="icons.faGrip"></fa-icon>
                </button>
              </!-->
              <div class="d-inline-block">
                <button class="btn btn-outline-secondary ms-2 border-2" (click)="open()">
                  <fa-icon [icon]="icons.faSliders" class="me-0 me-md-2"></fa-icon>
                  <span class="d-none d-sm-none d-md-inline-block">Filtrar</span>
                </button>
              </div>
              <button class="btn btn-dark ms-2" routerLink="create">
                <fa-icon [icon]="icons.faPlus" class="me-0 me-md-2"></fa-icon>
                <span class="d-none d-sm-none d-md-inline-block">Agregar alumno</span>
              </button>
            </div>
            <div class="form-group float-end me-3" style="width: 200px;">
              <div class="input-group">
                <div class="input-group-addon border border-2 rounded-start-2 border-secondary border-end-0 ps-2 text-secondary" style="padding-top: 7px;">
                  <fa-icon [icon]="icons.faSearch" ></fa-icon>
                </div>
                <!-- input class="form-control border-2 border-secondary bg-transparent border-start-0" placeholder="Buscar" -->
                <input id="typeahead-basic" type="text" class="form-control border-2 border-secondary bg-transparent border-start-0" [formControl]="searchControl" />
              </div>
            </div>
          </div>
        </div>      
        <div class="col-12 col-lg-12 offset-lg-0 mt-3 px-2 px-sm-0" *ngIf="activeView=='btnList'">

          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="bg-light text-secondary align-items-center align-middle rounded-top-left-3"><input type="checkbox" value="" id="flexCheckDefault" [value]="bulkSelect" (change)="bulkSelect = !bulkSelect"></th>
                <th class="bg-light text-secondary align-items-center align-middle text-start">Nombre</th>
                <th class="bg-light text-secondary align-items-center align-middle text-center">Campus</th>
                <th class="bg-light text-secondary align-items-center align-middle text-center">Grupos</th>
                <th class="bg-light text-secondary align-items-center align-middle rounded-top-right-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr app-tr-students 
                [student]="student" 
                [permissions]="permissions"
                [sections]="sections"
                [bulkSelect]="bulkSelect"
                (updateStudent)="saveStudent($event)"
                (deleteStudent)="removeStudent($event)"
                *ngFor="let student of students | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }"
              ></tr>
            </tbody>
          </table>
          <div class="text-center py-5" *ngIf="students.length==0&&loading">
              <fa-icon [icon]="icons.faCircleNotch" size="4x" animation="spin" class="text-escalas mt-5"></fa-icon>
              <p class="text-muted mt-5">Cargando...</p>
            </div>
            <div class="text-center py-5" *ngIf="students.length==0&&!loading">
              <img src="/assets/images/empty-state.png" width="240px">
              <h5 class="text-muted">No se encontraron registros</h5>
            </div>

          
        </div>
        <div class="col-12 col-lg-12 offset-lg-0 px-sm-0 mt-3" *ngIf="activeView=='btnBloc'">
          <div class="d-flex flex-wrap align-items-center justify-content-between">
            <div class="data-item" *ngFor="let student of students | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
              <div class="card rounded-4 overflow-hidden" style="height: 180px;">
                <div class="card-body">
                  <div class="flex-column">
                    <div class="flex-row">
                      <div class="d-inline-flex px-3 py-2 rounded-5 bg-light text-dark me-3">
                        <fa-icon [icon]="icons.faChild"></fa-icon>
                      </div>
                      <div class="d-inline-flex align-self-start border" style="width: 80%;">
                        <div class="flex-column align-self-start">
                          <h4>{{student.name}} {{student.p_last_name}} {{student.m_last_name}}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="d-flex">
                      <div class="text-muted">Familia</div>
                    </div>
                    <div class="d-flex">
                      <div class="text-sm">{{student.family_id}}</div>
                    </div>
                  </div>
                    <p class="mb-2">Familia {{student.family_id}}</p>
                    <p class="mb-2">Campus {{student.family_id}}</p>
                    <p class="mb-2">Grado {{student.family_id}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <pagination-controls
            (pageChange)="p = $event"
            (pageChange)="pageChanged($event)"
            previousLabel="Prev."
            nextLabel="Sig."
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page" 
            *ngIf="students.length>0"                 
          ></pagination-controls>
        </div>
      </div>
  `,
  styleUrls: []
})
export class IndexStudentsComponent implements OnInit, AfterViewInit {
  @ViewChild('btnList') btnList!: ElementRef;
  @ViewChild('btnBloc') btnBloc!: ElementRef;

  school: School;
  students: Student[] = [];
  meta: any = {};
  permissions: any = {};
  loading: boolean = false;
  viewPrefs: any = {};
  prefs: any = {};

  model: any;

  campuses: Campus[] = [];
  sections: Section[] = [];

  searchControl: FormControl = new FormControl(); 

  activeView: string = 'btnList';
  bulkSelect: boolean = false;

  payload: any = {
    per_page: 100,
    page: 1,
  };
  p: number = 1;

  constructor(
    private escolarService: EscolarService,
    private toastr: ToastrService,    
    public icons: IconsService,
    private offcanvasService: NgbOffcanvas
  ) {
    this.permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
    // this.viewPrefs = localStorage.getItem('viewPrefs') || '';
    this.school = JSON.parse(localStorage.getItem('school') || '{}');
    this.campuses = JSON.parse(localStorage.getItem('campuses') || '[]');
    this.prefs = JSON.parse(localStorage.getItem('preferences') || '{}');
    //
    this.activeView = this.prefs.viewPrefs || 'btnList';
    this.payload.school_id = this.school.school_id;
    this.payload.campus_id = this.prefs.campus_id ? [this.prefs.campus_id] : this.campuses.map((campus: any) => campus.campus_id);
    this.payload.section_id = this.prefs.section_id != undefined ? this.prefs.section_id : '';    
  }

  open() {
		const offcanvasRef = this.offcanvasService.open(OffcanvasStudentFilterComponent, { 
      position: 'end', 
      animation: true, 
      backdrop: true, 
      keyboard: true,
      scroll: true, 
      backdropClass: 'bg-dark', 
      panelClass: 'bg-dark' 
    });
		offcanvasRef.componentInstance.name = 'World';
    offcanvasRef.componentInstance.payload = this.payload;
    offcanvasRef.componentInstance.prefs = this.prefs;
    offcanvasRef.componentInstance.campuses = this.campuses;
    offcanvasRef.componentInstance.sections = this.sections;
    offcanvasRef.result.then((result) => {;
      this.payload = result;
      this.payload.school_id = this.school.school_id;
      this.payload.page = 1;

      this.prefs.campus_id = result.campus_id;
      this.prefs.section_id = result.section_id;
      this.prefs.per_page = result.per_page;

      localStorage.setItem('preferences', JSON.stringify(this.prefs));
      this.loadStudents(this.payload);
    }).catch((error) => {
      console.log('error => ', error);
    });
	}

  ngOnInit(): void {
    this.loadStudents(this.payload);
    // this.loadSections();

    this.searchControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => this.escolarService.getStudents({
        name: term, 
        school_id: this.school.school_id
      }))).subscribe((results: any) => {
        this.students = results.data;
        this.meta = results.meta;
      });
  }
  
  ngAfterViewInit() {
    this.btnList?.nativeElement.classList.remove('active');
    this.btnBloc?.nativeElement.classList.remove('active');
    if (this.activeView == 'btnList') {
      this.btnList?.nativeElement.classList.add('active');
    } else { 
      this.btnBloc?.nativeElement.classList.add('active');
    }
  }

  loadStudents(payload: any) {
    this.loading = true;
    this.escolarService.getStudents(payload).subscribe({
      next: (students: any) => {
        this.students = students.data;
        this.meta = students.meta;
        this.loading = false;
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al obtener los estudiantes.', 'Error');
        this.loading = false;
      }
    });
  }

  loadSections() {
    this.escolarService.getSections({ campus_id: this.campuses.map((campus: any) => campus.id), per_page: 100 }).subscribe((data: any) => {
      this.sections = data;
    });
  }

  saveStudent(event: any) {}

  removeStudent(event: any) {}

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadStudents(this.payload);
  }

  cleanSearch() {
    // this.studentFilter.reset();
    this.payload.page = 1;
    this.payload.section_id = '';
    this.payload.name = '';
    this.loadStudents(this.payload);
  }

  search() {
    this.payload.page = 1;
    // this.payload.section_id = this.studentFilter.get('section_id').value;
    // this.payload.name = this.studentFilter.get('name').value;
    this.loadStudents(this.payload);
  }

  selectView(event: any) {
    this.btnList.nativeElement.classList.remove('active');
    this.btnBloc.nativeElement.classList.remove('active');

    if (event == 'btnList') {
      this.btnList.nativeElement.classList.add('active');
    } else if (event == 'btnBloc') {
      this.btnBloc.nativeElement.classList.add('active');
    }

    this.activeView = event; //  == '' ? 'btnList' : 'btnBloc';
    localStorage.setItem('viewPrefs', this.activeView);
  }

}
