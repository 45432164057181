import { Component } from '@angular/core';

@Component({
  selector: 'app-create-families',
  template: ``,
  styleUrls: []
})
export class CreateFamiliesComponent {

}
