import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Event } from 'src/app/models/event.model';
import { School } from 'src/app/models/school.model';
import { Campus } from 'src/app/models/campus.model';
import { Section } from 'src/app/models/section.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { Family } from 'src/app/models/family.model';

@Component({
  selector: 'app-form-task',
  template: `
    <form [formGroup]="formTask">
      <div class="form-group mt-2">
        <label for="generic_type" class="form-control-label text-dark mb-1 ms-2">¿A quien va dirigido?</label>
        <select class="form-select rounded-3 border-secondary" formControlName="generic_type" (change)="selectType($event)">
          <option value="0" selected disabled>Selecciona...</option>
          <option *ngFor="let type of generic_types" [value]="type.id" [selected]="type.id === ff['generic_type'].value">{{type.title}}</option>
        </select>
      </div>
      <div class="form-group mt-4" *ngIf="ff['generic_type'].value != 1">
        <label for="generic_id" class="form-control-label mb-1 ms-2 text-dark">Selecciona los destinatarios</label>
        <div *ngIf="dropdownList.length>0&&!loadingIds">
          <ng-multiselect-dropdown
            [settings]="dropdownSettings"
            [data]="dropdownList"
            [(ngModel)]="selectedItems"
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            class="border-secondary"
            [ngModelOptions]="{standalone: true}"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="ms-2 mt-1" *ngIf="loadingIds">
          <fa-icon [icon]="icons.faCircleNotch" animation="spin" class="text-dark"></fa-icon>
          <span class="text-muted text-sm ms-2">Cargando información...</span>
        </div>
      </div>
      <div class="ms-auto text-end ms-5">
        <div class="general-wrapper ms-4">
          <input type="file" id="logo" name="logo" (change)="loadImage($event)" accept="image/*">
          <label for="file">Cargar imagen</label>
        </div>
      </div>
      <div class="border rounded-4 me-0 text-center align-middle py-0" style="width: 84px; height: 84px; overflow: hidden;">
            <img [src]="imageURL" width="90%" class="m-0">
          </div>
      <div class="form-group mt-4">
        <label for="title" class="form-label ms-2">Título del evento</label>
        <input type="text" class="form-control border-secondary" formControlName="title" placeholder="Título de la evento">
      </div>
      <div class="form-group mt-4">
        <label for="description" class="form-label ms-2">Descripción del evento</label>
        <!-- textarea class="form-control border-secondary mt-0" id="description" formControlName="description" placeholder="Descripción del evento"></!-->
        <div appMediumEditor [appMediumEditor]="editorOptions" class="form-control" formControlName="description"></div>
      </div>
      <div class="form-group mt-4">
        <label for="description" class="form-label ms-2">Fecha</label><!-- [min]="min" -->
        <input type="date" class="form-control" id="description"  formControlName="start_date">
      </div>
      <div class="form-group mt-4">
        <label for="description" class="form-label ms-2">Horario</label>
        <input type="time" class="form-control" id="description" formControlName="due_time" >
      </div>
      <div class="form-group mb-4 mt-4">
        <div class="form-check form-switch mt-3 ps-0">
          <input #allday class="form-check-input form-check-input-lg float-end" type="checkbox" formControlName="all_day" >
          <label class="form-label ms-2" for="flexCheckDefault"><strong>Dura todo el día</strong></label>
        </div>
      </div>
      <div class="form-group mb-4 mt-3" *ngIf="ff['all_day'].value === false">
        <label for="duration" class="form-label ms-2">Duración (en minutos)</label>
        <input type="number" class="form-control" id="duration" formControlName="duration" placeholder="Duración en minutos">        
      </div>
      <!-- div class="form-group mb-4 mt-3">
        <label for="name" class="form-label ms-2">Campus</label>
        <select class="form-select" formControlName="campus_id" (change)="selectCampus($event)">
          <option *ngFor="let campus of campuses" [value]="campus.campus_id">{{campus.name}}</option>
        </select>
      </!-->

      <div class="form-group text-end mt-5">
        <hr style="border-color: rbga(0,0,0,0.4);">
        <div class="d-flex flex-wrap">
          <div class="ms-auto">
            <button *ngIf="event?.id" class="btn btn-outline-danger" (click)="deleteEvent()">
              <fa-icon [icon]="icons.faTrashAlt" class="me-0 me-md-2"></fa-icon>
              <span class="d-none d-sm-none d-md-inline-block">Eliminar</span>
            </button>
          </div>
          <div class="flex-fill">
            <div class="form-check form-switch float-end ms-3">
              <label class="text-w500 text-sm pt-1" for="flexSwitchCheckDefault"><fa-icon [icon]="icons.faCircleInfo"></fa-icon></label>              
              <input class="form-check-input form-check-input-lg me-3" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="notify">              
            </div>
            <div class="form-group required mb-0 mt-0 text-end">
              <button type="button" class="btn btn-link text-dark me-2" routerLink="/calendar">Cancelar</button>
              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-escalas" id="dropdownBasic1" ngbDropdownToggle>
                  Guardar <fa-icon [icon]="icons.faChevronDown" class="ms-2"></fa-icon>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button type="button" ngbDropdownItem (click)="eventPublish()">
                    <fa-icon [icon]="icons.faPaperPlane" class="me-2"></fa-icon> 
                    Publicar
                  </button>
                  <button type="button" ngbDropdownItem *ngIf="event?.live">
                    <fa-icon [icon]="icons.faSave" class="me-2"></fa-icon>
                    Enviar a borrador
                  </button>
                  <button type="button" class="text-danger" ngbDropdownItem (click)="deleteEvent()">
                    <fa-icon [icon]="icons.faTrashAlt" class="me-2"></fa-icon>
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormTaskComponent implements OnInit {
  @Input() event: Event | null = null;
  @Input() loading: boolean = false;
  @Input() currentDay: any;
  @Input() school: School | null = null;
  @Input() campuses: Campus[] = [];
  @Output() taskData = new EventEmitter<any>();

  sections: Section[] = [];
  families: Family[] = [];

  formTask: FormGroup = new FormGroup({});

  imageURL: any = '';
  loadingIds: boolean = false;
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: any = {};

  priorities: any = [
    { id: 1, name: 'Baja', color: '#219C90' },
    { id: 2, name: 'Media', color: '#E9B824' },
    { id: 3, name: 'Alta', color: '#EE9322' },
    { id: 4, name: 'Urgente', color: '#D83F31' },
  ];

  status: any = [
    { id: 1, name: 'Pendiente' },
    { id: 2, name: 'En proceso' },
    { id: 3, name: 'Completada' },
    { id: 4, name: 'Cancelada' },
  ];

  generic_types: any[] = [
    { id: 1, title: "Todos" },
    { id: 3, title: "Grupo" },
    { id: 5, title: "Familia"}
  ];

  editorOptions = {
    placeholder: {
      text: 'Agrega un texto...'
    },
    toolbar: {
      allowMultiParagraphSelection: true,
      buttons: ['bold', 'italic', 'underline', 'h2', 'h3', 'quote', 'anchor', 'orderedlist', 'unorderedlist'],
      diffLeft: 0,
      diffTop: -10,
      firstButtonClass: 'medium-editor-button-first',
      lastButtonClass: 'medium-editor-button-last',
      relativeContainer: null,
      standardizeSelectionStart: false,
      static: false,
      align: 'center',
      sticky: false,
      updateOnEmptySelection: false
    },
    anchorPreview: {
      hideDelay: 500,
      previewValueSelector: 'a'
    },
    paste: {
      forcePlainText: true,
      cleanPastedHTML: false,
      cleanReplacements: [],
      cleanAttrs: ['class', 'style', 'dir'],
      cleanTags: ['meta'],
      unwrapTags: []
    },
    autoLink: true,
    imageDragging: false
  };

  constructor(
    private escolarService: EscolarService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar Todo',
      unSelectAllText: 'Deseleccionar Todo',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['event'] && changes['event'].currentValue) {
      let event: any = changes['event'].currentValue;
      if (changes['event'].currentValue.id) {
        this.formTask.patchValue(event);
        this.formTask.patchValue({
          start_date: event.start_date.split('T')[0],
          due_time: event.start_date.split('T')[1].split('-')[0]
        });
        this.imageURL = `https://api.escalas.io/${changes['event'].currentValue.image.url}`;
      }
      if (changes['event'].currentValue.generic_type) {
        this.loadTypes(changes['event'].currentValue.generic_type);
      }
    }
  }

  get ff() {
    return this.formTask.controls;
  }

  loadSelectedItems(event: any) {
    if (!event) return;
    const genericIds = event.generic_id?.split(',').map((id: any) => parseInt(id));
    if (event.generic_type === 3) {
      this.selectedItems.push(
        ...genericIds.map((id: any) => {
          const section = this.sections.find((s: any) => s.section_id === id);
          return section
            ? { item_id: section.section_id, item_text: `${section?.section ? section.section : ''} ${section?.grade ? section.grade : ''} ${section?.group ? section.group : ''}` }
            : null;
        }).filter((item: any) => item !== null)
      );
    } else if (event.generic_type === 5) {
      this.selectedItems.push(
        ...genericIds.map((id: any) => {
          const family = this.families.find((s: any) => s.family_id === id);
          return family
            ? { item_id: family.family_id, item_text: `${family.name}` }
            : null;
        }).filter((item: any) => item !== null)
      );
    }    
  }

  initForm() {
    this.formTask = this.fb.group({
      id: [''],
      title: ['', Validators.required],
      description: [''],
      all_day: [true],
      completed: [false],
      notify: [true],
      live: [true],
      duration: [''],
      start_date: ['', Validators.required],
      due_time: [''],
      generic_type: [1, Validators.required],
      generic_id: [],
      image: [''],
      school_id: [''],
      campus_id: [''],
    });
  }

  selectType(event: any) {
    this.loadTypes(event.target.value);
  }

  async loadTypes(id: number) {
    this.dropdownList = [];
    this.selectedItems = [];
    if (id == 1) {
      // this.loadCampus();    
    } else if (id == 3) {
      await this.loadSections();
    } else if (id == 4) {
      await this.loadSections();
    } else if (id == 5) {
      await this.loadFamilies();
    }
  }

  async loadSections() {
    this.loadingIds = true;
    this.escolarService.getSections({ 
      per_page: 600, 
      page: 1, 
      campus_id: this.campuses.map((item: any) => item.campus_id) 
    }).subscribe({
      next: (sections: any) => {
        this.sections = sections.data;
        this.sections.map((item: any) => {
          this.dropdownList?.push({ 
            item_id: item.section_id, 
            item_text: `${item?.section ? item?.section : ''} ${item?.grade ? item?.grade : ''} ${item?.group ? item?.group : ''}` 
          });
        });
        this.loadSelectedItems(this.event);
        this.loadingIds = false;
      },
      error: (error: any) => {
        this.toastr.error('Error al cargar las secciones', 'Error');
        console.error('Error loading sections', error);
        this.loadingIds = false
      }
    });
  }

  async loadFamilies() {
    this.loadingIds = true;
    this.escolarService.getFamilies({ 
      per_page: 600, 
      page: 1, 
      campus_id: this.campuses.map((item: any) => item.campus_id) 
    }).subscribe({
      next: (families: any) => {
      this.families = families.data;
      this.families.map((item: any) => { this.dropdownList.push({ item_id: item.family_id, item_text: item.name }) });
      this.loadingIds = false;
      this.loadSelectedItems(this.event);    
      },
      error: (error: any) => {
        this.toastr.error('Error al cargar las familias', 'Error');
        console.error('Error loading families', error);
        this.loadingIds = false;
      }
    });
  }

  loadImage(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    
    reader.onload = (e: any) => {
      this.imageURL = e.target.result;
      this.formTask.patchValue({
        image: { data: e.target.result, name: file.name, type: file.type }
      });
    }

    reader.readAsDataURL(file);
  }

  onItemSelect(item: any) {}

  onSelectAll(items: any) {}

  selectCampus(campus: any) {
    this.escolarService.getSections({ campus_id: campus.target.value }).subscribe((data: any) => {
      this.dropdownList = [];
      data.data.map((item: Section) => {
        this.dropdownList.push({ item_id: item.section_id, item_text: `${item?.section ? item.section : '' } ${item?.grade ? item.grade : '' } ${ item?.group ? item.group : '' }` });
      });
      this.sections = data.data;
    });
  }

  deleteEvent() {}

  eventPublish() {
    const generic_id: any = this.selectedItems.map((item: any) => item.item_id);
    this.formTask.patchValue({
      campus_id: this.campuses[0]!.campus_id,
      school_id: this.school!.school_id,
      generic_id: generic_id.join(','),
      start_date: `${this.formTask.value.start_date.split('T')[0]}T${this.formTask.value.due_time ? this.formTask.value.due_time : this.formTask.value.start_date.split('T')[1]}:00`,
    });

    if (this.formTask.invalid) {
      this.formTask.markAllAsTouched();
      this.toastr.error('Por favor completa el formulario', 'Error');
      return;
    }

    this.taskData.emit(this.formTask.value);
  }
}
