import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-notices-message',
  template: ``,
  styleUrls: []
})
export class EditNoticesMessageComponent {

}
