import { Component } from '@angular/core';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-setting',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo.url}}" width="18" height="18" class="ms-2" *ngIf="school.logo">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="d-flex mb-2">
        <div class="">
          <h1 class="mb-0">Configuración</h1>
        </div>
      </div>
      <div class="d-flex mb-2 pe-4">
        <div class="flex-shrink-2 me-4 ms-0">
          <div class="list-group pe-3 ps-0 border-0 mt-3">
            <div class="list-group-item list-group-item-action border-0 py-3">
              <fa-icon [icon]="icons.faCog" class="me-2"></fa-icon> General
            </div>
            <div class="list-group-item list-group-item-action border-0 py-3">
              <fa-icon [icon]="icons.faBell" class="me-2"></fa-icon> Notificaciones
            </div>
            <div class="list-group-item list-group-item-action border-0 py-3">
              <fa-icon [icon]="icons.faUser" class="me-2"></fa-icon> Personalización
            </div>
            <div class="list-group-item list-group-item-action border-0 py-3">
              <fa-icon [icon]="icons.faDatabase" class="me-2"></fa-icon> Control de datos
            </div>
            <div class="list-group-item list-group-item-action border-0 py-3">
              <fa-icon [icon]="icons.faUserShield" class="me-2"></fa-icon> Seguridad
            </div>
            <div class="list-group-item list-group-item-action border-0 py-3">
              <fa-icon [icon]="icons.faCreditCard" class="me-2"></fa-icon> Suscripción
            </div>
          </div>
        </div>
        <div class="flex-fill text-end mt-0 ms-5">

          <div #customize class="list-group border-0 bg-transparent mt-2">
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <strong>Perfil</strong>
              <button class="btn btn-outline-secondary btn-sm">Editar</button>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <strong>Notificaciones</strong>
              <button class="btn btn-outline-secondary btn-sm">Inactivo</button>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <strong>Modo obscuro</strong>
              <button class="btn btn-outline-primary btn-sm">Activo</button>
            </div>
          </div>

          <div #data class="list-group border-0 bg-transparent mt-2">
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0 text-start">
              <div>Exportar datos</div>
              <button class="btn btn-outline-secondary">Exportar</button>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0 text-start">
              <div>Eliminar cuenta</div>
              <button class="btn btn-danger">Eliminar</button>
            </div>
          </div>

          <div #security class="list-group border-0 bg-transparent mt-2">
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0 text-start">
              <div>
                <h4 style="font-size: 1em; font-weight: 500;">Seguridad</h4>
                <p class="text-sm text-muted mb-0" style="font-size: 13px; font-weight: 300;">Puedes contratar más espacio adicional a los 300 MB del plan básico.</p>
              </div>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex align-items-start py-3 px-0 list-group-item-action">
              <div class="text-start flex-shrink-1">
                <strong>Autenticación Multifactor</strong>
                <p class="mb-0 text-sm" style="font-size: 13px; font-weight: 300;">Requiere un desafío de seguridad adicional al iniciar sesión para todos tus usuarios. Si no puedes pasar este desafío, tendrás la opción de recuperar tu cuenta por correo electrónico.</p>
              </div>
              <button class="btn btn-outline-primary btn-sm">Activar</button>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex align-items-start py-3 px-0 list-group-item-action">
              <div class="text-start">
                <strong>Cerrar sesión en todos los dispositivos</strong>
                <p class="mb-0 text-sm" style="font-size: 13px; font-weight: 300;">Cierra todas las sesiones activas en todos los dispositivos, incluida tu sesión actual. En los otros dispositivos, el cierre de sesión puede demorar hasta 30 minutos.</p>
              </div>
              <button class="btn btn-outline-primary btn-sm">Cerrar todas las sesiones</button>
            </div>
          </div>

          <div #subscription class="list-group border-0 bg-transparent mt-2">
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0 text-start">
              <div>
                <h4 style="font-size: 1em; font-weight: 500;">Opciones de almacenaje</h4>
                <p class="text-sm text-muted mb-0" style="font-size: 13px; font-weight: 300;">Puedes contratar más espacio adicional a los 300 MB del plan básico.</p>
              </div>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex align-items-start py-3 px-0 list-group-item-action">
              <div class="badge bg-secondary px-3 py-3" style="width: 110px !important;"><h3 class="m-0">500 MB</h3></div>
              <div class="ms-3 text-start">
                <p class="mb-0" style="font-weight: 300;"><strong>$ 350.00 MXN</strong> Por mes</p>
                <p class="mb-0" style="font-weight: 300;">Tu almacenaje total de 700 MB</p>
              </div>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex align-items-start py-3 px-0 list-group-item-action">
              <div class="badge bg-secondary px-3 py-3" style="width: 110px !important;"><h3 class="m-0">1.0 GB</h3></div>
              <div class="ms-3 text-start">
                <p class="mb-0" style="font-weight: 300;"><strong>$ 350.00 MXN</strong> Por mes</p>
                <p class="mb-0" style="font-weight: 300;">Tu almacenaje total de 700 MB</p>
              </div>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex align-items-start py-3 px-0 list-group-item-action">
              <div class="badge bg-secondary px-3 py-3" style="width: 110px !important;"><h3 class="m-0">2.0 GB</h3></div>
              <div class="ms-3 text-start">
                <p class="mb-0" style="font-weight: 300;"><strong>$ 350.00 MXN</strong> Por mes</p>
                <p class="mb-0" style="font-weight: 300;">Tu almacenaje total de 2.3 GB</p>
              </div>
            </div>
            <div class="bg-transparent border-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <p class="mb-0 text-muted" style="font-weight: 300;">Todos los precios incluyen I.V.A.</p>
              <button class="btn btn-outline-primary">Contratar</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class DashboardSettingComponent {

  school: any = {};
  campuses: any[] = [];
  permissions: any = {};

  escalasURI: string = environment.ESCALAS_WSPREFIX;

  payload: any = {
    per_page: 50,
    page: 1,
  };
  p: number = 1;

  constructor(
    public icons: IconsService
  ) {
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = localStorage.getItem('campuses') != null ? JSON.parse(localStorage.getItem('campuses')!) : [];
    this.payload.campus_id = this.campuses.map((item: any) => item.campus_id);
  }

}
