import { Injectable } from '@angular/core';
import {
  faSignOutAlt,
  faEnvelope,
  faUser,
  faEdit,
  faPlus,
  faTimes,
  faSave,
  faBath,
  faBed,
  faCar,
  faSort,
  faSortUp,
  faSortDown,
  faFilePdf,
  faTrashAlt,
  faCircleNotch,
  faCalculator,
  faCalendarAlt,
  faCashRegister,
  faCreditCard,
  faHelmetSafety,
  faCalendar,
  faClock,
  faGasPump,
  faEye,
  faEyeSlash,
  faCog,
  faLink,
  faSearch,
  faGauge,
  faGears,
  faSpinner,
  faMoneyBill,
  faPhoneAlt,
  faMailBulk,
  faBuilding,
  faDownload,
  faFileExcel,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faUserShield,
  faEllipsisVertical,
  faTriangleExclamation,
  faCircleInfo,
  faMagnifyingGlass,
  faCircleExclamation,
  faGraduationCap,
  faPeopleGroup,
  faSchool,
  faStar,
  faSliders,
  faChild,
  faTableList,
  faList,
  faGrip,
  faImage,
  faPlay,
  faDatabase,
  faBullhorn,
  faUpload,
  faBell,
  faUpRightFromSquare,
  faChalkboardUser,
  faWandMagicSparkles,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  faSignOutAlt = faSignOutAlt;
  faEnvelope = faEnvelope;
  faUser = faUser;  
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faEdit = faEdit;
  faLink = faLink;
  faSearch = faSearch;
  faSave = faSave;
  faBath = faBath;
  faBed = faBed;
  faCar = faCar;
  faCog = faCog;
  faSort = faSort;
  faSpinner = faSpinner;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilePdf = faFilePdf;
  faTrashAlt = faTrashAlt;
  faCashRegister = faCashRegister;
  faHelmetSafety = faHelmetSafety;
  faCreditCard = faCreditCard;
  faGasPump = faGasPump;
  faGears = faGears;
  faPlus = faPlus;
  faClock = faClock;
  faTimes = faTimes;
  faGauge = faGauge;
  faFileExcel = faFileExcel;
  faMoneyBill = faMoneyBill;
  faPhoneAlt = faPhoneAlt;
  faMailBulk = faMailBulk;
  faBuilding = faBuilding;
  faDownload = faDownload;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faCalculator = faCalculator;
  faCircleNotch = faCircleNotch;
  faCalendarAlt = faCalendarAlt;
  faUserShield = faUserShield;
  faCalendar = faCalendar;
  faCircleExclamation = faCircleExclamation;
  faTriangleExclamation = faTriangleExclamation;
  faCircleInfo = faCircleInfo;
  faMagnifyingGlass = faMagnifyingGlass;
  faEllipsisVertical = faEllipsisVertical;
  faGraduationCap = faGraduationCap;
  faPeopleGroup = faPeopleGroup;
  faSchool = faSchool;
  faStar = faStar;
  faSliders = faSliders;
  faChild = faChild;
  faTableList = faTableList;
  faList = faList;
  faGrip = faGrip;
  faImage = faImage;
  faPlay = faPlay;
  faDatabase = faDatabase;
  faBullhorn = faBullhorn;
  faUpload = faUpload;
  faBell = faBell;
  faUpRightFromSquare = faUpRightFromSquare;
  faChalkboardUser = faChalkboardUser;
  faWandMagicSparkles = faWandMagicSparkles;
  faPaperPlane = faPaperPlane;
  
  constructor() { }

}
