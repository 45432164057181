import { Component, AfterViewInit, ViewChild, ElementRef, Input, SimpleChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Campus } from 'src/app/models/campus.model';
import { Family } from 'src/app/models/family.model';
import { Notice } from 'src/app/models/notice.model';
import { School } from 'src/app/models/school.model';
import { Section } from 'src/app/models/section.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { environment } from 'src/environments/environment';
import { ModalImageGeneratorComponent } from '../modals/modal-image-generator.component';

@Component({
  selector: 'app-form-notice',
  template: `
    <form [formGroup]="noticeForm">    
      <div class="d-flex flex-wrap flex-md-nowrap py-2">
        
        <div class="flex-shrink-1  d-block d-sm-none d-md-block px-3 py-2"  style="min-width: 414px; max-width: 414px; width: 414px; max-height: 660px;">
          <button type="button" class="btn btn-sm btn-outline-secondary" (click)="openModalImageGenerator()">
            <fa-icon [icon]="icons.faWandMagicSparkles" class="me-2"></fa-icon>
            Crea tu propia imagen
          </button>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-primary">Left</button>
            <button type="button" class="btn btn-primary">Middle</button>
            <button type="button" class="btn btn-primary">Right</button>
          </div>
          <div class="d-flex flex-column flex-wrap rounded-4" [ngStyle]="{ 'background-color': school ? school.color : '#fff'  }">
            <div class="d-flex">
              <div class="mt-3">
                <div class="d-flex flex-wrap" *ngIf="ff['generic_type']?.value == 1">
                  <div class="bg-primary text-center rounded-3 float-start ms-3 me-2" style="width: 28px; height: 28px; padding-top: 2px;">
                    <fa-icon [icon]="icons.faStar" class="text-white text-center"></fa-icon>
                  </div>
                  <div class="float-start text-w500 pt-1">Escuela</div>
                </div>
                <div class="d-flex" *ngIf="ff['generic_type']?.value == 3">
                  <div class="bg-primary text-center rounded-3 float-start ms-3 me-2" style="width: 28px; height: 28px; padding-top: 2px;">
                    <fa-icon [icon]="icons.faStar" class="text-white text-center"></fa-icon>
                  </div>
                  <div class="float-start text-w500 pt-1">Grupo</div>
                </div>
                <div class="d-flex" *ngIf="ff['generic_type']?.value == 5">
                  <div class="bg-primary text-center rounded-3 float-start ms-3 me-2" style="width: 28px; height: 28px; padding-top: 2px;">
                    <fa-icon [icon]="icons.faStar" class="text-white text-center"></fa-icon>
                  </div>
                  <div class="float-start text-w500 pt-1">Familia</div>
                </div>
              </div>            
            </div>
            <div class="d-flex mt-2 ms-3">
              <h3 class="text-dark">{{ff['title']?.value}}</h3>
            </div>
            <div class="d-flex" *ngIf="notice?.images!.length>0" style="max-width: 420px;">
              <ngb-carousel
                [showNavigationArrows]="false"
                [showNavigationIndicators]="true"
              >
                <ng-template ngbSlide *ngFor="let file of ff['images']?.value">
                  <div class="picsum-img-wrapper">
                    <img src="{{file.data}}" alt="Random first slide" width="420px" />
                  </div>
                </ng-template>
              </ngb-carousel>
            </div>
            <div class="d-flex mt-4 ms-3" style="max-width: 414px;">
              <div class="text-sm" [innerHTML]="ff['description']?.value"></div>
            </div>
          </div>
        </div>
        <div class="flex-fill ps-4" *ngIf="loading">
          <div class="text-center my-5 py-5">
            <fa-icon [icon]="icons.faCircleNotch" size="2x" animation="spin" class="text-dark"></fa-icon>
            <h5>CARGANDO...</h5> 
          </div>
        </div>
        <div class="flex-fill ms-0 ms-md-4" *ngIf="!loading">
          <!-- div class="form-group mt-0 mb-3">
            <div class="d-flex">
              <div class="ms-auto"></div>
              <div class="flex-fill text-end">
                <p class="text-secondary text-end text-muted text-sm mb-0">Capacidad max. {{totalSize | number : '1.0-2'}} MB / {{maxCapacity}} MB</p>
                <div class="progress">
                  <div class="progress-bar bg-info" role="progressbar" [ngStyle]="{ width: usedPercentage+'%' }" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </!-->          
          <div class="w-100">
            <div class="dropzone rounded-4" appFileDragDrop (filesChangeEmiter)="onFileChange($event)">
              <div class="text-wrapper">
                <div class="centered">
                  <input type="file" name="file" id="file" (change)="onFileChange($event)" multiple accept="image/*, application/pdf, video/*" >
                  <label for="file">
                    <fa-icon [icon]="icons.faImage" class="text-secondary" size="2x"></fa-icon>
                    <br>
                    Arrastra y suelta tu archivo aquí, ó <span class="textLink">selecciona tu archivo</span>
                    <br> <div class="text-sm text-secondary mt-2">Tamaño máximo 10MB</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="list-group rounded-4 mt-3 mb-3" *ngIf="ff['images'].value.length>0">
            <div class="list-group-item list-group-item-secondary px-3 py-2" *ngFor="let f of ff['images'].value; let ind = index">
              <div class="d-flex">
                <div class="text-center fileItemIconDiv me-2">
                  <div class="mt-2" *ngIf="!f.type?.includes('image')">
                    <fa-icon [icon]="icons.faFilePdf" class="text-secondary" size="2x" *ngIf="f.type?.includes('pdf')"></fa-icon>
                    <fa-icon [icon]="icons.faPlay" class="text-secondary" size="2x" *ngIf="f.type?.includes('video')"></fa-icon>
                  </div>
                  <img [src]="f.data" width="40px" class="mt-1" *ngIf="f.type?.includes('image')">
                </div>
                <div class="flex-fill fileItemText text-start">
                  <p class="mb-0">{{f.name}}</p>
                  <p class="mb-0 text-sm">{{getTotalSize(f.size)}} <span class="text-muted">{{f.type}}</span></p>
                </div>
                <div class="ms-auto">
                  <button type="button" class="btn btn-link">
                    <fa-icon [icon]="icons.faTrashAlt" class="text-secondary" (click)="removeFile(f)"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mt-3">
            <label for="generic_type" class="form-control-label text-dark mb-1 ms-2">¿A quien va dirigido?</label>
            <select class="form-select rounded-3 border-secondary" formControlName="generic_type" (change)="selectType($event)">
              <option value="0" selected disabled>Selecciona...</option>
              <option *ngFor="let type of generic_types" [value]="type.id" [selected]="type.id === ff['generic_type'].value">{{type.title}}</option>
            </select>
          </div>
          <div class="form-group mt-3" *ngIf="ff['generic_type'].value != 1">
            <label for="generic_id" class="form-control-label mb-1 ms-2 text-dark">Selecciona los destinatarios</label>
            <div *ngIf="dropdownList.length>0&&!loadingIds">
              <ng-multiselect-dropdown
                [settings]="dropdownSettings"
                [data]="dropdownList"
                [(ngModel)]="selectedItems"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                class="border-secondary"
                [ngModelOptions]="{standalone: true}"
              >
              </ng-multiselect-dropdown>
            </div>
            <div class="ms-2 mt-1" *ngIf="loadingIds">
              <fa-icon [icon]="icons.faCircleNotch" animation="spin" class="text-dark"></fa-icon>
              <span class="text-muted text-sm ms-2">Cargando información...</span>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="title" class="form-control-label text-dark mb-1 ms-2">Título</label>
            <input type="text" formControlName="title" class="form-control rounded-3 border-secondary" />
          </div>
          <div class="form-group mt-3">
            <label for="description" class="form-control-label text-dark mb-1 ms-2">Descripción (opcional)</label>
            <!-- textarea formControlName="description" class="form-control rounded-3 border-secondary" rows="2"></!-->
            <div appMediumEditor [appMediumEditor]="editorOptions" class="form-control" formControlName="description"></div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap mt-3">
        <div class="form-group text-start">
          <label for="published_at" class="form-check-label text-dark ms-2">Fecha de publicación</label>
          <input type="date" class="form-control rounded-3 bg-transparent border-secondary" formControlName="published_at_date">
        </div>
        <div class="from-group text-start ms-3 mb-0 clearfix">
          <label for="published_at" class="form-check-label text-dark ms-2">Hora de publicación</label>
          <input type="time" class="form-control rounded-3 bg-transparent border-secondary" formControlName="published_at_time">
        </div>
        <div class="flex-fill form-group text-end mt-4 mb-0">
          <div class="form-check form-switch float-end ms-3">
            <label class="text-w500 text-sm pt-1" for="flexSwitchCheckDefault"><fa-icon [icon]="icons.faCircleInfo"></fa-icon></label>              
            <input class="form-check-input form-check-input-lg me-3" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="notify">              
          </div>
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-escalas" id="dropdownBasic1" ngbDropdownToggle>
              Guardar <fa-icon [icon]="icons.faChevronDown" class="ms-2"></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button type="button" ngbDropdownItem (click)="noticePublish()">
                <fa-icon [icon]="icons.faPaperPlane" class="me-2"></fa-icon> 
                Publicar
              </button>
              <button type="button" ngbDropdownItem *ngIf="notice?.live">
                <fa-icon [icon]="icons.faSave" class="me-2"></fa-icon>
                Enviar a borrador
              </button>
              <button type="button" class="text-danger" ngbDropdownItem (click)="deleteNotice()">
                <fa-icon [icon]="icons.faTrashAlt" class="me-2"></fa-icon>
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <app-modal-image-generator></app-modal-image-generator>
  `,
  styleUrls: []
})

export class FormNoticeComponent implements OnInit {
  @Input() notice: Notice | null = null;
  @Input() school: School | null = null;
  @Input() campuses: Campus[] = [];
  @Input() loading: boolean = false;
  @Input('appMediumEditor') options: any;
  @Output() noticeData = new EventEmitter<any>();
  @ViewChild(ModalImageGeneratorComponent, { static: false }) modalImageGenerator !: ModalImageGeneratorComponent;

  escalasURI: string = environment.ESCALAS_WSPREFIX;
  url: string = 'https://api.escalas.io/';
  noticeForm: FormGroup = new FormGroup({});

  public uploadedFiles: any[] = [];
  public files: any[] = [];

  sections: Section[] = [];
  families: Family[] = [];

  loadingIds: boolean = false;
  dropdownSettings: any = {};
  selectedItems: any[] = [];
  dropdownList: any[] = [];

  image: string = '';
  hasMedia: boolean = false;
  usedPercentage: number = 0;
  totalSize: number = 0;
  maxCapacity: number = 10;

  editorOptions = {
    placeholder: {
      text: 'Agrega un texto...'
    },
    toolbar: {
      allowMultiParagraphSelection: true,
      buttons: ['bold', 'italic', 'underline', 'h2', 'h3', 'quote', 'anchor', 'orderedlist', 'unorderedlist'],
      diffLeft: 0,
      diffTop: -10,
      firstButtonClass: 'medium-editor-button-first',
      lastButtonClass: 'medium-editor-button-last',
      relativeContainer: null,
      standardizeSelectionStart: false,
      static: false,
      align: 'center',
      sticky: false,
      updateOnEmptySelection: false
    },
    anchorPreview: {
      hideDelay: 500,
      previewValueSelector: 'a'
    },
    paste: {
      forcePlainText: true,
      cleanPastedHTML: false,
      cleanReplacements: [],
      cleanAttrs: ['class', 'style', 'dir'],
      cleanTags: ['meta'],
      unwrapTags: []
    },
    autoLink: true,
    imageDragging: false
  };

  generic_types: any[] = [
    { id: 1, title: "Todos" },
    { id: 3, title: "Grupo" },
    { id: 5, title: "Familia"}
  ];

  constructor(
    public icons: IconsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private escolarService: EscolarService,
    private imageService: ImageService,
    private store: EscolarService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadForm();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar Todo',
      unSelectAllText: 'Deseleccionar Todo',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['notice'] && changes['notice'].currentValue) {
      let notice: Notice = changes['notice'].currentValue;
      this.hasMedia = notice.images && notice.images?.length > 0 ? true : false;
      this.noticeForm.patchValue({
        notice_id: notice.notice_id,
        title: notice.title,
        description: notice.description,
        notify: notice.notify,
        campus_id: notice.campus_id,
        live: notice.live,
        generic_type: notice.generic_type,
        generic_id: notice.generic_id,
        notice_type: notice.notice_type,
      });

      if (changes['notice'].currentValue.generic_type) {
        this.loadTypes(changes['notice'].currentValue.generic_type);
      }

      notice.images?.map(async (image: any) => {
        const img = await fetch(`${this.escalasURI}${image.url}`);
        const contentLength = img.headers.get("Content-Length");
        const base = await this.imageService.getBase64ImageFromUrl(img.url);

        console.log('image ===> ', image);

        // this.files.push({
          // name: image.url.split('/').pop(),
          // data: base, // `${this.escalasURI}${image.url}`,
          // size: contentLength,
          // type: image.content_type,
          // id: image.id
        // });
        if(image.content_type.includes('image')) {
          this.uploadedFiles.push({
            name: image.url.split('/').pop(),
            data: base, // `${this.escalasURI}${image.url}`,
            size: contentLength,
            type: image.content_type,
            id: image.id
          });
        }
      });

      this.noticeForm.patchValue({
        images: this.uploadedFiles
      });

      console.log("NOTICE IMAGES ===> ", this.files);
      // this.files = notice.images;
    }
  }

  get ff (): any {
    return this.noticeForm.controls;
  }

  loadSelectedItems(notice: any) {
    if (!notice) return;
    const genericIds = notice.generic_id.split(',').map((id: any) => parseInt(id));

    if (notice.generic_type === 3) {
      this.selectedItems.push(
        ...genericIds.map((id: any) => {
          const section = this.sections.find((s: any) => s.section_id === id);
          return section
            ? { item_id: section.section_id, item_text: `${section?.section ? section.section : ''} ${section?.grade ? section.grade : ''} ${section?.group ? section.group : ''}` }
            : null;
        }).filter((item: any) => item !== null)
      );
    } else if (notice.generic_type === 5) {
      this.selectedItems.push(
        ...genericIds.map((id: any) => {
          const family = this.families.find((s: any) => s.family_id === id);
          return family
            ? { item_id: family.family_id, item_text: `${family.name}` }
            : null;
        }).filter((item: any) => item !== null)
      );
    }    
  }

  isImage(file: { content_type: string }): boolean {
    return file.content_type?.startsWith('image/');
  }

  isVideo(file: { content_type: string }): boolean {
    return file.content_type?.startsWith('video/');
  }

  isPdf(file: { content_type: string }): boolean {
    return file.content_type === 'application/pdf';
  }

  getTotalSize(size: number): string {
    return size > 1000000 ? (size / 1000000).toFixed(2) + ' MB' : (size / 1000).toFixed(2) + ' KB';
  }

  selectType(event: any) {
    this.loadTypes(event.target.value);
  }

  async loadTypes(id: number) {
    this.dropdownList = [];
    this.selectedItems = [];
    if (id == 1) {
      // this.loadCampus();    
    } else if (id == 3) {
      await this.loadSections();
    } else if (id == 4) {
      await this.loadSections();
    } else if (id == 5) {
      await this.loadFamilies();
    }
  }

  async loadSections() {
    this.loadingIds = true;
    this.escolarService.getSections({ 
      per_page: 600, 
      page: 1, 
      campus_id: this.campuses.map((item: any) => item.campus_id) 
    }).subscribe({
      next: (sections: any) => {
        this.sections = sections.data;
        this.sections.map((item: any) => {
          this.dropdownList?.push({ 
            item_id: item.section_id, 
            item_text: `${item?.section ? item?.section : ''} ${item?.grade ? item?.grade : ''} ${item?.group ? item?.group : ''}` 
          });
        });
        this.loadSelectedItems(this.notice);
        this.loadingIds = false;
      },
      error: (error: any) => {
        this.toastr.error('Error al cargar las secciones', 'Error');
        this.loadingIds = false
      }
    });
  }

  async loadFamilies() {
    this.loadingIds = true;
    this.escolarService.getFamilies({ 
      per_page: 600, 
      page: 1, 
      campus_id: this.campuses.map((item: any) => item.campus_id) 
    }).subscribe({
      next: (families: any) => {
      this.families = families.data;
      this.families.map((item: any) => { this.dropdownList.push({ item_id: item.family_id, item_text: item.name }) });
      this.loadingIds = false;
      this.loadSelectedItems(this.notice);    
      },
      error: (error: any) => {
        this.toastr.error('Error al cargar las familias', 'Error');
        console.error('Error loading sections', error);
        this.loadingIds = false;
      }
    });
  }

  loadForm(): void {
    this.noticeForm = this.fb.group({
      notice_id: [''],
      title: ['', [Validators.required]],
      description: [''],
      notify: [true, [Validators.required]],
      campus_id: ['', [Validators.required]],
      live: [true, [Validators.required]],
      generic_type: [1, [Validators.required]],
      generic_id: [],
      files: [''],
      images: [''],
      school_id: [this.school?.school_id, [Validators.required]],
      notice_type: [''],
      published_at_date: [new Date().toISOString().split('T')[0]],
      published_at_time: [new Date().toISOString().split('T')[1].split('.')[0]],
    });
  }

  onFileChange(pFileList: any) {
    const ctl = this;
    let newFiles: any [] = [];

    console.log("FILES ===> ", pFileList);

    for (let i = 0; i < pFileList.length; i++) {
      const file = pFileList[i];
      const reader = new FileReader();

      if (!(file.type.startsWith('image/') || file.type.startsWith('video/') || file.type === 'application/pdf')) {
        ctl.toastr.error(`El archivo <b>${file.name}</b> <br> no es válido, únicamente se pueden subir <b>imágenes, vídeos y arhivos pdf<b>.`, 'Error', {
          timeOut: 4000,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: true
        });
        return;
      } else {
        reader.onload = function (e: any) {
          const base64String = e.target.result;
          const fileName = file.name;
          ctl.totalSize += file.size / (1024 * 1024);
          ctl.usedPercentage = ((ctl.totalSize / ctl.maxCapacity) * 100);
          ctl.files.push({ name: fileName, type: file.type, data: base64String, size: file.size, content_type: file.type });
          if (file.type.includes('image')) {
            ctl.uploadedFiles.push({ name: fileName, type: file.type, data: base64String, size: file.size, content_type: file.type })
          }
          newFiles.push(file);
          ctl.noticeForm.patchValue({
            files: ctl.files,
            images: ctl.uploadedFiles
          });
        };

        reader.onerror = function (error) {
          ctl.toastr.error('Error al cargar el archivo', 'Error', {
            timeOut: 3000,
            closeButton: true,
            enableHtml: true
          });
        };

        reader.readAsDataURL(file);
      }
    }
    //
    ctl.uploadedFiles = [ ...ctl.uploadedFiles,  ...newFiles];
  }

  removeFile(file: any): void {
    const ctl = this;

    ctl.files = ctl.files.filter(f => f.name !== file.name);
    ctl.uploadedFiles = ctl.uploadedFiles.filter(f => f.name !== file.name);
    ctl.totalSize -= file.size / (1024 * 1024);
    ctl.usedPercentage = ((ctl.totalSize / ctl.maxCapacity) * 100);
    ctl.noticeForm.patchValue({
      files: ctl.files,
      images: ctl.files
    });
    this.store.deleteImageNotice(this.notice!.notice_id, file.id).subscribe({
      next: (data: any) => {
        this.toastr.success('Archivo eliminado correctamente', 'Éxito');
        console.log("FILE DELETED ===> ", data);

      },
      error: (error: any) => {
        this.toastr.error('Error al eliminar el archivo', 'Error');
      }
    });
  }

  noticePublish(): void {
    const generic_id: any = this.selectedItems.map((item: any) => item.item_id);
    this.noticeForm.patchValue({
      campus_id: this.campuses[0]!.campus_id,
      files: this.files,
      generic_id: generic_id.join(',')
    });

    console.log("NOTICE FORM READY ===> ", this.noticeForm.value);

    this.noticeForm.patchValue({
      images: []
    });

    if (this.noticeForm.invalid) {
      this.toastr.error('Por favor, llena todos los campos requeridos', 'Error');
      return;
    }

    
    this.noticeData.emit(this.noticeForm.value);
  }

  deleteNotice(): void {
    this.store.deleteNotice(this.notice!.notice_id).subscribe({
      next: (data: any) => {
        this.toastr.success('Aviso eliminado correctamente', 'Éxito');
        this.router.navigate(['/notices/bulletins']);
      },
      error: (error: any) => {
        this.toastr.error('Error al eliminar el aviso', 'Error');
      }
    });
  }

  openModalImageGenerator() {
    const modalTemplate: any | undefined = this.modalImageGenerator;
    if (modalTemplate) {
      this.modalImageGenerator.openModal(modalTemplate['modalImageGenerator']);
    }
  }

  onItemSelect(item: any) {

  }
  onSelectAll(items: any) {

  }

}
