import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, switchMap } from 'rxjs';
import { OffcanvasTutorFilterComponent } from 'src/app/components/offcanvas/offcanvas-tutor-filter.component';
import { Campus } from 'src/app/models/campus.model';
import { School } from 'src/app/models/school.model';
import { Section } from 'src/app/models/section.model';
import { Tutor } from 'src/app/models/tutor.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-index-tutors',
  template: `
    
      <div class="bg-white p-4 rounded-4 shadow">
        <div class="d-flex mb-2 mb-md-4">
          <img src="https://api.escalas.io{{school.logo!.url}}" width="18" height="18" class="ms-2" *ngIf="school">
          <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="d-flex mb-2">
          <div class="">
            <h1 class="mb-0">Padres <span class="text-muted" style="font-size: 0.5em;">({{meta.total_entries}})</span></h1>
          </div>
          <div class="flex-fill text-end mt-0">
            <div class="float-end"> 
              <div class="d-inline-block">
                <button class="btn btn-outline-secondary ms-2 border-2" (click)="open()">
                  <fa-icon [icon]="icons.faSliders" class="me-0 me-md-2"></fa-icon>
                  <span class="d-none d-sm-none d-md-inline-block">Filtrar</span>
                </button>
              </div>
              <button class="btn btn-dark ms-2" routerLink="create">
                <fa-icon [icon]="icons.faPlus" class="me-0 me-md-2"></fa-icon>
                <span class="d-none d-sm-none d-md-inline-block">Agregar tutor</span>
              </button>
            </div>
            <div class="form-group float-end me-3" style="width: 200px;">
              <div class="input-group">
                <div class="input-group-addon border border-1 rounded-start-2 border-secondary border-end-0 ps-2 text-secondary" style="padding-top: 7px;">
                  <fa-icon [icon]="icons.faSearch" ></fa-icon>
                </div>
                <!-- input class="form-control border-2 border-secondary bg-transparent border-start-0" placeholder="Buscar" -->
                <input id="typeahead-basic" type="text" class="form-control border-1 border-secondary bg-transparent border-start-0" [formControl]="searchControl" />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3" *ngIf="activeView=='btnList'">

        <table class="table table-hover table-responsive">
          <thead>
            <tr>
              <th class="bg-light text-secondary rounded-top-left-3 align-items-center align-middle">Nombre</th>
              <th class="bg-light text-secondary align-items-center align-middle text-center">Teléfono</th>
              <th class="bg-light text-secondary rounded-top-right-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr app-tr-tutors
              [tutor]="tutor"              
              [permissions]="permissions"
              (updateStudent)="saveTutor($event)"
              (deleteStudent)="removeTutor($event)"
              *ngFor="let tutor of tutors | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }"
            ></tr>
          </tbody>
        </table>
        <div class="text-center py-5" *ngIf="tutors.length==0&&loading">
          <fa-icon [icon]="icons.faCircleNotch" size="4x" animation="spin" class="text-escalas mt-5"></fa-icon>
          <p class="text-muted mt-5">Cargando...</p>
        </div>
        <div class="text-center py-5" *ngIf="tutors.length==0&&!loading">
          <img src="/assets/images/empty-state.png" width="240px">
          <h5 class="text-muted">No se encontraron registros</h5>
        </div>        
        <div class="d-flex justify-content-center">
          <pagination-controls
            (pageChange)="p = $event"
            (pageChange)="pageChanged($event)"
            previousLabel="Prev."
            nextLabel="Sig."
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            *ngIf="tutors.length>0"
          ></pagination-controls>
        </div>
      </div>
  `,
  styleUrls: []
})
export class IndexTutorsComponent {
  @ViewChild('btnList') btnList!: ElementRef;
  @ViewChild('btnBloc') btnBloc!: ElementRef;

  school: School;
  prefs: any = {};
  tutors: Tutor[] = [];
  meta: any = {};
  permissions: any = {};
  loading: boolean = false;
  activeView: string = 'btnList';
  tutorFilter: any = new FormGroup({});
  campuses: Campus[] = [];
  sections: Section[] = [];

  searchControl: FormControl = new FormControl();

  payload: any = {
    per_page: 100,
    page: 1,
  };

  p: number = 1;

  constructor(
    private escolarService: EscolarService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService,
    private offcanvasService: NgbOffcanvas
  ) {
    this.permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
    this.school = JSON.parse(localStorage.getItem('school') || '{}');
    this.prefs = JSON.parse(localStorage.getItem('preferences') || '{}');
    this.activeView = this.prefs.viewPrefs || 'btnList';
    this.payload.school_id = this.school.school_id;
  }

  open() {
		const offcanvasRef = this.offcanvasService.open(OffcanvasTutorFilterComponent, { 
      position: 'end', 
      animation: true, 
      backdrop: true, 
      keyboard: true,
      scroll: true, 
      backdropClass: 'bg-dark', 
      panelClass: 'bg-dark' 
    });
		offcanvasRef.componentInstance.name = 'World';
    offcanvasRef.componentInstance.payload = this.payload;
    offcanvasRef.componentInstance.prefs = this.prefs;
    offcanvasRef.componentInstance.campuses = this.campuses;
    offcanvasRef.componentInstance.sections = this.sections;
    offcanvasRef.result.then((result) => {
      this.payload = result;
      this.payload.school_id = this.school.school_id;
      this.payload.page = 1;

      this.prefs.campus_id = result.campus_id;
      this.prefs.section_id = result.section_id;
      this.prefs.per_page = result.per_page;

      localStorage.setItem('preferences', JSON.stringify(this.prefs));
      this.loadTutors(this.payload);
    }).catch((error) => {
      
    });
	}

  ngOnInit(): void {
    this.initForm();
    this.loadTutors(this.payload);
    // this.loadSections();

    this.searchControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => this.escolarService.getTutors({
        name: term, 
        school_id: this.school.school_id
      }))).subscribe((results: any) => {
        this.tutors = results.data;
        this.meta = results.meta;
      });
  }

  initForm() {
    this.tutorFilter = this.fb.group({
      name: [''],
      section_id: [0],
    });
  }

  loadTutors(payload: any) {
    this.loading = true;
    this.escolarService.getTutors(payload).subscribe({
      next: (tutors: any) => {
        this.tutors = tutors.data;
        this.meta = tutors.meta;
        this.loading = false;
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al obtener los estudiantes.', 'Error');
        this.loading = false;
      }
    });
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadTutors(this.payload);
  }

  cleanSearch() {
    this.tutorFilter.reset();
    this.payload.page = 1;
    this.payload.section_id = '';
    this.payload.name = '';
    this.loadTutors(this.payload);
  }

  saveTutor(event: any) {}

  removeTutor(event: any) {}

  search() {
    this.payload.page = 1;
    this.payload.section_id = this.tutorFilter.get('section_id').value;
    this.payload.name = this.tutorFilter.get('name').value;
    this.loadTutors(this.payload);
  }

  selectView(event: any) {
    this.btnList.nativeElement.classList.remove('active');
    this.btnBloc.nativeElement.classList.remove('active');

    if (event == 'btnList') {
      this.btnList.nativeElement.classList.add('active');
    } else if (event == 'btnBloc') {
      this.btnBloc.nativeElement.classList.add('active');
    }

    this.activeView = event; //  == '' ? 'btnList' : 'btnBloc';
    localStorage.setItem('viewPrefs', this.activeView);
  }

}
