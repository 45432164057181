import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  loading: boolean = true;
  validated: boolean = false;
  faCircleNotch = faCircleNotch;

  constructor(
    public authService: AuthService,
    private route: Router,
    private toastr: ToastrService,
    private activateRoute: ActivatedRoute,
  ) {
    let ctl = this;
    this.activateRoute.params.subscribe(params => {
      setTimeout(function () {
        /*ctl.settlementsService.verifyEmail(params['token']).subscribe({
          next: (data: any) => {
            ctl.toastr.success("Cuenta validada", '¡Éxito!');
            ctl.loading = false;
            setTimeout(function () {
              ctl.route.navigate(['/sign-in']);
            }, 1000);
          },
          error: (err: any) => {
            ctl.loading = false;
            ctl.toastr.error(err.error.email, 'Error');
          }
        });*/
      }, 1000);
    });
  }
  
  ngOnInit(): void {
  }
}
