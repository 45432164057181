import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  protected escolarURL = `${environment.ESCALAS_WSPREFIX}/${environment.api_v}`;
  protected apiURL = `${environment.AWS_REST_WSPREFIX}/${environment.api_v}`;
  // protected taskURL = `${environment.TASK_REST_WSPREFIX}/${environment.api_v}`;
  // protected receiptURL =`${environment.RECIBOS_REST_WSPREFIX}`;
  protected logURL = `${environment.AWS_REST_WSPREFIX}`;
  // protected videoURL = `${environment.AWS_REST_WSPREFIX}`;
  protected firebaseURL = `${environment.FIREBASE_WSPREFIX}`;
  protected firebaseToken = `${environment.FIREBASE_TOKEN}`;
  protected supportURL = `${environment}`

  constructor(protected http: HttpClient) {}

  httpOptions: any = {};

  errorHandler(error: any) {

    return error;
  }

  getHeaders(token: any, refresherToken: any = null) {
    const headers: any = { 'Content-Type': 'application/json; charset=utf-8' };
    if (token != null) headers.Authorization = token; // if token authorization exist add to headers
    if (refresherToken != null) headers['X-Refresh-Token'] = refresherToken; // if refresherToken exist add to headers

    return { headers: new HttpHeaders(headers) };
  }

  parseQueryParams(data: any) {
    let query: any = "";

    Object.keys(data).map((item: any, index: any) => {
      query += `${item}=${Object.values(data)[index]}`;
      query += index === Object.keys(data).length -1 ? "" : "&";
    });

    return query;
  }

  serializePayload(data: any, type: any) {
    let serialObj: any = { data: { attributes: data }, type: type };
    //
    return serialObj;
  }
}
