import { Injectable, NgZone } from '@angular/core';
import { User } from '../../models/user';
import * as auth from 'firebase/auth';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
// import {
  // AngularFirestore,
  // AngularFirestoreDocument,
// } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  constructor(
    // public afs: AngularFirestore, // Inject Firestore service
    // public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    // this.afAuth.authState.subscribe((user) => {
      // if (user) {
        // this.userData = user;
        // localStorage.setItem('user', JSON.stringify(this.userData));
        // JSON.parse(localStorage.getItem('user')!);
      // } else {
        // localStorage.setItem('user', 'null');
        // JSON.parse(localStorage.getItem('user')!);
      // }
    // });
  }
  // Sign in with email/password
  // SignIn(email: string, password: string) {
    // let payload = {
      // user: {
        // email: email,
        // password: password
      // }
    // };
    // return this.settlementService.login(payload).subscribe((data: any) => {
      // if (data.status.code == 200) {
        // if (this.SetUserData(data)) {
          // // this.router.navigate(['']);
          // window.location.reload();
        // }
      // } else {
        // window.alert(data.message);
      // }
    // });
    // return this.afAuth
      // .signInWithEmailAndPassword(email, password)
      // .then((result: any) => {
        // this.SetUserData(result.user);
        // this.afAuth.authState.subscribe((user) => {
          // if (user) {
            // this.router.navigate(['dashboard']);
          // }
        // });
      // })
      // .catch((error) => {
        // window.alert(error.message);
      // });
  // }
  // Sign up with email/password
  // SignUp(email: string, password: string) {
    // let payload = {
      // user: {
        // email: email,
        // password: password,
        // password_confirmation: password
      // }
    // }
    // return this.settlementService.signup(payload).subscribe((data: any) => {
      // return data;
    // });
    // return this.afAuth
      // .createUserWithEmailAndPassword(email, password)
      // .then((result) => {
        // /* Call the SendVerificaitonMail() function when new user sign 
        // up and returns promise */
        // this.SendVerificationMail();
        // this.SetUserData(result.user);
// 
        // // TODO: CREATE USER IN BACKEND
        // let payload: any = {
          // uid: result.user!.uid,
          // email: email
        // };
        // this.settlementService.createUser(payload);
      // })
      // .catch((error) => {
        // window.alert(error.message);
      // });
  // }
  // Send email verfificaiton when new user sign up
  // SendVerificationMail() {
    // return this.afAuth.currentUser
      // .then((u: any) => u.sendEmailVerification())
      // .then(() => {
        // this.router.navigate(['verify-email-address']);
      // });
  // }
  // Reset Forggot password
  // ForgotPassword(passwordResetEmail: string) {
    // return this.afAuth
      // .sendPasswordResetEmail(passwordResetEmail)
      // .then(() => {
        // window.alert('Password reset email sent, check your inbox.');
      // })
      // .catch((error) => {
        // window.alert(error);
      // });
  // }
  // Returns true when user is looged in and email is verified
  // get isLoggedIn(): boolean {
    // const user = JSON.parse(localStorage.getItem('user')!);
    // return user !== null && user.emailVerified !== false ? true : false;
  // }
  // Sign in with Google
  // GoogleAuth() {
    // return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      // this.router.navigate(['dashboard']);
    // });
  // }
  // Auth logic to run auth providers
  // AuthLogin(provider: any) {
    // return this.afAuth
      // .signInWithPopup(provider)
      // .then((result) => {
        // this.router.navigate(['dashboard']);
        // this.SetUserData(result.user);
      // })
      // .catch((error) => {
        // window.alert(error);
      // });
  // }
  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  // SetUserData(user: any) {
    // const userData: User = {
      // id: user.data.id,
      // email: user.data.email,
      // name: user.data.name,
      // last_name: user.data.last_name,
      // jti: user.data.jti,
      // role_id: user.data.role_id,
      // avatar: user.avatar
    // };
// 
    // localStorage.setItem('token', user.token);
    // localStorage.setItem('user', JSON.stringify(userData));
// 
    // return true;
  // }
  // Sign out
  // SignOut() {
    // return this.afAuth.signOut().then(() => {
      // localStorage.removeItem('user');
      // this.router.navigate(['sign-in']);
    // });
  // }
}