import { AuthService } from './../../shared/services/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../models/user';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { EscolarService } from 'src/app/services/escolar.service';
import { signInWithEmailAndPassword, getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { AppCheckService } from 'src/app/services/app-check.service';

@Component({
  selector: 'app-sign-in',
  template: `
    <div class="d-flex pt-4">
      <div class="flex-fill flex-column text-center">
        <div>
          <img src="assets/images/logo-escalas-r512x512.png" class="img-fluid text-center" width="48px" alt="escalas">
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="flex-grow-2 p-3 mt-5" style="width: 320px;">
        <h2 class="text-center mb-0 mt-5 mb-3">Benvenido</h2>
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="form-group text-start">
            <label for="email" class="text-sm sr-only d-none">Dirección de correo electrónico</label>
            <input type="text" formControlName="email" class="form-control form-control-lg border-2 rounded-3 bg-transparent py-3" placeholder="Dirección de correo electrónico*" #userName required style="font-size: 14px !important;">
            <div *ngIf="lf['email'].touched && lf['email'].invalid">
              <div *ngIf="lf['email'].errors?.['required']" class="text-danger text-sm">El correo electrónico es requerido.</div>
              <div *ngIf="lf['email'].errors?.['email']" class="text-danger text-sm">El correo electrónico no es válido</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="password" class="text-sm sr-only d-none">Contraseña</label>
            <input type="password" formControlName="password" class="form-control border-2 rounded-3 bg-transparent" placeholder="Contraseña" #userPassword required >
            <div *ngIf="lf['password'].touched && lf['password'].invalid">
              <div *ngIf="lf['password'].errors?.['required']" class="text-danger text-start text-sm">La contraseña es requerida.</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <button type="submit" class="btn btn-escalas w-100 border-0 rounded-3 py-3 shadow-sm">
              <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="loading" class="text-secondary me-2"></fa-icon>
              <!-- fa-icon [icon]="faLock" *ngIf="!loading" class="text-light me-2"></!-->
              <span style="font-weight: 400;">Continuar</span>
            </button>
          </div>
          <div class="forgotPassword mt-4 mb-3 text-center" style="font-size: 14px;">
            ¿No tienes cuenta? <a routerLink="/register-user" class="text-sm">Regístrate</a>
          </div>
        </form>
        <hr>
        <div class="d-flex justify-content-center mt-4">
          <button class="gsi-material-button" style="width:340" (click)="loginGoogle()">
            <div class="gsi-material-button-state"></div>
            <div class="gsi-material-button-content-wrapper">
              <div class="gsi-material-button-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span class="gsi-material-button-contents">Continuar con Google</span>
              <span style="display: none;">Continuar con Google</span>
            </div>
          </button>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <button class="gsi-material-button" style="width:340">
            <div class="gsi-material-button-state"></div>
            <div class="gsi-material-button-content-wrapper">
              <div class="gsi-material-button-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span class="gsi-material-button-contents">Continuar con Apple</span>
              <span style="display: none;">Continuar con Apple</span>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="mt-5 pt-5">
        <a href="https://escalas.io/aviso-de-privacidad/" class="text-sm mt-5">Aviso de privacidad</a> <span class="text-sm mt-5 ps-3 pe-3"> | </span>
        <a href="https://escalas.io/terminos-y-condiciones/" class="text-sm mt-5">Términos y condiciones</a>
      </div>
    </div>
  `,
  styleUrls: []
})
export class SignInComponent implements OnInit {
  loginForm!: FormGroup;
  loading: boolean = false;

  protected version = environment.app_version;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  faCircleNotch = faCircleNotch;
  faLock = faLock;


  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: EscolarService,
    private router: Router,
    private appCheck: AppCheckService
  ) { }

  get lf() {
    return this.loginForm.controls;
  }

  get email() {
    return this.loginForm.get('email');
  }
  
  ngOnInit(): void { 
    if (localStorage.getItem('token')) {
      // window.location.href = '';
      this.router.navigate(['dashboard']);
    }

    this.initLoginForm();
  }

  private initLoginForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  async login () {
    this.loading = true;
    if (this.loginForm.invalid) {
      this.toastr.error('No está completa la información.', 'Error');
      this.loading = false;
      return;
    }

    try {
      const userCredentials = await signInWithEmailAndPassword(getAuth(), this.loginForm.value.email, this.loginForm.value.password);
      const user = userCredentials.user;

      // Get the ID token for the signed-in user
      const idToken = await user.getIdToken();

      let payload = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      };

      this.store.login(user, idToken).subscribe({
        next: (data: any) => {
          if (!data.error) {
            if (this.SetUserData(data)) {
              this.store.getRole(data.role_id, data.token).subscribe((role: any) =>{
                localStorage.setItem('role', JSON.stringify({ role: role}));
                localStorage.setItem('permissions', role.permissions);

                this.store.getSchool(data.school_id).subscribe((school: any) => {
                  localStorage.setItem('school', JSON.stringify(school));

                  this.store.getCampuses({ school_id: school.school_id }).subscribe((campuses: any) => {
                    localStorage.setItem('campuses', JSON.stringify(campuses.data));

                    this.router.navigate(['/dashboard']);
                    // window.location.reload();
                  }); 
                });
              });
            }
          } else {
            this.toastr.error('Credenciales equivocadas por favor verifica e intenta de nuevo.', 'Error');
            this.loading = false;
          }
        },
        error: (error: any) => {
          this.toastr.error('Credenciales equivocadas por favor verifica e intenta de nuevo.', 'Error');
          this.loading = false;
        }
      });
    } catch (error) {
      this.toastr.error('Credenciales equivocadas por favor verifica e intenta de nuevo.', 'Error');
      this.loading = false;
    }

  }

  loginGoogle() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(getAuth(), provider).then((result: any) => {});
  }

  SetUserData(user: any) {
    const userData: User = {
      id: user.user_id,      
      name: user.name,
      last_name: user.last_name,
      email: user.email,      
      uid: user.uid,
      fpm_token: user.token,
      is_active: user.is_active,
      notify: user.notify,
      phone_number: user.phone_number,
      role_id: user.role_id,
      school_id: user.school_id,
    };

    localStorage.setItem('token', user.token);
    localStorage.setItem('refresh_token', user.refresh_token);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('preferences', JSON.stringify({ 
      theme: 'light',
      layout: 'vertical',
      sidebarCollapsed: false,
      viewPrefs: 'btnList',
      per_page: 30,
      campus_id: null,
      section_id: null,
    }));

    return true;
  }
}
