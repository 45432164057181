import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import MediumEditor from 'medium-editor';

// declare const MediumEditor: any;

@Directive({
  selector: '[appMediumEditor]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MediumEditorDirective,
      multi: true
    }
  ]
})
export class MediumEditorDirective implements OnInit, OnDestroy, ControlValueAccessor {
  @Input('appMediumEditor') options: any;

  private editor: any;
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.editor = new MediumEditor(this.el.nativeElement, this.options);

    this.editor.subscribe('editableInput', (event: any, editable: any) => {
      this.onChange(this.editor.getContent());
    });
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.editor.setContent(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
