import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';
import { Professor } from 'src/app/models/professor.model';
import { School } from 'src/app/models/school.model';
import { Section } from 'src/app/models/section.model';
import { Notice } from 'src/app/models/notice.model';
import { Campus } from 'src/app/models/campus.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-professors',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo!.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb></xng-breadcrumb>
      </div>
      <div class="d-flex mb-2">
        <div class="flex-fill">
          <div class="flex-column">
            <h4 class="mb-0 mt-2">Perfil del profesor</h4>
            <p>Configura la información del profesor para que pueda ver la información personalizada.</p>
          </div>
          <div class="flex-column">
            <app-form-professor
              [professor]="professor"
              [sections]="sections"
              [school]="school"
              [campuses]="campuses"
              (professorData)="createProfessor($event)"
            ></app-form-professor>
          </div>
        </div>
        <div class="flex-grow-1">
          <app-live-feed 
            [school]="school" 
            [user_id]="professor?.user?.id"
          >
          </app-live-feed>
          <div class="flex-column text-center">
            <p>Contenido que ve el profesor</p>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class CreateProfessorsComponent {
  professor: Professor | null = null;
  school: School | null = null;
  sections: Section[] = [];
  campus: Campus | undefined;
  campuses: Campus[] = [];

  notices: Notice[] = [];
  escalasURI: string = environment.ESCALAS_WSPREFIX;

  loadingFeed: boolean = false;

  constructor(
    private escolarService: EscolarService,
    private router: Router,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = JSON.parse(localStorage.getItem('campuses') || '[]');
    this.loadingFeed = true;
  }

  createProfessor(event: any) {
    this.escolarService.createProfessor({ professor: event }).subscribe({
      next: (response: any) => {
        this.toastr.success('Profesor creado correctamente', 'Éxito');
        this.router.navigate(['/professors']);
      },
      error: (error: any) => {
        this.toastr.error('Error al crear el profesor', 'Error');
      }
    });
  }
}
