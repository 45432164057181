import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  constructor() { }

  noticeType: any[] = [
    { id: 1, name: 'Escuela', icon: 'faStar', color: '#FFC107' },
    { id: 3, name: 'Grado', icon: 'faStar', color: '#FFC107' },
    { id: 4, name: 'Grupo', icon: 'faStar', color: '#FFC107' },
    { id: 5, name: 'Familia', icon: 'faStar', color: '#FFC107' }
  ];

  priority: any = [
    { id: 0, name: 'Sin prioridad', color: '#6C757D'},
    { id: 1, name: 'Baja', color: '#219C90' },
    { id: 2, name: 'Media', color: '#E9B824' },
    { id: 3, name: 'Alta', color: '#EE9322' },
    { id: 4, name: 'Urgente', color: '#D83F31' },
  ];

  status: any = [
    { id: 0, name: 'No asignada', color: '#2D2756', text: '#ADA3F0' },
    { id: 1, name: 'Pendiente', color: '#2D2756', text: '#ADA3F0' },
    { id: 2, name: 'En proceso', color: '#2D2756', text: '#ADA3F0' },
    { id: 3, name: 'Completada', color: '#2D2756', text: '#ADA3F0' },
    { id: 4, name: 'Cancelada', color: '#BD4089', text: '#42213D' },
  ];

  regimenes: any[] = [
    { id: 601, name: 'General de Ley Personas Morales' },
    { id: 603, name: 'Personas Morales con Fines no Lucrativos' },
    { id: 605, name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { id: 606, name: 'Arrendamiento' },
    { id: 607, name: 'Régimen de Enajenación o Adquisición de Bienes' },
    { id: 608, name: 'Demás ingresos' },
    { id: 609, name: 'Consolidación' },
    { id: 610, name: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
    { id: 611, name: 'Ingresos por Dividendos (socios y accionistas)' },
    { id: 612, name: 'Personas Físicas con Actividades Empresariales y Profesionales' },
    { id: 614, name: 'Ingresos por intereses' },
    { id: 615, name: 'Régimen de los ingresos por obtención de premios' },
    { id: 616, name: 'Sin obligaciones fiscales' },
    { id: 620, name: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { id: 621, name: 'Incorporación Fiscal' },
    { id: 622, name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { id: 623, name: 'Opcional para Grupos de Sociedades' },
    { id: 624, name: 'Coordinados' },
    { id: 625, name: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
    { id: 626, name: 'Régimen Simplificado de Confianza' },
    { id: 628, name: 'Hidrocarburos' },
    { id: 629, name: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
    { id: 630, name: 'Enajenación de acciones en bolsa de valores' }
  ];

  usosCFDI: any[] = [
    { id: 'G01', name: 'Adquisición de mercancías.' },
    { id: 'G02', name: 'Devoluciones, descuentos o bonificaciones.' },
    { id: 'G03', name: 'Gastos en general.' },
    { id: 'I01', name: 'Construcciones.' },
    { id: 'I02', name: 'Mobiliario y equipo de oficina por inversiones.' },
    { id: 'I03', name: 'Equipo de transporte.' },
    { id: 'I04', name: 'Equipo de computo y accesorios.' },
    { id: 'I05', name: 'Dados, troqueles, moldes, matrices y herramental.' },
    { id: 'I06', name: 'Comunicaciones telefónicas.' },
    { id: 'I07', name: 'Comunicaciones satelitales.' },
    { id: 'I08', name: 'Otra maquinaria y equipo.' },
    { id: 'D01', name: 'Honorarios médicos, dentales y gastos hospitalarios.' },
    { id: 'D02', name: 'Gastos médicos por incapacidad o discapacidad.' },
    { id: 'D03', name: 'Gastos funerales.' },
    { id: 'D04', name: 'Donativos.' },
    { id: 'D05', name: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
    { id: 'D06', name: 'Aportaciones voluntarias al SAR.' },
    { id: 'D07', name: 'Primas por seguros de gastos médicos.' },
    { id: 'D08', name: 'Gastos de transportación escolar obligatoria.' },
    { id: 'D09', name: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
    { id: 'D10', name: 'Pagos por servicios educativos (colegiaturas).' },
    { id: 'S01', name: 'Sin efectos fiscales.' },
    { id: 'CP01', name: 'Pagos' },
    { id: 'CN01', name: 'Nómina' }
  ];

  tipoFactura: any = [
    { id: 0, name: 'Ingreso' },
    { id: 1, name: 'Complemento' },
    { id: 2, name: 'Nota de crédito' },
    { id: 3, name: 'Global' },
    { id: 4, name: 'Cancelada' },
    { id: 5, name: 'Ingreso A' }
  ];

  edos: any[] = [
    { "name": "Aguascalientes", "value": "AGU" },
    { "name": "Baja California", "value": "BCN" },
    { "name": "Baja California Sur", "value": "BCS" },
    { "name": "Campeche", "value": "CAM" },
    { "name": "Chiapas", "value": "CHP" },
    { "name": "Chihuahua", "value": "CHH" },
    { "name": "Ciudad de México", "value": "CMX" },
    { "name": "Coahuila", "value": "COA" },
    { "name": "Colima", "value": "COL" },
    { "name": "Durango", "value": "DUR" },
    { "name": "Guanajuato", "value": "GUA" },
    { "name": "Estado de Guerrero", "value": "GRO" },
    { "name": "Estado de Hidalgo", "value": "HID" },
    { "name": "Jalisco", "value": "JAL" },
    { "name": "Estado de México", "value": "MEX" },
    { "name": "Michoacán", "value": "MIC" },
    { "name": "Morelos", "value": "MOR" },
    { "name": "Nayarit", "value": "NAY" },
    { "name": "Nuevo León", "value": "NLE" },
    { "name": "Oaxaca", "value": "OAX" },
    { "name": "Puebla", "value": "PUE" },
    { "name": "Querétaro", "value": "QUE" },
    { "name": "Quintana Roo", "value": "ROO" },
    { "name": "San Luis Potosí", "value": "SLP" },
    { "name": "Sinaloa", "value": "SIN" },
    { "name": "Sonora", "value": "SON" },
    { "name": "Tabasco", "value": "TAB" },
    { "name": "Tamaulipas", "value": "TAM" },
    { "name": "Tlaxcala", "value": "TLA" },
    { "name": "Veracruz", "value": "VER" },
    { "name": "Estado de Yucatán", "value": "YUC" },
    { "name": "Zacatecas", "value": "ZAC "}
  ];

  years: any[] = [
    { "name": "2024", "value": "2024" },
    { "name": "2025", "value": "2025" },
    { "name": "2026", "value": "2026" },
    { "name": "2027", "value": "2027" },
    { "name": "2028", "value": "2028" },
    { "name": "2029", "value": "2029" },
    { "name": "2030", "value": "2030" },
    { "name": "2031", "value": "2031" },
    { "name": "2032", "value": "2032" },
    { "name": "2033", "value": "2033" },
    { "name": "2034", "value": "2034" },
    { "name": "2035", "value": "2035" }
  ];

  months: any[] = [
    { "name": "01", "value": "01" },
    { "name": "02", "value": "02" },
    { "name": "03", "value": "03" },
    { "name": "04", "value": "04" },
    { "name": "05", "value": "05" },
    { "name": "06", "value": "06" },
    { "name": "07", "value": "07" },
    { "name": "08", "value": "08" },
    { "name": "09", "value": "09" },
    { "name": "10", "value": "10" },
    { "name": "11", "value": "11" },
    { "name": "12", "value": "12" }
  ];

  trxStatus: any[] = [
    { id: 0, name: 'Sin estatus', color: '#FFC107' },
    { id: 1, name: 'Pendiente', color: '#6C757D' },
    { id: 2, name: 'Rechazada', color: '#DC3545' },
    { id: 3, name: 'Aprobada', color: '#28A745' },
    { id: 4, name: 'Rechazada', color: '#DC3545' },
  ];
}
