import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { EscolarService } from 'src/app/services/escolar.service';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';
import { Tutor } from 'src/app/models/tutor.model';
import { School } from 'src/app/models/school.model';
import { Student } from 'src/app/models/student.model';
import { Notice } from 'src/app/models/notice.model';
import { Campus } from 'src/app/models/campus.model';

@Component({
  selector: 'app-edit-tutors',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo?.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="d-flex">
        <div class="flex-fill">
          <div class="flex-column">
            <h4>Perfil del Padre de familia</h4>
            <p>Configura la información del profesor para que pueda ver la información personalizada.</p>
          </div>
          <div class="flex-column">
            <div class="d-flex flex-column">
              <app-form-tutor 
                [tutor]="tutor!"
                [students]="students"
                [campuses]="campuses"
                [school]="school!"
                (tutorData)="updateTutor($event)"
              ></app-form-tutor>              
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <app-live-feed 
            [school]="school"
            [user_id]="tutor?.user?.id"
          ></app-live-feed>
          <div class="flex-column text-center">
            <p>Contenido que ve el padre de familia</p>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class EditTutorsComponent {
  tutor: Tutor | null = null;
  school: School | null = null;
  students: Student[] = [];
  notices: Notice[] = [];
  campuses: Campus[] = [];
  permissions: any = {};

  escalasURI: string = environment.ESCALAS_WSPREFIX;
  formUserTutor: FormGroup = new FormGroup({});

  constructor(
    private store: EscolarService,    
    public icons: IconsService,
    private route: Router,
    private router: ActivatedRoute,
  ) {
    this.school = JSON.parse(localStorage.getItem('school') || '{}');
    this.permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
    this.campuses = JSON.parse(localStorage.getItem('campuses') || '[]');
    this.router.params.subscribe((params) => {
      this.store.getTutor(params['id']).subscribe((tutor: any) => {
        this.tutor = tutor;
        if (tutor.family) {
          this.store.getStudents({ family_id: tutor.family.family_id }).subscribe((students: any) => {
            this.students = students.data;
          });
        }

        if (tutor.user) {
          this.store.getTutorFeed(tutor.user.id, { page: 1, per_page: 10}).subscribe((feed: any) => {
            this.notices = feed.data;
          });
        }

      });
    })
  }

  updateTutor(tutor: any) {
    if (!this.tutor?.user!.phone_number && tutor.user!.phone_number) {
      // CREATE USER in FIREBASE and API and confirm
      // Link user to tutor

      this.store.createUser({
        name: tutor.name,
        last_name: tutor.last_name,
        uid: '',
        school_id: this.school!.school_id,
        phone_number: tutor.phone_number,
        role_id: tutor.role_id
      });
    } else if (this.tutor?.user!.phone_number != tutor.phone_number) {
      // UPDATE USER and confirm
      // update user link to tutor
    }

    this.store.updateTutor(tutor.tutor_id, { tutor: tutor }).subscribe((data: any) => {
      this.route.navigate(['/tutors']);
    });
  }
}
