import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { School } from 'src/app/models/school.model';
import { Campus } from 'src/app/models/campus.model';
import { Family } from 'src/app/models/family.model';
import { Section } from 'src/app/models/section.model';

@Component({
  selector: 'app-index-families',
  template: `
    <div class="bg-white p-4 rounded-4 shadow"> <!-- col-12 col-lg-10 offset-lg-1 mt-3 mb-3 -->
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo.url}}" width="18" height="18" class="ms-2" *ngIf="school.logo">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="d-flex mb-2">
        <div class="">
          <h1 class="mb-0"><fa-icon [icon]="icons.faChild" class="me-3" style="font-size: 0.7em; position: relative; top: -3px;"></fa-icon> Familias<span class="text-muted" style="font-size: 0.5em;">({{meta.total_entries}})</span></h1>
        </div>
        <div class="flex-fill text-end mt-2">
          <div class="float-end">
            <div class="btn-group border border-1 border-secondary me-2" role="group" aria-label="Basic example">
              <button id="btnList" #btnList type="button" class="btn btn-outline-secondary" (click)="selectView('btnList')">
                <fa-icon [icon]="icons.faList"></fa-icon>
              </button>
              <button id="btnBloc" #btnBloc type="button" class="btn btn-outline-secondary" (click)="selectView('btnBloc')">
              <fa-icon [icon]="icons.faGrip"></fa-icon>
              </button>
            </div>            
            <div class="d-inline-block">
              <button class="btn btn-outline-secondary ms-2 border-2" (click)="open()">
                <fa-icon [icon]="icons.faSliders" class="me-2"></fa-icon>
                Filtrar
              </button>
            </div>
            <button class="btn btn-dark ms-2" routerLink="create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar familia
            </button>
          </div>
          <div class="form-group float-end me-3" style="width: 200px;">
            <div class="input-group">
              <div class="input-group-addon border border-2 rounded-start-2 border-secondary border-end-0 ps-2 text-secondary" style="padding-top: 7px;">
                <fa-icon [icon]="icons.faSearch" ></fa-icon>
              </div>
              <!-- input class="form-control border-2 border-secondary bg-transparent border-start-0" placeholder="Buscar" -->
              <input id="typeahead-basic" type="text" class="form-control border-2 border-secondary bg-transparent border-start-0" [(ngModel)]="model"  /> <!-- [ngbTypeahead]="searchx" -->
            </div>
          </div>
        </div>
      </div>      
      <div class="mt-1" *ngIf="activeView=='btnList'">

        <table class="table table-striped table-hover table-responsive">
          <thead>
            <tr>
              <th class="bg-light text-secondary rounded-start-2 align-items-center align-middle">Nombre</th>
              <th class="bg-light text-secondary align-items-center align-middle">Familia</th>
              <th class="bg-light text-secondary">Campus</th>
              <th class="bg-light text-secondary">Grado</th>
              <th class="bg-light text-secondary rounded-end-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr app-tr-families [family]="family" *ngFor="let family of families | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">              
            </tr>
          </tbody>
        </table>
        <div class="text-center py-5" *ngIf="families.length==0&&loading">
          <fa-icon [icon]="icons.faCircleNotch" size="4x" animation="spin" class="text-escalas mt-5"></fa-icon>
          <p class="text-muted mt-5">Cargando...</p>
        </div>
        <div class="py-5 text-center" *ngIf="families.length==0&&!loading">
            <img src="/assets/images/empty-state.png" width="240px">
            <h5 class="text-muted">No se encontraron registros</h5>
          </div>
        <div class="d-flex justify-content-center">
          <pagination-controls
            (pageChange)="p = $event"
            (pageChange)="pageChanged($event)"
            previousLabel="Prev."
            nextLabel="Sig."
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            *ngIf="families.length>0"
          ></pagination-controls>
        </div>

        
        <!-- div class="list-group border-0" *ngIf="families.length>0">          
          <div 
            class="list-group-item bg-transparent py-2 px-3 border-2 border-secondary rounded-2 mb-2"
            app-item-family
            [family]="family"
            [permissions]="permissions"
            (updateStudent)="saveFamily($event)"
            (deleteStudent)="removeFamily($event)"
            style="height: 70px;"
            *ngFor="let family of families | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }"
          >
          </div>
        </!-->
      </div>
    </div>
  `,
  styleUrls: []
})
export class IndexFamiliesComponent {
  @ViewChild('btnList') btnList!: ElementRef;
  @ViewChild('btnBloc') btnBloc!: ElementRef;

  school: School;  
  families: Family[] = [];
  meta: any = {};
  permissions: any = {};
  loading: boolean = false;
  viewPrefs: any = {};
  prefs: any = {};

  campuses: Campus[] = [];
  sections: Section[] = [];

  model: any;

  activeView: string = 'btnList';

  payload: any = {
    per_page: 50,
    page: 1,
  };
  p: number = 1;

  constructor(
    private escolarService: EscolarService,
    private router: Router,
    private toastr: ToastrService,
    public icons: IconsService
  ) { 
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = localStorage.getItem('campuses') != null ? JSON.parse(localStorage.getItem('campuses')!) : [];
    this.payload.campus_id = this.campuses.map((item: any) => item.campus_id);
    this.loadFamilies(this.payload);
  }

  loadFamilies(payload: any) {
    this.loading = true;
    this.escolarService.getFamilies(payload).subscribe({
      next: (families: any) => {
        this.families = families.data;
        this.meta = families.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al obtener los estudiantes.', 'Error');
        this.loading = false;
      }
    });
  }

  open() {}

  saveFamily(event: any) {}

  removeFamily(event: any) {}

  search() {}

  selectView(event: any) {
    this.btnList.nativeElement.classList.remove('active');
    this.btnBloc.nativeElement.classList.remove('active');

    if (event == 'btnList') {
      this.btnList.nativeElement.classList.add('active');
    } else if (event == 'btnBloc') {
      this.btnBloc.nativeElement.classList.add('active');
    }

    this.activeView = event; //  == '' ? 'btnList' : 'btnBloc';
    localStorage.setItem('viewPrefs', this.activeView);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadFamilies(this.payload);
  }

}
