import { Component, AfterViewInit, ViewChild, ElementRef, Input, SimpleChanges, Output, EventEmitter, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-modal-image-generator',
  template: `
    <ng-template #modalImageGenerator let-modal>
      <div class="modal-header">
        <h3>Generador de imágenes</h3>
      </div>
      <div class="modal-body p-4">
        <div class="d-flex">
          <div class="px-4">
            <!-- Live Preview -->
            <div><label class="form-control-label">Vista previa:</label></div>
            <div *ngIf="visible">
              <canvas #canvasElement id="canvasElement"></canvas>
            </div>
          </div>
          <div class="flex-fill">
            <div class="container">
              <form [formGroup]="postForm">
                <div class="d-flex">
                  <div class="flex-fill flex-grow-1" style="width: 50%;">
                    <div class="form-group text-start">
                      <label for="postText" class="form-control-label">Enter your post text:</label>
                      <textarea id="postText" class="form-control" formControlName="postText" rows="5"></textarea>
                    </div>
                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label for="textFont" class="form-control-label">Tipografía:</label>
                        </div>
                        <div class="ms-auto text-end">
                          <select id="textFont" class="form-select" formControlName="textFont">
                            <option value="Arial">Arial</option>
                            <option value="Verdana">Verdana</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Tahoma">Tahoma</option>
                            <option value="Courier New">Courier New</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label for="textColor" class="form-control-label">Text Color:</label>
                        </div>
                        <div class="ms-auto text-end">
                          <input type="color" id="textColor" formControlName="textColor">
                        </div>
                      </div>
                    </div>
                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label for="textSize" class="form-control-label">Text Size (px):</label>
                        </div>
                        <div class="ms-auto text-end">
                          <input type="number" id="textSize" class="form-control" formControlName="textSize" min="10" max="50">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ms-4">
                    <!-- Border Settings -->
                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label class="form-control-label" for="borderColor">Border Color:</label>
                        </div>
                        <div class="ms-auto text-end">
                          <input type="color" id="borderColor" formControlName="borderColor">
                        </div>
                      </div>
                    </div>
                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label class="form-control-label" for="borderWidth">Border Width (px):</label>
                        </div>
                        <div class="ms-auto text-end">
                          <input type="number" id="borderWidth" class="form-control" formControlName="borderWidth" min="1" max="50">
                        </div>
                      </div>
                    </div>

                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label class="form-control-label" for="padding">Padding (px):</label>
                        </div>
                        <div class="ms-auto text-end">
                          <input type="number" id="padding" class="form-control" formControlName="padding" min="0" max="100">
                        </div>
                      </div>
                    </div>

                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label class="form-control-label" for="backgroundColor">Background Color:</label>
                        </div>
                        <div class="ms-auto text-end">
                          <input type="color" id="backgroundColor" formControlName="backgroundColor">
                        </div>
                      </div>
                    </div>

                    <!-- Image Upload -->
                    <!-- div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label class="form-control-label" for="imageFile">Upload an Image (PNG/JPG):</label>
                        </div>
                        <div class="ms-auto text-end">
                          <input type="file" id="imageFile" (change)="onImageUpload($event)" accept="image/png, image/jpeg">
                        </div>
                      </div>
                    </!-->

                    <!-- Image Positioning -->
                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label class="form-control-label" for="imagePosition">Image Position:</label>
                        </div>
                        <div class="ms-auto text-end">
                          <select id="imagePosition" class="form-select" formControlName="imagePosition">
                            <option value="top">Top</option>
                            <option value="center">Center</option>
                            <option value="bottom">Bottom</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <!-- Image Resize -->
                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label class="form-control-label" for="imageWidth">Image Width (% of Canvas):</label>
                        </div>
                        <div class="ms-auto text-end">
                          <input type="range" id="imageWidth" formControlName="imageWidth" min="10" max="100" step="1">
                        </div>
                      </div>
                    </div>

                    <div class="form-group my-3">
                      <div class="d-flex">
                        <div class="flex-fill text-start">
                          <label class="form-control-label" for="imageOpacity">Image Opacity:</label>
                        </div>
                        <div class="ms-auto text-end">
                          <input type="range" id="imageOpacity" formControlName="imageOpacity" min="0.1" max="1" step="0.1">
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="d-flex text-end mt-3">
                  <div class="flex-fill">
                    <button type="button" class="btn btn-link text-secondary me-2" (click)="dismiss()">Cancelar</button>
                    <button type="submit" class="btn btn-escalas">Generar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: []
})
export class ModalImageGeneratorComponent implements OnInit, AfterViewInit {
  @ViewChild('canvasElement', { static: false }) canvasElement!: ElementRef<HTMLCanvasElement>;

  @ViewChild('modalImageGenerator') modalImageGenerator!: ElementRef;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();

  postForm: FormGroup;
  visible: boolean = false;
  private modalRef: NgbModalRef | undefined;
  uploadedImage: HTMLImageElement | null = null;
  frameImages: { [key: string]: string } = {
    valentine: 'assets/frames/valentine.png',
    birthday: 'assets/frames/birthday.png',
    christmas: 'assets/frames/christmas.png'
  };

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {
    this.postForm = this.fb.group({
      postText: ['', [Validators.required, Validators.maxLength(200)]],
      borderColor: ['#333333', Validators.required],
      borderWidth: [5, [Validators.required, Validators.min(1), Validators.max(50)]],
      padding: [20, [Validators.required, Validators.min(0), Validators.max(100)]],
      backgroundColor: ['#f4f4f4', Validators.required],
      imageWidth: [80, [Validators.required, Validators.min(10), Validators.max(100)]],
      imagePosition: ['center', Validators.required],
      imageOpacity: [1, [Validators.required, Validators.min(0.1), Validators.max(1)]],
      textFont: ['Arial', Validators.required],
      textColor: ['#000000', Validators.required],
      textSize: [24, [Validators.required, Validators.min(10), Validators.max(50)]],
      frame: ['']
    });
  }

  ngOnInit(): void {
    // Listen to form value changes and debounce to prevent excessive redraws
    this.postForm.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      this.drawCanvas();
    });

    // Initial draw
    // this.drawCanvas();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    console.log('waiting .... ', this.canvasElement); // Debug: Ensure the canvas is available
    if (this.canvasElement) {
      this.drawCanvas();
    }
  }

  onImageUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          this.uploadedImage = img;
          this.drawCanvas(); // Redraw the canvas when the image is loaded
        };
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  drawCanvas() {
    const {
      postText,
      borderColor,
      borderWidth,
      padding,
      backgroundColor,
      imageWidth,
      imagePosition,
      imageOpacity,
      textFont,
      textColor,
      textSize
    } = this.postForm.value;

    if (!this.canvasElement) {
      console.error('Canvas element is not available.');
      return;
    }

    const canvas = this.canvasElement.nativeElement;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const canvasWidth = 375 + 2 * padding; // Typical mobile width (375px)
    const canvasHeight = 375 + 2 * padding; // Typical mobile height (667px)

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Background
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // Draw uploaded image
    if (this.uploadedImage) {
      const imgWidth = (canvasWidth * imageWidth) / 100; // Scale based on user input
      const imgHeight = imgWidth * (this.uploadedImage.height / this.uploadedImage.width); // Maintain aspect ratio
      const imgX = (canvasWidth - imgWidth) / 2;

      let imgY = 0;
      if (imagePosition === 'center') {
        imgY = (canvasHeight - imgHeight) / 2;
      } else if (imagePosition === 'bottom') {
        imgY = canvasHeight - imgHeight - padding;
      }

      ctx.globalAlpha = imageOpacity; // Set opacity
      ctx.drawImage(this.uploadedImage, imgX, imgY, imgWidth, imgHeight);
      ctx.globalAlpha = 1; // Reset opacity
    }

    // Border
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderWidth;
    ctx.strokeRect(0, 0, canvasWidth, canvasHeight);

    // Render Text
    this.renderHTML(ctx, postText, padding, padding, canvasWidth - 2 * padding);

    // Overlay Frame
    // if (this.selectedFrameImage) {
      // ctx.drawImage(this.selectedFrameImage, 0, 0, canvasWidth, canvasHeight);
    // }

    // Text
    /*
    ctx.fillStyle = textColor;
    ctx.font = `${textSize}px ${textFont}`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    const maxWidth = canvasWidth - 2 * padding;
    const lineHeight = textSize * 1.2;
    const words = postText.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    words.forEach((word: string) => {
      const testLine = currentLine + word + ' ';
      const testWidth = ctx.measureText(testLine).width;

      if (testWidth > maxWidth) {
        lines.push(currentLine);
        currentLine = word + ' ';
      } else {
        currentLine = testLine;
      }
    });

    lines.push(currentLine);

    const textStartY = canvasHeight - lines.length * lineHeight - padding;
    lines.forEach((line, index) => {
      ctx.fillText(line, canvasWidth / 2, textStartY + index * lineHeight);
    });
    */
  }

  renderHTML(ctx: CanvasRenderingContext2D, rawHTML: string, x: number, y: number, maxWidth: number) {
    const parser = new DOMParser();
    const html = parser.parseFromString(rawHTML, 'text/html');
    const elements = html.body.childNodes;

    let currentY = y;

    elements.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        // Plain text
        ctx.font = '16px Arial';
        ctx.fillStyle = '#000';
        currentY = this.wrapText(ctx, node.textContent || '', x, currentY, maxWidth, 20);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        switch (element.tagName.toLowerCase()) {
          case 'h1':
            ctx.font = 'bold 24px Arial';
            ctx.fillStyle = '#000';
            currentY = this.wrapText(ctx, element.textContent || '', x, currentY, maxWidth, 30);
            break;
          case 'h2':
            ctx.font = 'bold 20px Arial';
            ctx.fillStyle = '#000';
            currentY = this.wrapText(ctx, element.textContent || '', x, currentY, maxWidth, 25);
            break;
          case 'b':
            ctx.font = 'bold 16px Arial';
            ctx.fillStyle = '#000';
            currentY = this.wrapText(ctx, element.textContent || '', x, currentY, maxWidth, 20);
            break;
          case 'p':
            ctx.font = '16px Arial';
            ctx.fillStyle = '#000';
            currentY = this.wrapText(ctx, element.textContent || '', x, currentY, maxWidth, 20);
            break;
          default:
            // Default styling for unsupported tags
            ctx.font = '16px Arial';
            ctx.fillStyle = '#000';
            currentY = this.wrapText(ctx, element.textContent || '', x, currentY, maxWidth, 20);
            break;
        }
      }
    });
  }

  wrapText(
    ctx: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number
  ): number {
    const words = text.split(' ');
    let line = '';

    words.forEach((word) => {
      const testLine = `${line}${word} `;
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && line !== '') {
        ctx.fillText(line, x, y);
        line = `${word} `;
        y += lineHeight;
      } else {
        line = testLine;
      }
    });

    ctx.fillText(line, x, y);
    return y + lineHeight;
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent, { size: 'xl', centered: true, backdrop: 'static' });
    
    this.modalRef.shown.subscribe(() => {
      setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      console.log('Modal shown');
      this.visible = true;
      this.drawCanvas();
      }, 1000);
    });
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }
}
