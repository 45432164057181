import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { School } from 'src/app/models/school.model';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-offcanvas-navbar',
  template: `
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">Menú</h5>
        <button type="button" class="btn-close btn-close-black" data-bs-dismiss="offcanvas" aria-label="Close" (click)="dismiss()"></button>
      </div>
      <div class="offcanvas-body">
      <ul class="nav nav-pills flex-column mb-auto mt-3" *ngIf="school">    
        <li class="nav-item" *ngIf="permissions?.menu?.dashboard">
          <a #dashboard id="dashboard" class="d-flex nav-link"
            routerLink="dashboard"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faDatabase"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Dashboard</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.schools&&school?.school_id">
          <a #schools id="schools" class="d-flex nav-link" 
            routerLink="/schools/{{school.school_id}}"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faGraduationCap"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Mi escuela</span>
            </div>
          </a>
        </li>
        <li class="nav-item " *ngIf="permissions?.menu?.notices">
          <a #notices id="notices" class="d-flex nav-link"
            routerLink="/notices/bulletins"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faBullhorn"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Comunicados</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.calendar">
          <a #calendar id="calendar" class="d-flex nav-link"
            routerLink="/calendar"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faCalendar"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Calendario</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.professors">
          <a #professors id="professors" class="d-flex nav-link"
            routerLink="/professors"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faChalkboardUser"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Profesores</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.students">
          <a #students id="students" class="d-flex nav-link"
            routerLink="/students"
          >
            <div class="ms-auto text-center" style="width: 30px;">
            <fa-icon [icon]="icons.faChild"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Alumnos</span>
            </div>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissions?.menu?.tutors">
          <a #tutors id="tutors" class="d-flex nav-link"
            routerLink="/tutors"
          >
            <div class="ms-auto text-center" style="width: 30px;">
              <fa-icon [icon]="icons.faUser"></fa-icon>
            </div>
            <div class="flex-fill ms-3">
              <span>Padres</span>
            </div>
          </a>
        </li>
      </ul>
        <hr />
        <ul class="nav nav-pills flex-column" *ngIf="permissions?.menu?.notices">
          <li class="nav-item ps-2">
            <a #uploader id="uploader"
              routerLink=""
              class="d-flex nav-link"
            >
              <div class="ms-auto text-center" style="width: 30px;">
                <fa-icon [icon]="icons.faUpload"></fa-icon>
              </div>
              <div class="flex-fill ms-3">
                <span>Cargar datos</span>
              </div>
            </a>
          </li>
          <li class="nav-item ps-2" *ngIf="permissions?.menu?.settings">
            <a #settings id="settings" class="d-flex nav-link"
              routerLink="/settings"
            >
              <div class="ms-auto text-center" style="width: 30px;">
                <fa-icon [icon]="icons.faCog"></fa-icon>
              </div>
              <div class="flex-fill ms-3">
                <span>Configuración</span>
              </div>
            </a>
          </li>
          <li class="nav-item ps-2" *ngIf="permissions?.menu?.notifications">
            <a #notifications id="notifications" class="d-flex nav-link"
              routerLink="/notifications"
            >
              <div class="ms-auto text-center" style="width: 30px;">
                <fa-icon [icon]="icons.faBell"></fa-icon>
              </div>
              <div class="flex-fill ms-3">
                <span>Notificaciones</span>
              </div>
              <div class="text-end me-2 mt-1">
                <div class="rounded-5 bg-primary text-light text-center" style="width: 18px; height: 18px; font-size: 10px; padding-top: 2px;">2</div>
              </div>
            </a>
          </li>
          <li class="nav-item pb-3 px-2">
            <a class="d-flex nav-link" (click)="closeSession()">
              <div class="ms-auto text-center" style="width: 30px;">
                <fa-icon [icon]="icons.faSignOutAlt"></fa-icon>
              </div>
              <div class="flex-fill ms-3">
                <span>Cerrar sesión</span>
              </div>
            </a>
          </li>
        </ul>
        <div style="position: absolute; bottom: 8px;">
          <div class="d-flex" *ngIf="school">
            <div class="d-flex border border-1 border-secondary bg-white rounded-4 ps-2 pe-3 py-2 shadow-sm">
              <div class="ms-2" style="margin-top: 2px;">
                <img src="https://api.escalas.io{{school!.logo!.url}}" width="44" *ngIf="school">
              </div>
              <div class="flex-fill ms-3 mt-1">
                <h4 class="mb-0" style="line-height: 0.9; font-size: 1.1rem;">{{school.name}}</h4>
                <p class="mb-0 text-sm text-wrap" style="font-size: 0.8em;">{{school.description}}</p>
              </div>
            </div>
          </div>
          <div class="d-flex mt-3 text-center">
            <div class="flex-fill">
              <p class="text-sm mb-0" style="font-size: 0.7em;">Powered by <a href="#">Input Output Co.</a></p>
            </div>
            <!-- div class="flex-fill text-end"><p class="text-sm text-muted">© 2025 Todos los derechos reservados</p></!-->
          </div>
        </div>
      </div>
  `,
  styles: ``
})
export class OffcanvasNavbarComponent {
  @Input() school: School | null = null;
  @Input() permissions: any;
  @Output() signOut = new EventEmitter<any>();

  constructor(
    public activeOffcanvas: NgbActiveOffcanvas,
    public icons: IconsService
  ) { }

  dismiss() {
    this.activeOffcanvas.dismiss('Cross click');
  }

  closeSession() {
    this.signOut.emit();
    this.activeOffcanvas.close();
  }

}
