

import { AuthGuard } from './shared/guard/auth.guard';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './admin/pages/dashboard/dashboard.component';

import { UsersComponent } from './admin/pages/users/users.component';
import { ProfileComponent } from './admin/pages/users/profile.component';
import { RolesComponent } from './admin/pages/roles/roles.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ConfirmPasswordChangeComponent } from './components/confirm-password-change/confirm-password-change.component';
import { RoleCreateComponent } from './admin/pages/roles/role-create.component';
import { RoleEditComponent } from './admin/pages/roles/role-edit.component';
import { UserCreateComponent } from './admin/pages/users/user-create.component';
import { EditSchoolComponent } from './admin/pages/schools/edit-school.component';
import { IndexNoticesMessageComponent } from './admin/pages/notices/index-notices-message.component';
import { IndexNoticesBulletinComponent } from './admin/pages/notices/index-notices-bulletin.component';
import { CreateNoticesMessageComponent } from './admin/pages/notices/create-notices-message.component';
import { EditNoticesMessageComponent } from './admin/pages/notices/edit-notices-message.component';
import { EditNoticesBulletinComponent } from './admin/pages/notices/edit-notices-bulletin.component';
import { CreateNoticesBulletinComponent } from './admin/pages/notices/create-notices-bulletin.component';
import { NotificationsComponent } from './admin/pages/notifications/notifications.component';
import { IndexStudentsComponent } from './admin/pages/students/index-students.component';
import { CreateStudentComponent } from './admin/pages/students/create-student.component';
import { EditStudentComponent } from './admin/pages/students/edit-student.component';
import { IndexTutorsComponent } from './admin/pages/tutors/index-tutors.component';
import { EditTutorsComponent } from './admin/pages/tutors/edit-tutors.component';
import { CreateTutorsComponent } from './admin/pages/tutors/create-tutors.component';
import { IndexFamiliesComponent } from './admin/pages/families/index-families.component';
import { CreateFamiliesComponent } from './admin/pages/families/create-families.component';
import { EditFamiliesComponent } from './admin/pages/families/edit-families.component';
import { DashboardSettingComponent } from './admin/pages/settings/dashboard-setting.component';
import { IndexProfessorsComponent } from './admin/pages/professors/index-professors.component';
import { CreateProfessorsComponent } from './admin/pages/professors/create-professors.component';
import { EditProfessorsComponent } from './admin/pages/professors/edit-professors.component';
import { IndexEventsComponent } from './admin/pages/events/index-events.component';
import { CreateEventsComponent } from './admin/pages/events/create-events.component';
import { EditEventsComponent } from './admin/pages/events/edit-events.component';
import { OnBoardingComponent } from './admin/pages/on-boarding/on-boarding.component';


const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'ingresar', component: SignInComponent },
  { path: 'register-user', component: SignUpComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address/:token', component: VerifyEmailComponent },
  { path: 'confirm-password-change/:token', component: ConfirmPasswordChangeComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },  
  { path: 'schools',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Escuelas', alias: 'schools'} },
    children: [
      { path: 'onboarding', component: OnBoardingComponent, canActivate: [AuthGuard] },
      { path: ':id', component: EditSchoolComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editar', alias: 'editSchool'} } }
    ]
  },
  {
    path: 'students',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Alumnos', alias: 'students'} },
    children: [
      { path: '', component: IndexStudentsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} } },
      { path: 'create', component: CreateStudentComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear alumno', alias: 'create'} } },
      { path: ':id', component: EditStudentComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'edit'} }  }
    ]
  },
  {
    path: 'tutors',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Padres', alias: 'tutors'} },
    children: [
      { path: '', component: IndexTutorsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} } },
      { path: 'create', component: CreateTutorsComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear tutor', alias: 'create'} } },
      { path: ':id', component: EditTutorsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'edit'} } }
    ]
  },
  {
    path: 'professors',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Profesores', alias: 'profesores'} },
    children: [
      { path: '', component: IndexProfessorsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} } },
      { path: 'create', component: CreateProfessorsComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear profesor', alias: 'create'} } },
      { path: ':id', component: EditProfessorsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'edit'} } }
    ]
  },
  {
    path: 'families',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Familias', alias: 'families'} },
    children: [
      { path: '', component: IndexFamiliesComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} } },
      { path: 'create', component: CreateFamiliesComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear familia', alias: 'create'} } },
      { path: ':id', component: EditFamiliesComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'edit'} } }
    ]
  },
  {
    path: 'notices',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Noticias', alias: 'notices'} },
    children: [
      { 
        path: 'messages',
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Mensajes', alias: 'messages'} },
        children: [
          { path: '', component: IndexNoticesMessageComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} } },
          { path: 'create', component: CreateNoticesMessageComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear mensaje', alias: 'create'} } },
          { path: ':id', component: EditNoticesMessageComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'edit'} } }
        ]
      },
      {
        path: 'bulletins',
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Boletines', alias: 'bulletins'}},
        children: [
          { path: '', component: IndexNoticesBulletinComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} } },
          { path: 'create', component: CreateNoticesBulletinComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear boletín', alias: 'create'} } },
          { path: ':id', component: EditNoticesBulletinComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'edit'} } }
        ],
      },
    ]
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardSettingComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Configuración', alias: 'dashboard'} } }
    ]
  },
  {
    path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Notificaciones', alias: 'notifications'} }
  },
  { path: 'calendar',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: IndexEventsComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Calendario', alias: 'tasks'} } },
      { path: 'create', component: CreateEventsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'taskCreate'} } },
      { path: ':id', component: EditEventsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'taskEdit'} } }
    ]
  },
  { path: 'roles',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Roles'}},
    children: [
      { path: '', component: RolesComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Rol', alias: 'roles'} }},
      { path: 'create', component: RoleCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Creando rol', alias: 'roleCreate'} }},
      { path: ':id', component: RoleEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editando rol', alias: 'roleEdit'} }},
    ]
  },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Usuarios', alias: 'users'} }},
  { path: 'users/create', component: UserCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear usuario', alias: 'userCreate'} }},
  { path: 'users/profile/:id', component: ProfileComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Perfil', alias: 'profile'} }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
