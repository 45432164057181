import { faEye, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-role',
  template: `
    <form>
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="roleForm" (ngSubmit)="saveRole()">
            <div class="form-group required mb-4 mt-3">
              <label for="name" class="form-label ms-2">Nombre del role</label>
              <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
              <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
                <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
              </div>
            </div>
            <div class="form-group required mb-4 mt-3" *ngIf="role">
              <label for="name" class="form-label ms-2">Grupo</label>
              <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" [value]="roleGroup" readonly>
              <!-- div *ngIf="ufc['name'].touched && ufc['name'].invalid">
                <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
              </!-->
            </div>

            <div class="form-group required mb-4 mt-3" *ngIf="!role">
              <label for="name" class="form-label ms-2">Grupo</label>
              <select class="form-select form-select-lg" (change)="selectGroup($event)">
                <option value="0">Selecciona un grupo</option>
                <option *ngFor="let group of groups" [value]="group.id">{{group.name}}</option>
              </select>
            </div>

            <div class="list-group shadow-sm">
              <div class="list-group-item list-group-item-light" > <!--  -->

              <div class="form-check form-switch mt-2 ps-3">
                  <input class="form-check-input form-check-input-lg float-end me-3" type="checkbox" value="profile" [checked]="permissions.menu.profile" (change)="updateMenu($event)">
                  <label class="form-check-label" for="flexCheckDefault">
                    Cambio de perfil
                  </label>
                </div>

                <div class="form-check form-switch mt-2 ps-3">
                  <input class="form-check-input form-check-input-lg float-end me-3" type="checkbox" value="admin" [checked]="permissions.menu.admin" (change)="updateMenu($event)">
                  <label class="form-check-label" for="flexCheckDefault">
                    Administración
                  </label>
                </div>
                <div class="list-group mt-3 mb-2">
                  <div class="list-group-item">
                    <div class="form-check form-switch ps-1">
                      <input
                        class="form-check-input float-end"
                        type="checkbox"
                        id="dashboard"
                        value="admin.active"
                        [checked]="permissions.modules.admin.dashboard.active"
                        [disabled]="!permissions.menu.admin"
                        (change)="updatePermissions($event)"
                      >
                      <label class="form-check-label" for="flexCheckDefault">
                        Vista general
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item">

                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Condominios</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="settlements"
                            value="admin.active"
                            [checked]="permissions.modules.admin.settlements.active"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label float-start" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="settlements"
                            value="admin.write"
                            [checked]="permissions.modules.admin.settlements.write"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label float-start" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Comercial</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="commercial"
                            value="admin.active"
                            [checked]="permissions.modules.admin.commercial.active"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label float-start" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="commercial"
                            value="admin.write"
                            [checked]="permissions.modules.admin.commercial.write"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label text-end" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>                    
                  </div>
                  <div class="list-group-item">

                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Pagos</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                        <input
                          class="form-check-input float-end"
                          type="checkbox"
                          id="billing"
                          value="admin.active"
                          [checked]="permissions.modules.admin.billing.active"
                          [disabled]="!permissions.menu.admin"
                          (change)="updatePermissions($event)"
                        >
                        <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEye"></fa-icon>
                        </label>
                      </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="billing"
                            value="admin.write"
                            [checked]="permissions.modules.admin.billing.write"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-12 mt-3 pe-0 ps-0">
                        <div class="list-group">
                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Pagos no emitidos</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.emitted"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.billing.emitted.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.emitted"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.billing.emitted.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Recibos no pagados</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.nopagos"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.billing.nopagos.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.nopagos"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.billing.nopagos.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Pagos</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.pagos"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.billing.pagos.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.pagos"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.billing.pagos.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Saldos</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.saldos"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.billing.saldos.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.saldos"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.billing.saldos.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Conceptos</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.conceptos"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.billing.conceptos.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.conceptos"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.billing.conceptos.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Auditor</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.auditor"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.billing.auditor.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="billing.auditor"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.billing.auditor.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Facturación</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="invoices"
                            value="admin.active"
                            [checked]="permissions.modules.admin.invoices.active"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="invoices"
                            value="admin.write"
                            [checked]="permissions.modules.admin.invoices.write"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>

                      <div class="col-12 mt-3 pe-0 ps-0">
                        <div class="list-group">
                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Dashboard</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.dashboard"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.invoices.dashboard.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.dashboard"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.invoices.dashboard.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Facturas ingreso</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.ingreso"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.invoices.ingreso.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.ingreso"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.invoices.ingreso.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Complementos</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.complementos"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.invoices.complementos.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.complementos"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.invoices.complementos.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Facturas globales</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.global"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.invoices.global.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.global"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.invoices.global.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Notas de crédito</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.notas"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.invoices.notas.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.notas"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.invoices.notas.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Facturas canceladas</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.canceladas"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.invoices.canceladas.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="invoices.canceladas"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.invoices.canceladas.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Inventario</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="inventory"
                            value="admin.active"
                            [checked]="permissions.modules.admin.inventory.active"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="inventory"
                            value="admin.write"
                            [checked]="permissions.modules.admin.inventory.write"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>

                      <div class="col-12 mt-3 pe-0 ps-0">
                        <div class="list-group">
                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Vehículos</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="inventory.vehiculos"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.inventory.vehiculos.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="inventory.vehiculos"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.inventory.vehiculos.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Habilidades</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="inventory.skills"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.inventory.skills.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="inventory.skills"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.inventory.skills.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Herramientas</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="inventory.tools"
                                    value="admin.active"
                                    [checked]="permissions.modules.admin.inventory.tools.active"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="inventory.tools"
                                    value="admin.write"
                                    [checked]="permissions.modules.admin.inventory.tools.write"
                                    [disabled]="!permissions.menu.admin"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>                    
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Reportes</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="reports"
                            value="admin.active"
                            [checked]="permissions.modules.admin.reports.active"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="reports"
                            value="admin.write"
                            [checked]="permissions.modules.admin.reports.write"
                            [disabled]="!permissions.menu.admin"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-group-item" >
                <div class="form-check form-switch mt-2 ps-3">
                  <input
                    class="form-check-input float-end me-3"
                    type="checkbox"
                    value="operation"
                    [checked]="permissions.menu.operation"
                    (change)="updateMenu($event)"
                  >
                  <label class="form-check-label" for="flexCheckDefault">
                    Operación
                  </label>
                </div>
                <div class="list-group mt-3 mb-2">

                  
                  
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Servicio técnico</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="service"
                            value="operation.active"
                            [checked]="permissions.modules.operation?.service.active"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="service"
                            value="operation.write"
                            [checked]="permissions.modules.operation?.service.write"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>

                      <div class="col-12 mt-3 pe-0 ps-0">
                        <div class="list-group">
                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Calendario</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.calendario"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.service.calendario.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.calendario"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.service.calendario.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Tareas</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.tareas"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.service.tareas.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.tareas"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.service.tareas.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Tareas no asignadas</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.unassigned"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.service.unassigned.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.unassigned"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.service.unassigned.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Tareas de suministro</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.refuel"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.service.refuel.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.refuel"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.service.refuel.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Constructor</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.builder"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.service.builder.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.builder"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.service.builder.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Actividades</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.activities"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.service.activities.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.activities"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.service.activities.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Tipo de actividades</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.activityTypes"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.service.activityTypes.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.activityTypes"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.service.activityTypes.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Días festivos</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.holidays"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.service.holidays.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="service.holidays"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.service.holidays.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Servicios</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="services"
                            value="operation.active"
                            [checked]="permissions.modules.operation?.services.active"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="services"
                            value="operation.write"
                            [checked]="permissions.modules.operation?.services.write"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>

                      <div class="col-12 mt-3 pe-0 ps-0">
                        <div class="list-group">
                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Amenidades</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="services.amenities"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.services.amenities.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="services.amenities"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.services.amenities.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Catálogo</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="services.catalogo"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.services.catalogo.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="services.catalogo"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.services.catalogo.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Amenidades</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="amenities"
                            value="operation.active"
                            [checked]="permissions.modules.operation?.amenities.active"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="amenities"
                            value="operation.write"
                            [checked]="permissions.modules.operation?.amenities.write"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>                    
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Proveedores</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="providers"
                            value="operation.active"
                            [checked]="permissions.modules.operation?.providers.active"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="providers"
                            value="operation.write"
                            [checked]="permissions.modules.operation?.providers.write"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Combustible</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="combustibles"
                            value="operation.active"
                            [checked]="permissions.modules.operation?.combustibles.active"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="combustibles"
                            value="operation.write"
                            [checked]="permissions.modules.operation?.combustibles.write"
                            [disabled]="!permissions.menu.operation"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>

                      <div class="col-12 mt-3 pe-0 ps-0">
                        <div class="list-group">
                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Tanques</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="combustibles.tanques"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.combustibles.tanques.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="combustibles.tanques"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.combustibles.tanques.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Suministros</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="combustibles.suministros"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.combustibles.suministros.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="combustibles.suministros"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.combustibles.suministros.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Pedidos</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="combustibles.pedidos"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.combustibles.pedidos.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="combustibles.pedidos"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.combustibles.pedidos.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-8">Zonas</div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="combustibles.zonas"
                                    value="operation.active"
                                    [checked]="permissions.modules.operation.combustibles.zonas.active"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEye"></fa-icon>
                                  </label>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-check form-switch ps-1">
                                  <input
                                    class="form-check-input float-end"
                                    type="checkbox"
                                    id="combustibles.zonas"
                                    value="operation.write"
                                    [checked]="permissions.modules.operation.combustibles.zonas.write"
                                    [disabled]="!permissions.menu.operation"
                                    (change)="updatePermissions($event)"
                                  >
                                  <label class="form-check-label" for="flexCheckDefault">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="list-group-item" >
                <div class="form-check form-switch mt-2 ps-3">
                  <input
                    class="form-check-input float-end me-3"
                    type="checkbox"
                    value="config"
                    [checked]="permissions.menu.config"
                    (change)="updateMenu($event)"
                  >
                  <label class="form-check-label" for="flexCheckDefault">
                    Configuración
                  </label>
                </div>
                <div class="list-group mt-3 mb-2">
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Técnicos</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="technicians"
                            value="config.active"
                            [checked]="permissions.modules.config?.technicians.active"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="technicians"
                            value="config.write"
                            [checked]="permissions.modules.config?.technicians.write"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Perfiles</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="profiles"
                            value="config.active"
                            [checked]="permissions.modules.config?.profiles.active"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="profiles"
                            value="config.write"
                            [checked]="permissions.modules.config?.profiles.write"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Inquilinos</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="tenants"
                            value="config.active"
                            [checked]="permissions.modules.config?.tenants.active"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="tenants"
                            value="config.write"
                            [checked]="permissions.modules.config?.tenants.write"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Roles</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="roles"
                            value="config.active"
                            [checked]="permissions.modules.config?.roles.active"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="roles"
                            value="config.write"
                            [checked]="permissions.modules.config?.roles.write"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                          <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="list-group-item">
                    <div class="row">
                      <div class="col-8">
                        <p class="mb-0">Administradores</p>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="admins"
                            value="config.active"
                            [checked]="permissions.modules.config?.admins.active"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEye"></fa-icon>
                          </label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-check form-switch ps-1">
                          <input
                            class="form-check-input float-end"
                            type="checkbox"
                            id="admins"
                            value="config.write"
                            [checked]="permissions.modules.config?.admins.write"
                            [disabled]="!permissions.menu.config"
                            (change)="updatePermissions($event)"
                          >
                          <label class="form-check-label" for="flexCheckDefault">
                            <fa-icon [icon]="faEdit"></fa-icon>
                          </label>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="list-group-item">
                <div class="form-check form-switch mt-2 ps-3">
                  <input
                    class="form-check-input float-end me-3"
                    type="checkbox"
                    value="tenants"
                    [checked]="permissions.menu.tenants"
                    (change)="updateMenu($event)"
                  >
                  <label class="form-check-label" for="flexCheckDefault">
                    Inquilino
                  </label>
                </div>
                <div class="list-group mt-3 mb-2">
                <div class="list-group-item">
                    <div class="form-check form-switch ps-1">
                      <input
                        class="form-check-input float-end"
                        type="checkbox"
                        id="dashboard"
                        value="tenants.active"
                        [checked]="permissions.modules.tenants?.dashboard"
                        [disabled]="!permissions.menu.tenants"
                        (change)="updatePermissions($event)"
                      >
                      <label class="form-check-label" for="flexCheckDefault">
                        Dashboard
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="form-check form-switch ps-1">
                      <input
                        class="form-check-input float-end"
                        type="checkbox"
                        id="payments"
                        value="tenants.active"
                        [checked]="permissions.modules.tenants?.payments"
                        [disabled]="!permissions.menu.tenants"
                        (change)="updatePermissions($event)"
                      >
                      <label class="form-check-label" for="flexCheckDefault">
                        Pagos
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="form-check form-switch ps-1">
                      <input
                        class="form-check-input float-end"
                        type="checkbox"
                        id="profile"
                        value="tenants.active"
                        [checked]="permissions.modules.tenants?.profile"
                        [disabled]="!permissions.menu.tenants"
                        (change)="updatePermissions($event)"
                      >
                      <label class="form-check-label" for="flexCheckDefault">
                        Perfil
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="form-check form-switch ps-1">
                      <input
                        class="form-check-input float-end"
                        type="checkbox"
                        id="amenitites"
                        value="tenants.active"
                        [checked]="permissions.modules.tenants?.amenitites"
                        [disabled]="!permissions.menu.tenants"
                        (change)="updatePermissions($event)"
                      >
                      <label class="form-check-label" for="flexCheckDefault">
                        Amenidades
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="form-check form-switch ps-1">
                      <input
                        class="form-check-input float-end"
                        type="checkbox"
                        id="payment_methods"
                        value="tenants.active"
                        [checked]="permissions.modules.tenants?.payment_methods"
                        [disabled]="!permissions.menu.tenants"
                        (change)="updatePermissions($event)"
                      >
                      <label class="form-check-label" for="flexCheckDefault">
                        Métodos de pago
                      </label>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="form-check form-switch ps-1">
                      <input
                        class="form-check-input float-end"
                        type="checkbox"
                        id="billing"
                        value="tenants.active"
                        [checked]="permissions.modules.tenants?.billing"
                        [disabled]="!permissions.menu.tenants"
                        (change)="updatePermissions($event)"
                      >
                      <label class="form-check-label" for="flexCheckDefault">
                        Pagos
                      </label>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            
            <div class="form-group required mb-4 mt-3 text-end">
              <button type="button" class="btn btn-link me-2" routerLink="/roles">Cancelar</button>
              <button type="submit" class="btn btn-success btn-lg rounded-5">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormRoleComponent implements OnInit {

  @Input() role: any;
  @Output() roleData = new EventEmitter<any>();

  roleForm: FormGroup = new FormGroup({});
  loading: boolean = false;

  roleGroup: string = "";
  group_id: number = 0;

  faEdit = faEdit;
  faEye = faEye;

  groups: any[] = [
    { id: 1, name: 'Administrador' },
    { id: 2, name: 'Técnico' },
    { id: 3, name: 'Inquilino' },
  ];

  permissions: any = {
    menu: {
      admin: false,
      operation: false,
      config: false,
      tenants: false,
      profile: true,
      auditor: false,
    },
    modules: {      
      admin: {
        dashboard: {
          active: false,
          write: false,
        },
        settlements: {
          active: true,
          write: true,
        },
        commercial: {
          active: false,
          write: false,
        },
        billing: {
          active: false,
          write: false,
          emitted: {
            active: false,
            write: false,
          },
          nopagos: {
            active: false,
            write: false,
          },
          pagos: {
            active: false,
            write: false,
          },
          saldos: {
            active: false,
            write: false,
          },
          conceptos: {
            active: false,
            write: false,
          },
          auditor: {
            active: false,
            write: false,
          }
        },
        invoices: {
          active: false,
          write: false,
          dashboard: {
            active: false,
            write: false,            
          },
          ingreso: {
            active: false,
            write: false,
          },
          complementos: {
            active: false,
            write: false,
          },
          global: {
            active: false,
            write: false,
          },
          notas: {
            active: false,
            write: false,
          },
          canceladas: {
            active: false,
            write: false,
          },          
        },
        reports: {
          active: false,
          write: false,
        },
        inventory: {
          active: false,
          write: false,
          vehiculos: {
            active: false,
            write: false,
          },
          skills: {
            active: false,
            write: false,
          },
          tools: {
            active: false,
            write: false,
          },
        },
      },
      operation: {
        service: {
          active: false,
          write: false,
          calendario: {
            active: false,
            write: false,
          },
          tareas: {
            active: false,
            write: false,
          },
          unassigned: {
            active: false,
            write: false,
          },
          refuel: {
            active: false,
            write: false,
          },
          builder: {
            active: false,
            write: false,
          },
          activities: {
            active: false,
            write: false,
          },
          activityTypes: {
            active: false,
            write: false,
          },
          holidays: {
            active: false,
            write: false,
          },          
        },
        services: {
          active: false,
          write: false,
          amenities: {
            active: false,
            write: false,
          },
          solicitudes: {
            active: false,
            write: false,
          },
          catalogo: {
            active: false,
            write: false,
          },
        },
        amenities: {
          active: false,
          write: false,
        },
        providers: {
          active: false,
          write: false,
        },
        combustibles: {
          active: false,
          write: false,
          tanques: {
            active: false,
            write: false,
          },
          suministros: {
            active: false,
            write: false,
          },
          pedidos: {
            active: false,
            write: false,
          },
          zonas: {
            active: false,
            write: false,
          },
        },
      },
      config: {
        technicians: {
          active: false,
          write: false,
        },
        admins: {
          active: false,
          write: false,
        },
        profiles: {
          active: false,
          write: false,
        },
        tenants: {
          active: false,
          write: false,
        },
        roles: {
          active: false,
          write: false,
        },
      },
      tenants: {
        dashboard: {
          active: false,
          write: false,
        },
        amenities: {
          active: false,
          write: false,
        },
        profile: {
          active: false,
          write: false,
        },
        payment_methods: {
          active: false,
          write: false,
        },
        billing: {
          active: false,
          write: false,
        },
        payments: {
          active: false,
          write: false,
        },
        invoices: {
          active: false,
          write: false,
        },
      }
    }
  };

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private toastr: ToastrService,
    private activateRoute: ActivatedRoute,
  ) { 
    
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.roleForm = this.fb.group({
      name: ['', Validators.required],
      group_id: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['role'].currentValue) {
      this.roleForm.patchValue(changes['role'].currentValue);
      this.roleGroup = this.groups.find(g => g.id === changes['role'].currentValue.group_id)?.name;
      this.group_id = changes['role'].currentValue.group_id;
      if (changes['role'].currentValue.permissions) {
        this.permissions = JSON.parse(changes['role'].currentValue?.permissions);
      }
    }
  }

  updateMenu(e: any) {
    this.permissions.menu[e.target.value] = e.target.checked;
  }

  updatePermissions(e: any) {
    if (e.target.value.split('.').length > 0) {
      let module_action: any = e.target.value.split('.');
      let target_action: any = e.target.id.split('.');

      if (e.target.id.split('.').length == 2) {
        this.permissions.modules[module_action[0]][target_action[0]][target_action[1]][module_action[1]] = e.target.checked;
      } else {
        // [e.target.id][module_action[0]]
        this.permissions.modules[module_action[0]][e.target.id][module_action[1]] = e.target.checked;
      }

      return;
    } else {
      this.permissions.modules[e.target.value][e.target.id] = e.target.checked;
    }
  }

  selectGroup(e: any) {
    this.roleForm.patchValue({ group_id: e.target.value });
  }

  get ufc() {
    return this.roleForm.controls;
  }

  saveRole() {
    this.loading = true;

    if (this.roleForm.invalid) {
      this.toastr.error('Faltan datos por completar.');
      this.loading = false;
      this.roleForm.markAllAsTouched();
      return;
    }

    const payload: any = {
      name: this.roleForm.value.name,
      group_id: this.roleForm.value.group_id,
      permissions: JSON.stringify(this.permissions)
    };
    

    this.roleData.emit(payload);
  }

}
