import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EscolarService } from 'src/app/services/escolar.service';
import { Notice } from 'src/app/models/notice.model';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ToastrService } from 'ngx-toastr';
import { School } from 'src/app/models/school.model';
import { Campus } from 'src/app/models/campus.model';

@Component({
  selector: 'app-edit-notices-bulletin',
  template: `
    <div class="bg-white p-4 rounded-4 shadow-sm">
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo.url}}" width="18" height="18" class="ms-2" *ngIf="school.logo">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="alert alert-warning alert-dismissible fade show rounded-4 overflow-hidden" role="alert" *ngIf="notice&&notice.expired_at">
        <fa-icon [icon]="icons.faCircleInfo" class="me-2"></fa-icon>
        <strong>Este aviso expiró</strong> el , ya no es visible para los usuarios a los que se les compartió su contenido.            
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <app-form-notice 
        [notice]="notice" 
        [school]="school"
        [campuses]="campuses"
        [loading]="loading"         
        (noticeData)="updateNotice($event)"
      ></app-form-notice>
    </div>
  `,
  styleUrls: []
})
export class EditNoticesBulletinComponent implements OnInit {
  notice: Notice | null = null;
  school: School;
  campuses: Campus[] = [];
  loading: boolean = false;

  constructor(
    private escolarService: EscolarService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = JSON.parse(localStorage.getItem('campuses') || '[]');
    this.route.params.subscribe((params) => {
      this.loading = true;
      this.escolarService.getNotice(params['id']).subscribe((notice: any) => {
        this.notice = notice;
        this.loading = false;
      });
    });
  }

  ngOnInit(): void {}

  updateNotice(data: any) {
    this.escolarService.updateNotice(data.notice_id, data).subscribe({
      next: (notice: any) => {
        this.toastr.success('Aviso actualizado', 'Éxito');
        // this.router.navigate(['/notices/bulletins']);
      },
      error: (error: any) => {
        this.toastr.error('Error al crear el aviso', 'Error');
      }
    });
  }
}
