import { TasksService } from 'src/app/services/tasks.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import { environment } from 'src/environments/environment';
import esLocale from '@fullcalendar/core/locales/es';
import { DateService } from 'src/app/shared/services/date.service';
import { TextService } from 'src/app/shared/services/text.service';
import { ToastrService } from 'ngx-toastr';
import { IconsService } from 'src/app/shared/services/icons.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { EscolarService } from 'src/app/services/escolar.service';
import { User } from 'src/app/models/user';
import { School } from 'src/app/models/school.model';
import { Campus } from 'src/app/models/campus.model';

@Component({
  selector: 'app-dashboard',
  template: `
    <div class="bg-white p-4 rounded-4 shadow" *ngIf="permissions?.modules.admin.dashboard.active">
      <div class="d-flex flex-wrap mb-4">
        <div class="flex-fill">
          <div class="d-flex flex-fill mb-4 mb-ms-0">
            <div class="ms-auto">
              <div class="avatar-container text-center" style="left: 0;">
                <div class="image-container border border-4" style="width: 110px; height: 110px;">
                  <img [src]="profileLogo" alt="logo" class="img-fluid" style="width: 80%; padding-top: 10px;">
                </div>
              </div>
            </div>
            <div class="flex-fill ms-4">
              <h5 class="mb-1 mt-2">COLEGIO</h5>
              <h1 class="mb-0" style="font-size: 2em !important; line-height: 0.8;">{{school?.name}}</h1>
              <p class="mb-0" style="font-size: 1.6em !important;">{{school?.description}}</p>
            </div>
          </div>
          <!-- div class="d-flex flex-md-fill rounded-3 p-3 shadow mt-4 mb-4 mb-md-0" [ngStyle]="{ 'background-color': school?.color }">
            <div class="flex-fill text-center">
              <h1 [countUp]="1" [duration]="500" style="font-size: 50px;" class="mb-0"></h1>
              <p>Grupos</p>
            </div>
            <div class="flex-fill text-center">
              <h1 [countUp]="1" [duration]="500" style="font-size: 50px;" class="mb-0"></h1>
              <p>Alumnos</p>
            </div>
            <div class="flex-fill text-center">
              <h1 [countUp]="1" [duration]="500" style="font-size: 50px;" class="mb-0"></h1>
              <p>Mensajes</p>
            </div>
          </!-->
          <full-calendar [options]="calendarOptions"></full-calendar>
        </div>
        <div class="flex-md-fill flex-sm-column ms-4" *ngIf="calendarOptions">
          <app-live-feed 
            [school]="school"
            [user_id]="user?.id"
          ></app-live-feed>
        </div>
      </div>
    </div>
    
    <div class="row" *ngIf="!permissions?.modules.admin.dashboard.active">
      <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-3">
        <h1 class="text-center mt-5">Escalas</h1>
      </div>
    </div>
    <!--- app-calendar-event
      [top]="top"
      [left]="left"
      [isVisible]="isVisible"
      [selectedTask]="selectedTask"
      [taskCategories]="taskCategories"
      [priority]="priority"
      [status]="status"
      (isVisibleChange)="toggleVisibility($event)"
    ></!--->
  `,
  styleUrls: []
})
export class DashboardComponent implements OnInit {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  escalasURL: string = environment.ESCALAS_WSPREFIX;
  token: string = '';
  user: User | null = null;

  school: School | null = null;
  campuses: Campus[] = [];
  permissions: any = {};
  
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, timeGridPlugin],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay' // user can switch between the two
    },
    locale: esLocale,
    selectable: true,
    nowIndicator: true,
    displayEventTime: true,
    eventDisplay: 'block',
    eventTimeFormat: { // like '14:30:00'
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
    businessHours: {
      daysOfWeek: [ 1, 2, 3, 4, 5, 6 ], // Monday - Thursday
      startTime: '9:00', // a start time (10am in this example)
      endTime: '18:00', // an end time (6pm in this example)
    },
    eventClick: this.handleDateClick.bind(this),
    datesSet: this.handleDateChange.bind(this)
  };

  top: number = 0; // Default top position
  left: number = 0; // Default left position
  isVisible: boolean = false; // Default visibility

  cardColor: string = '#232837';

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  selectedTask: any = {};
  profileLogo: string = '';
  
  payload: any = {
    per_page: 1000,
    page: 1,
    profile_id: 0,
    year: 2024,
    month: 5,
  }

  priority: any = [
    { id: 1, name: 'Baja', color: '#219C90' },
    { id: 2, name: 'Media', color: '#E9B824' },
    { id: 3, name: 'Alta', color: '#EE9322' },
    { id: 4, name: 'Urgente', color: '#D83F31' },
  ];

  status: any = [
    { id: 1, name: 'Pendiente', color: '#3C4F76', textColor: '#fff'},
    { id: 2, name: 'En proceso', color: '#E9B824', textColor: '#000'},
    { id: 3, name: 'Completada', color: '#D1BEB0', textColor: '#000'},
    { id: 4, name: 'Cancelada', color: '#EE9322', textColor: '#fff'},
  ];

  a = new Date();
  fromDate: any; // = "2023/12/01"; // Date = new Date();
  toDate: any; // = "2024/02/02"; // Date = new Date();

  // SUMMARY DATA

  fullTasks: any[] = [];
  unasigned_tasks: any[] = [];
  taskCategories: any[] = [];

  constructor(
    public authService: AuthService,
    private escolarService: EscolarService,
    private tasksService: TasksService,
    private dateService: DateService,
    private textService: TextService,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.token = '';
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = localStorage.getItem('campuses') != null ? JSON.parse(localStorage.getItem('campuses')!) : [];
    this.user = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')!) : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.school!.school_id;
    this.payload.year = this.a.getFullYear();
    this.payload.month = [1,2,3,4,5]

    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);

    const url: string = 'https://api.escalas.io/';
    this.profileLogo = `${url}${this.school?.logo!.url}`;
  }

  ngOnInit(): void {}

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;
  }

  handleDateClick(arg: any) {
    this.top = arg.jsEvent.pageY;
    this.left = arg.jsEvent.pageX;
    this.isVisible = true;
    this.selectedTask = this.fullTasks.find((task: any) => task.id === Number(arg.event.id));  
  }

  handleDateChange(arg: any) {
    this.payload.due_date_since = arg.startStr.split('T')[0];
    this.payload.due_date_to = arg.endStr.split('T')[0];
    // this.loadTasks(this.payload);
  }

  /*loadTasks(payload: any) {
    payload.profile_id = 1;
    this.tasksService.getTasks(payload).subscribe({
      next: async (resp: any) => {
        let tasks: any[] = [];
        this.fullTasks = resp.data;
        await resp.data.map((task: any) => {
          if (task.due_date == null) {
            this.unasigned_tasks.push(task);
            return;
          }
          let t: any = {
            id: task.id,
            title: this.textService.truncateTextAtWord(this.taskCategories.find((c: any) => c.id === task.builder_id).name, 15),
            start: `${task.due_date.split('.')[0]}`,
            end: `${this.dateService.addMinutesToDate(task.due_date, task.duration).split('.')[0]}`,
            display: 'block',
            startEditable: true
          };

          tasks.push(t);
        });
        //
        this.calendarOptions.events = tasks;
      },
      error: (err: any) => {
        this.toastr.error('No se ha podido cargar las tareas.', 'Error');
      }
    });
  }*/

  toggleVisibility(event: any) {
    this.isVisible = event;
  }
}
