import { Student } from './../models/student.model';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Section } from '../models/section.model';
import { Tutor } from '../models/tutor.model';
import { Professor } from '../models/professor.model';
import { Campus } from '../models/campus.model';
import { Event } from '../models/event.model';
import { Image } from '../models/image';
import { Notice } from '../models/notice.model';

@Injectable({
  providedIn: 'root'
})
export class EscolarService extends CommonService {
  token: string;
  refresh_token: string;

  constructor(
    private httpClient: HttpClient
  ) { 
    super(httpClient);
    this.token = localStorage.getItem('token') != null ? `${localStorage.getItem('token')}` : '';
    this.refresh_token = localStorage.getItem('refresh_token') != null ? `${localStorage.getItem('refresh_token')}` : '';
  }

  // CRUD Schools

  getSchools(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/schools?${this.parseQueryParams(data)}`, options);
  }

  getSchool(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/schools/${id}`, options);
  }

  updateSchool(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/schools/${id}`, data, options);
  }

  createSchool(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/schools`, data, options);
  }

  deleteSchool(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/schools/${id}`, options);
  }

  // CRUD Campuses

  getCampuses(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/campuses?${this.parseQueryParams(data)}`, options)
    .pipe(map((data: any) => {
      const campus: Campus[] = [];
      data.data.map((campux: any) => {
        let sections: Section[] = [];
        campux.attributes.sections.map((section: any) => {
          sections.push({
            section_id: section.section_id,
            section: section.section,
            grade: section.grade,
            group: section.group,
            color: section.color,
            icon: section.icon,
          });
        });

        const campusData: Campus = {
          campus_id: campux.id,
          name: campux.attributes.name,
          description: campux.attributes.description,
          school_id: campux.attributes.school_id,
          sections: sections,
        }
        campus.push(campusData);
      });
      return { data: campus, meta: data.meta };
    }));
  }

  createCampus(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/campuses`, data, options);
  }

  updateCampus(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/campuses/${id}`, data, options);
  }

  deleteCampus(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/campuses/${id}`, options);
  }

  // CRUD Families

  getFamilies(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/families?${this.parseQueryParams(data)}`, options);
  }

  getFamily(id: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/families/${id}`, options);
  }

  createFamily(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/families`, data, options);
  }

  updateFamily(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/families/${id}`, data, options);
  }

  deleteFamily(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/families/${id}`, options);
  }

  // CRUD Sections

  getSections(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/sections?${this.parseQueryParams(data)}`, options);
  }

  createSection(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/sections`, data, options);
  }

  updateSection(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/sections/${id}`, data, options);
  }

  deleteSection(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/sections/${id}`, options);
  }

  getGrades(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/sections/grades?${this.parseQueryParams(data)}`, options);
  }

  validateSection(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/sections/validate/${id}`, options);
  }

  // CRUD Students

  getStudents(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/students?${this.parseQueryParams(data)}`, options)
    .pipe(map((data: any) => {
      const students: Student[] = [];
      data.data.map((student: any) => {
        let sections: Section[] = [];
        student.attributes.sections.map((section: any) => {
          sections.push({
            section_id: section.section_id,
            section: section.section_name,            
            grade: section.grade,
            group: section.group,
            color: section.color,
            icon: section.icon,
          });
        });

        const studentData: Student = {
          student_id: student.id,
          name: student.attributes.name,
          p_last_name: student.attributes['p-last-name'],
          m_last_name: student.attributes['m-last-name'],
          sections: sections,
          family: student.attributes.family,
          campus: student.attributes.campus
        }
        students.push(studentData);
      });
      const meta: any = {
        per_page: data.meta.params['per-page'],
        total_entries: data.meta.pagination['total-entries'],
        current_page: data.meta.pagination['current-page'],
      };
      return { data: students, meta: meta };
    }));
  }

  getStudent(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/students/${id}`, options)
    .pipe(map((data: any) => {
      let sections: Section[] = [];
      data.data.attributes.sections.map((section: any) => {
        sections.push({
          section_id: section.section_id,
          section: section.section_name,            
          grade: section.grade,
          group: section.group,
          color: section.color,
          icon: section.icon,
        });
      });

      const studentData: Student = {
        student_id: data.data.id,
        name: data.data.attributes.name,
        p_last_name: data.data.attributes['p-last-name'],
        m_last_name: data.data.attributes['m-last-name'],
        sections: sections,
        family: data.data.attributes.family,
        campus: data.data.attributes.campus
      }

      return studentData;
    }));
  }

  createStudent(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/students`, data, options);
  }

  updateStudent(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/students/${id}`, data, options);
  }

  deleteStudent(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/students/${id}`, options);
  }

  // CRUD Professors

  getProfessors(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/professors?${this.parseQueryParams(data)}`, options)
    .pipe(map((data: any) => {
      const professors: Professor[] = [];
      data.data.map((professor: any) => {
        let sections: Section[] = [];
        professor.attributes.groups.map((section: any) => {
          sections.push({
            section_id: section.section_id,
            section: section.section_name,            
            grade: section.grade,
            group: section.group,
            color: section.color,
            icon: section.icon,
          });
        });

        const professorData: Professor = {
          professor_id: professor.id,
          name: professor.attributes.name,
          p_last_name: professor.attributes['p-last-name'],
          m_last_name: professor.attributes['m-last-name'],
          sections: sections,
          user: professor.attributes.user,
          campus: professor.attributes.campus
        }
        professors.push(professorData);
      });
      const meta: any = {
        per_page: data.meta.params['per-page'],
        total_entries: data.meta.pagination['total-entries'],
        current_page: data.meta.pagination['current-page'],
      };
      return { data: professors, meta: meta };
    }));
  }

  getProfessor(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/professors/${id}`, options)
    .pipe(map((data: any) => {
      let sections: Section[] = [];
      data.data.attributes.groups.map((section: any) => {
        sections.push({
          section_id: section.section_id,
          section: section.section_name,            
          grade: section.grade,
          group: section.group,
          color: section.color,
          icon: section.icon,
        });
      });

      const professorData: Professor = {
        professor_id: data.data.id,
        name: data.data.attributes.name,
        p_last_name: data.data.attributes['p-last-name'],
        m_last_name: data.data.attributes['m-last-name'],
        sections: sections,
        user: data.data.attributes.user,
        campus: data.data.attributes.campus
      }

      return professorData;
    }));
  }

  createProfessor(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/professors`, data, options);
  }

  updateProfessor(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/professors/${id}`, data, options);
  }

  deleteProfessor(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/professors/${id}`, options);
  }

  // Notices CRUD

  getNotices(data: any): Observable<any> {
    const options = this.getHeaders(this.token, this.refresh_token);
    return this.httpClient.get(`${this.escolarURL}/notices?${this.parseQueryParams(data)}`, options);
  }

  getNotice(id: number): Observable<any> {
    const options = this.getHeaders(this.token, this.refresh_token);
    return this.httpClient.get(`${this.escolarURL}/notices/${id}`, options)
    .pipe(map((data: any) => {
      let images: Image[] = [];
      data.data.attributes.images.map((image: any) => {
        images.push({
          id: image.id,
          url: image.url,
          content_type: image['content-type'],
          filename: image['filename'],
        });
      });

      const notice: Notice = {
        notice_id: data?.data?.id,
        title: data?.data?.attributes.title,
        description: data?.data?.attributes.description,
        campus_id: data?.data?.attributes['campus_id'],
        notify: data?.data?.attributes.notify,
        live: data?.data?.attributes.live,
        images: images,
        generic_type: data?.data?.attributes['generic-type'],
        generic_id: data?.data?.attributes['generic-id'],
        school: data?.data?.attributes.school,
        campus: data?.data?.attributes.campus,
      }

      console.log('notice ====> ', notice);
      return notice;
    }));
  }

  createNotice(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/notices`, { notice: data }, options)
    .pipe(map((data: any) => {
      const notice = {
        id: data.data.id,
        title: data.data.attributes.title,
        description: data.data.attributes.description,
        images: data.data.attributes.images,
        generic_type: data.data.attributes['generic-type'],
        generic_id: data.data.attributes['generic-id'],
        school: data.data.attributes.school,
        campus: data.data.attributes.campus,
      }
      return notice;
    }));
  }

  updateNotice(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/notices/${id}`, { notice: data }, options)
    .pipe(map((data: any) => {
      const notice = {
        id: data.data.id,
        title: data.data.attributes.title,
        description: data.data.attributes.description,
        images: data.data.attributes.images,
        generic_type: data.data.attributes['generic-type'],
        generic_id: data.data.attributes['generic-id'],
        school: data.data.attributes.school,
        campus: data.data.attributes.campus,
      }
      return notice;
    }));
  }

  deleteNotice(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/notices/${id}`, options);
  }

  deleteImageNotice(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/notices/${id}/delete_image/${data}`, options)
    .pipe(map((data: any) => {
      const notice = {
        id: data.data.id,
        title: data.data.attributes.title,
        description: data.data.attributes.description,
        images: data.data.attributes.images,
        generic_type: data.data.attributes['generic-type'],
        generic_id: data.data.attributes['generic-id'],
        school: data.data.attributes.school,
        campus: data.data.attributes.campus,
      }
      return notice;
    }));
  };

  getTutorFeed(id: any, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/notices/feed/${id}?${this.parseQueryParams(data)}`, options);
  }

  // CRUD Tutors

  getTutors(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/tutors?${this.parseQueryParams(data)}`, options)
    .pipe(map((data: any) => {
      const tutors: Tutor[] = [];
      data.data.map((tutor: any) => {
        const tutorData: Tutor = {
          tutor_id: tutor.id,
          name: tutor.attributes.name,
          last_name: tutor.attributes['last-name'],
          family: tutor.attributes.family,
          user: tutor.attributes.user,
        }
        tutors.push(tutorData);
      });
      const meta: any = {
        per_page: data.meta.params['per-page'],
        total_entries: data.meta.pagination['total-entries'],
        current_page: data.meta.pagination['current-page'],
      };
      return { data: tutors, meta: meta };
    }));
  }

  getTutor(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/tutors/${id}`, options)
    .pipe(map((data: any) => {
      const tutor: Tutor = {
        tutor_id: data.data.id,
        name: data.data.attributes.name,
        last_name: data.data.attributes['last-name'],
        family: data.data.attributes.family,
        user: data.data.attributes.user,
      }
      return tutor;
    }))
  }

  createTutor(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/tutors`, data, options)
    .pipe(map((data: any) => {
      const tutor: Tutor = {
        tutor_id: data.data.id,
        name: data.data.attributes.name,
        last_name: data.data.attributes['last-name'],
        family: data.data.attributes.family,
        user: data.data.attributes.user,
      }
      return tutor;
    }))
  }

  updateTutor(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/tutors/${id}`, data, options)
    .pipe(map((data: any) => {
      const tutor: Tutor = {
        tutor_id: data.data.id,
        name: data.data.attributes.name,
        last_name: data.data.attributes['last-name'],
        family: data.data.attributes.family,
        user: data.data.attributes.user,
      }
      return tutor;
    }))
  }

  deleteTutor(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/tutors/${id}`, options);
  }

  // CRUD Users

  getUsers(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/users?${this.parseQueryParams(data)}`, options);
  }

  getUser(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/users/${id}`, options);
  }

  createUser(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/users`, data, options);
  }

  // SESSION

  login(data: any, token: string): Observable<any> {
    const options = this.getHeaders(token);
    let login = this.httpClient.post(`${this.escolarURL}/auth/login`, data, options);

    return login;
  }

  getRole(id: any, token: any): Observable<any> {
    const options = this.getHeaders(token);
    return this.httpClient.get(`${this.escolarURL}/roles/${id}`, options);
  }

  verifyUser(data: any, token: string): Observable<any> {
    const options = this.getHeaders(token);
    return this.httpClient.post(`${this.escolarURL}/auth/verify_user`, data, options);
  }

  // EVENTS

  getEvents(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/events?${this.parseQueryParams(data)}`, options)
    .pipe(map((data: any) => {
      const events: Event[] = [];
      console.log('events data', data);
      data.data.map((event: any) => {
        const eventData: Event = {
          id: event?.id,
          title: event?.attributes?.title,
          description: event?.attributes?.description,
          start_date: event?.attributes['start-date'],
          duration: event?.attributes?.duration,
          completed: event?.attributes?.completed,
          notify: event?.attributes?.notify,
          live: event?.attributes?.live,
          image: event?.attributes?.image,
          generic_type: event?.attributes['generic-type'],
          generic_id: event?.attributes['generic-id'],
          all_day: event?.attributes != undefined ? event?.attributes['all-day'] : false,
          school: event?.attributes?.school,
          campus: event?.attributes?.campus
        };

        const meta: any = {
          per_page: event.meta?.params['per-page'],
          total_entries: event.meta?.pagination['total-entries'],
          current_page: event.meta?.pagination['current-page'],
        };
        events.push(eventData);
      });

      return { data: events, meta: data.meta };
    }));
  }

  getEvent(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.get(`${this.escolarURL}/events/${id}`, options)
    .pipe(map((data: any) => {
      const event: Event = {
        id: data?.data?.id,
        title: data?.data?.attributes.title,
        description: data?.data?.attributes.description,
        start_date: data?.data?.attributes['start-date'],
        duration: data?.data?.attributes.duration,
        completed: data?.data?.attributes.completed,
        notify: data?.data?.attributes.notify,
        live: data?.data?.attributes.live,
        image: data?.data?.attributes.image,
        generic_type: data?.data?.attributes['generic-type'],
        generic_id: data?.data?.attributes['generic-id'],
        all_day: data?.data?.attributes['all-day'],
        school: data?.data?.attributes.school,
        campus: data?.data?.attributes.campus
      };

      return event;
    }));
  }

  createEvent(data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.post(`${this.escolarURL}/events`, data, options);
  }

  updateEvent(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/events/${id}`, data, options);
  }

  deleteEvent(id: number): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/events/${id}`, options);
  }

  updateEventImage(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.put(`${this.escolarURL}/events/${id}/update_image`, data, options);
  }

  deleteEventImage(id: number, data: any): Observable<any> {
    const options = this.getHeaders(this.token);
    return this.httpClient.delete(`${this.escolarURL}/events/${id}/delete_image/${data}`, options);
  }
}
