import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { DateService } from 'src/app/shared/services/date.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { IconsService } from 'src/app/shared/services/icons.service';
import { Campus } from 'src/app/models/campus.model';
import { School } from 'src/app/models/school.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { Event } from 'src/app/models/event.model';

@Component({
  selector: 'app-edit-events',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo!.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="d-flex mb-2">
        <div>
          <h1>Editar evento</h1>
          <p>Configura los datos del evento con los siguientes campos.</p>
        </div>
      </div>
      <div class="d-flex mb-4">
        <div class="flex-fill">
          <app-form-task 
            (taskData)="updateTask($event)" 
            [event]="event" 
            [campuses]="campuses"            
            [loading]="loading"
            [school]="school"
            [campuses]="campuses"
          ></app-form-task>
        </div>
        <div class="flex-grow-1 ms-3 d-none d-sm-none d-md-block">
          <full-calendar #calendar [options]="calendarOptions"></full-calendar>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class EditEventsComponent {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;
  loading: boolean = false;
  event: Event | null = null;
  selectedBuilder: any = null;
  tecnicos: any[] = [];
  totalTime: number = 0;
  totalWeight: number = 0;
  totalSkills: number = 0;
  totalTools: number = 0;
  profile: any = {};

  dateTasks: any[] = [];


  school: School | null = null;
  campuses: Campus[] = [];

  currentDay = new Date().toISOString().split('T')[0];

  payload: any = {
    page: 1,
    per_per: 100,
    role_id: 3,
  };

  calendarOptions: CalendarOptions = {    
    plugins: [dayGridPlugin, timeGridPlugin],
    locale: esLocale,
    selectable: true,
    nowIndicator: true,
    displayEventTime: true,
    eventDisplay: 'block',
    eventTimeFormat: { // like '14:30:00'
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
  };

  constructor(
    private taskService: TasksService,
    private escolarService: EscolarService,
    private router: Router,
    private toastr: ToastrService,
    private activateRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private dateService: DateService,
    public icons: IconsService
  ) {
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = localStorage.getItem('campuses') != null ? JSON.parse(localStorage.getItem('campuses')!) : [];
  }

  ngOnInit(): void {
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.escolarService.getEvent(params['id']).subscribe({
        next: (data: any) => {
          this.loading = false;
          this.event = data;

          console.log(' this.event', this.event);

          this.breadcrumbService.set('@index', 'Eventos');
          this.breadcrumbService.set('@tasks', 'Evento');
          this.breadcrumbService.set('@taskEdit', 'Editar evento');
        },
        error: (err: any) => {
          this.loading = false;
          this.toastr.error('Ocurrió un error al obtener el evento.', 'Error');
        }
      });
    });
  }

  updateTask(event: any) {
    this.escolarService.updateEvent(event.id, { event: event }).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se edito el evento correctamente.', 'Evento editado');
        this.router.navigateByUrl('/calendar');
      },
      error: (err: any) => {
        this.loading = false;
        console.error(err);
        this.toastr.error('No se ha podido crear el evento.', 'Error');
      }
    });
  }
}
