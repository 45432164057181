import { Component } from '@angular/core';

@Component({
  selector: 'app-index-notices-message',
  template: ``,
  styleUrls: []
})
export class IndexNoticesMessageComponent {

}
