import { Component } from '@angular/core';

@Component({
  selector: 'app-on-boarding',
  template: ``,
  styleUrls: []
})
export class OnBoardingComponent {

}
