import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, switchMap } from 'rxjs';
import { Campus } from 'src/app/models/campus.model';
import { Family } from 'src/app/models/family.model';
import { School } from 'src/app/models/school.model';
import { Student } from 'src/app/models/student.model';
import { Tutor } from 'src/app/models/tutor.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-form-tutor',
  template: `
    <form [formGroup]="tutorForm" (ngSubmit)="onSubmit()">
      <div class="form-group mt-2">
        <label for="name" class="form-label">Nombre</label>
        <input type="text" class="form-control border-secondary" id="name" formControlName="name">
        <div *ngIf="ufc['name']?.touched && ufc['name']?.invalid">
          <div *ngIf="ufc['name']?.errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-2">
        <label for="last_name" class="form-label">Apellidos</label>
        <input type="text" class="form-control border-secondary" id="last_name" formControlName="last_name">
        <div *ngIf="ufc['last_name'].touched && ufc['last_name'].invalid">
          <div *ngIf="ufc['last_name'].errors?.['required']" class="text-danger text-sm">Los apellidos son requeridos.</div>
        </div>
      </div>
      <div class="form-group mt-4">
        <fieldset class="rounded-2 py-3 px-4 bg-light" style="border: 1px solid rbga(0,0,0,0.5);">
          <div class="list-group border-0 bg-transparent">
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <div>
                <h4 style="font-size: 1em; font-weight: 500;">Configuración del usuario</h4>
                <p class="text-sm text-muted mb-0" style="font-size: 13px; font-weight: 300;">Estos datos representan la información del usuario en la aplicación.</p>
              </div>
            </div>
            <div *ngIf="tutor?.user" class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <strong>Notificaciones activas</strong>
              <div class="form-check form-switch mt-3 ps-0">
                <input class="form-check-input form-check-input-lg float-end" type="checkbox" formControlName="notify">
                <label class="form-check-label" for="flexCheckDefault"></label>
              </div>
            </div>
            <div *ngIf="tutor?.user" class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <strong>Activo</strong>
              <div class="form-check form-switch mt-3 ps-0">
                <input class="form-check-input form-check-input-lg float-end" type="checkbox" formControlName="is_active">
                <label class="form-check-label" for="flexCheckDefault"></label>
              </div>
            </div>
            <div class="bg-transparent border-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <div>
                <p><strong>Teléfono</strong></p>
                <div *ngIf="ufc['last_name'].touched && ufc['last_name'].invalid">
                  <div *ngIf="ufc['last_name'].errors?.['required']" class="text-danger text-sm">Los apellidos son requeridos.</div>
                </div>
              </div>
              <div class="flex-shrink-1">
                <input type="text" class="form-control bg-transparent" style="border: 1px solid rbga(0,0,0,0.4);" id="phone_number" formControlName="phone_number">
              </div>
            </div>
          </div>
        </fieldset>        
      </div>
      <div class="form-group mt-4">
        <div class="">
          <div class="d-flex">
            <div class="flex-fill mt-2">
              <h5 class="mb-0">Familia</h5>
            </div>
            <div class="ms-auto text-end" *ngIf="tutor?.family == null">
              <div class="form-group d-flex">
                <div>
                  <input id="typeahead-basic" type="text" class="form-control" [formControl]="searchControl" />
                  <ul class="list-unstyled p-1 text-start bg-light shadow-sm" style="positon: absolute; width: 280px;">
                    <li class="" *ngFor="let student of students" (click)="selectStudent(student)">{{student?.name}} {{student?.p_last_name}} {{student?.m_last_name}}</li>
                  </ul>
                </div>
                <div>
                  <button class="btn btn-outline-primary ms-2" >
                    <fa-icon [icon]="icons.faSearch" ></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>          
          <table class="table mt-3">
            <thead>
              <tr>
                <th class="bg-light text-secondary rounded-top-left-2">Nombre</th>
                <th class="bg-light text-secondary text-center">Campus</th>
                <th class="bg-light text-secondary text-center">Grado</th>
                <th class="bg-light text-secondary rounded-top-right-2"></th>
              </tr>
            </thead>
            <tbody *ngIf="tutor?.family">
              <tr *ngFor="let student of students">
                <td class="align-middle">{{student.name}} {{student.p_last_name}} {{student.m_last_name}}</td>
                <td class="align-middle text-center">{{student?.campus?.name}} {{student?.campus?.description}}</td>
                <td class="align-middle text-center">
                  <div class="badge bg-light text-secondary me-2 mb-1 py-2" *ngFor="let section of student?.sections">
                    {{section.section}} {{section.grade}} {{section.group}}
                  </div>
                </td>
                <td class="align-middle text-end"><button class="btn btn-link btn-sm p-0" routerLink="/students/{{student.student_id}}">Ver</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- div class="alert alert-info mt-3">
        <p class="mb-0 text-sm">Al crear un usuario para el padre de familia podría ingresar a la aplicación utilizando únicamente su número de celular.</p>
      </!-->
      <div class="form-group text-end mt-5">
        <hr style="border-color: rbga(0,0,0,0.4);">
        <div class="d-flex flex-wrap">
          <div class="ms-auto">
            <button *ngIf="tutor?.tutor_id" class="btn btn-outline-danger" (click)="deleteTutor()">
              <fa-icon [icon]="icons.faTrashAlt" class="me-0 me-md-2"></fa-icon>
              <span class="d-none d-sm-none d-md-inline-block">Eliminar</span>
            </button>
          </div>
          <div class="flex-fill">
            <button class="btn btn-link text-dark me-2 me-sm-3" routerLink="/tutors">Cancelar</button>
            <button type="submit" class="btn btn-escalas">
              <fa-icon [icon]="icons.faSave" class="me-0 me-md-2"></fa-icon>
              <span class="d-none d-sm-none d-md-inline-block">Guardar</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormTutorComponent {
  @Input() tutor: Tutor | null = null;
  @Input() school: School | undefined;
  @Input() family: any; // Family | undefined;
  @Input() students: Student[] = [];
  @Input() campuses: Campus[] = [];

  @Output() tutorData = new EventEmitter<any>();

  searchControl: FormControl = new FormControl();

  user: any;
  tutorForm: FormGroup = new FormGroup({});

  studentModel: any;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: EscolarService,
    public icons: IconsService,
    private router: Router
  ) {
    this.initForm();
  }

  get ufc() {
    return this.tutorForm.controls;
  }

  initForm() {
    this.tutorForm = this.fb.group({
      tutor_id: [''],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      family_id: [''],
      is_active: [false], // USER
      notify: [false], // USER
      phone_number: [''], // USER
      school_id: [''], // USER
      role_id: 2, // USER
    });

    this.searchControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => this.store.getStudents({
        name: term, 
        campus_id: this.campuses.map((item: any) => item.campus_id)
      }))).subscribe((results: any) => {
        this.students = results.data;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tutor'] && changes['tutor'].currentValue) {
      this.tutorForm.patchValue(changes['tutor'].currentValue);
      if (changes['tutor'].currentValue['user']) {
        // this.loadUser(changes['tutor'].currentValue['user']);
        this.tutorForm.patchValue({
          phone_number: changes['tutor'].currentValue['user'].phone_number,
          is_active: changes['tutor'].currentValue['user'].is_active,
          notify: changes['tutor'].currentValue['user'].notify,
          school_id: changes['tutor'].currentValue['user'].school_id,
          family_id: changes['tutor'].currentValue['family'].family_id
        });
      }
    }
  }

  selectStudent(student: any) {
    this.studentModel = student;
    this.tutorForm.patchValue({ family_id: student.family.family_id, school_id: student.campus.school_id });
    this.students = [];
  }

  onSubmit() {
    if (this.tutorForm.valid) {
      this.tutorData.emit(this.tutorForm.value);  
    } else {
      this.toastr.error('Faltan campos por llenar', 'Error');
      this.tutorForm.markAllAsTouched();
      return;
    }

  }

  deleteTutor() {
    const confirmation = confirm('¿Está seguro de eliminar este padre?');

    if (!confirmation) {
      return;
    } else {
      this.store.deleteTutor(this.tutor!.tutor_id).subscribe((response: any) => {
        this.toastr.success('Padre eliminado correctamente', 'Éxito');
        this.router.navigate(['/tutors']);
      });
    }
  }

}
