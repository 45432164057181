import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounce, debounceTime, distinctUntilChanged, map, Observable, OperatorFunction } from 'rxjs';
import { Campus } from 'src/app/models/campus.model';
import { Family } from 'src/app/models/family.model';
import { School } from 'src/app/models/school.model';
import { Student } from 'src/app/models/student.model';
import { Tutor } from 'src/app/models/tutor.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-create-tutors',
  template: `
    <div class="bg-white p-4 rounded-4 shadow mb-2">
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo?.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="d-flex">
        <div class="flex-fill">
          <div class="flex-column">
            <h4>Perfil del Padre de familia</h4>
            <p>Configura la información del profesor para que pueda ver la información personalizada.</p>
          </div>
          <div class="flex-column">
            <div class="d-flex flex-column">
              <app-form-tutor [tutor]="tutor" [school]="school!" [campuses]="campuses" (tutorData)="createTutor($event)"></app-form-tutor>
              <div class="mt-5" *ngIf="tutor?.family">
                <h5 class="mb-0">Hijo(s) / Alumno(s)</h5>
                <table class="table mt-3">
                  <thead>
                    <tr>
                      <th class="bg-light text-secondary rounded-top-left-2">Nombre</th>
                      <th class="bg-light text-secondary text-center">Campus</th>
                      <th class="bg-light text-secondary text-center">Grado</th>
                      <th class="bg-light text-secondary rounded-top-right-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let student of students">
                      <td class="align-middle">{{student.name}} {{student.p_last_name}} {{student.m_last_name}}</td>
                      <td class="align-middle text-center">{{student?.campus?.name}} {{student?.campus?.description}}</td>
                      <td class="align-middle text-center">
                        <div class="badge bg-light text-secondary me-2 mb-1 py-2" *ngFor="let section of student?.sections">
                          {{section.section}} {{section.grade}} {{section.group}}
                        </div>
                      </td>
                      <td class="align-middle text-end"><button class="btn btn-link btn-sm p-0" routerLink="/students/{{student.student_id}}">Ver</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <app-live-feed [school]="school" [user_id]="tutor?.user?.id"></app-live-feed>
          <div class="flex-column text-center">
            <p>Contenido que ve el padre de familia</p>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class CreateTutorsComponent implements OnInit {
  tutor: Tutor | null = null;
  school: School | null = null;
  students: Student[] = [];
  campuses: Campus[] = [];
  families: Family[] = [];
  permissions: any = {};
  prefs: any = {};

  selectedFamily: any = null;
  model: any;

  payload: any = {
    page: 1,
    limit: 10
  }

  filterFamilies: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => {
        if (term === '') {
          return [];
        }
        return this.families.filter((family: any) => {
          return family.name.toLowerCase().indexOf(term.toLowerCase()) > -1
        }).map((family: any) => family.name);
      })
    );

  constructor(
    private store: EscolarService,
    public icons: IconsService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
    this.school = JSON.parse(localStorage.getItem('school') || '{}');
    this.prefs = JSON.parse(localStorage.getItem('preferences') || '{}');
    this.campuses = JSON.parse(localStorage.getItem('campuses') || '[]');
    this.payload.school_id = this.school?.school_id;
    this.payload.campus_id = this.campuses.map((campus: any) => campus.id);
  }

  ngOnInit(): void {
    this.getFamilies();
  }

  getFamilies() {
    this.store.getFamilies(this.payload).subscribe((response: any) => {
      this.families = response.data;
    });
  }

  selectFam(event: any) {
    this.selectedFamily = this.families.find((family: any) => family.name == event.target.value);
    this.store.getStudents({ family_id: this.selectedFamily.family_id }).subscribe((students: any) => {
      this.students = students.data;
    });
  }

  createTutor(event: any) {    
    this.store.createTutor({ tutor: event }).subscribe({
      next: (data: any) => {
        this.toastr.success('Tutor creado correctamente', 'Tutor creado');
        this.router.navigate([`/tutors/${data.data.id}`]);     
      }, error: (err: any) => {
        this.toastr.error(`Error al crear el tutor: ${err.message}`, `Error ${err.status} ${err.statusText}`, { tapToDismiss: true });
      }
    });
  }

}