import { Component, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import { ToastrService } from 'ngx-toastr';
import { DateService } from 'src/app/shared/services/date.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { TextService } from 'src/app/shared/services/text.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
//
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import { EscolarService } from 'src/app/services/escolar.service';

@Component({
  selector: 'app-index-events',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-2 mb-md-4">
        <img src="https://api.escalas.io{{school.logo!.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="d-flex mb-2">
        <div class="">
          <h1 class="mb-0">Calendario</h1>
          <p>Configura el calendario de tu escuela, agregando las actividades en el calendario.</p>
        </div>
        <div class="text-end ms-3 flex-fill">
          <button class="btn btn-escalas" *ngIf="permissions?.modules.admin.calendar.write" routerLink="/calendar/create">
            <fa-icon [icon]="icons.faPlus" class="me-0 me-md-2"></fa-icon>
            <span class="d-none d-sm-none d-md-inline-block">Agregar evento</span>
          </button>
        </div>
      </div>
      <div class="d-flex mb-4">
        <div class="flex-fill">
          <full-calendar [options]="calendarOptions"></full-calendar>
        </div>
      </div>
    </div>
    
    <app-calendar-event
      [top]="top"
      [left]="left"
      [isVisible]="isVisible"
      [selectedTask]="selectedTask"
      [taskCategories]="taskCategories"
      [priority]="catalog.priority"
      [status]="catalog.status"
      (isVisibleChange)="toggleVisibility($event)"
    ></app-calendar-event>
  `,
  styleUrls: []
})
export class IndexEventsComponent {
  profile: any = {};
  permissions: any = {};

  top: number = 0; // Default top position
  left: number = 0; // Default left position
  isVisible: boolean = false; // Default visibility
  
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;
  calendarObj: any = {};
  calendarOptions: CalendarOptions = {
    themeSystem: 'bootstrap5',
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, timeGridPlugin], // interactionPlugin
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay' // user can switch between the two
    },
    locale: esLocale,
    selectable: true,
    nowIndicator: true,
    displayEventTime: true,
    eventDisplay: 'block',
    eventTimeFormat: { // like '14:30:00'
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
    businessHours: {
      daysOfWeek: [ 1, 2, 3, 4, 5, 6 ], // Monday - Thursday
      startTime: '9:00', // a start time (10am in this example)
      endTime: '18:00', // an end time (6pm in this example)
    },
    eventClick: this.handleDateClick.bind(this),
    datesSet: this.handleDateChange.bind(this)
  };

  loading: boolean = false;

  payload: any = {
    page: 1,
    per_page: 100,
  }

  fullTasks: any[] = [];
  taskCategories: any[] = [];

  searchActive: boolean = false;
  task: any = {};
  selectedTask: any = {};

  school: any = {};
  

  constructor(
    private escolarService: EscolarService,
    private toastr: ToastrService,
    private dateService: DateService,
    private textService: TextService,
    public catalog: CatalogsService,
    public icons: IconsService,
  ) {
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.payload.school_id = this.school.school_id;
  }

  ngOnInit(): void {
    const d = new Date();

    let periodos = this.dateService.getStartAndEndDateOfMonth(
      parseInt(d.getFullYear().toString()), parseInt(`${d.getMonth()+1}`));

    this.payload.due_date_since = periodos.start_date;
    this.payload.due_date_to = periodos.end_date;

    // this.breadcrumbService.set('@index', 'Servicio técnico');
  }
  

  handleDateClick(arg: any) {
    this.top = arg.jsEvent.pageY;
    this.left = arg.jsEvent.pageX - 300;
    this.isVisible = true;

    console.log('arg', arg.event._def.publicId);
    console.log('this.fullTasks =>', this.fullTasks);
    
    this.selectedTask = this.fullTasks.find((task: any) => task.id === arg.event._def.publicId);  
    console.log('selectedTask', this.selectedTask);
  }

  handleDateChange(arg: any) {
    this.payload.due_date_since = arg.startStr.split('T')[0];
    this.payload.due_date_to = arg.endStr.split('T')[0];
    this.loadTasks(this.payload);
  }

  loadTasks(payload: any) {
    this.escolarService.getEvents(payload).subscribe({
      next: async (resp: any) => {
        console.log('resp', resp);
        let tasks: any[] = [];
        this.fullTasks = resp.data;
        await resp.data.map((task: any) => {

          

          let t: any = {
            id: task.id,
            title: this.textService.truncateTextAtWord(task.title, 30), // this.textService.truncateTextAtWord(this.taskCategories.find((c: any) => c.id === task.builder_id).name, 15),
            start: `${task.start_date?.split(' ')[0]}`,
            // end: task.stat_date, // `${this.dateService.addMinutesToDate(task.stat_date, task?.duration ? task?.duration : 0 ).split('.')[0]}`,
            display: 'block',
            startEditable: true,
            allDay: true,
            backgroundColor: task?.live ? this.school?.color : '#e4e4e4',
            borderColor: task?.live ? this.school?.color : '#e4e4e4',
            textColor: task?.live ? this.school?.text_color : '#000'
          };

          tasks.push(t);
        });

        console.log('===========> tasks', tasks);

        this.calendarOptions.events = tasks;
      },
      error: (err: any) => {
        console.error(err);
        this.toastr.error('No se ha podido cargar los eventos.', 'Error');
      }
    });
  }

  selectBuilding(event: any) {

    this.payload.building_id = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  selectCategory(event: any) {
    this.payload.builder_id = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  selectTecnico(event: any) {
    this.payload.assignee_id = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  selectStatus(event: any) {
    this.payload.status = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  clearFilter() {
    this.payload = {
      page: 1,
      per_page: 1000,
      profile_id: this.profile.id
    }
    this.taskCategories = [];
    this.searchActive = false;
  }

  toggleVisibility(event: any) {
    this.isVisible = event;
  }
}
