import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EscolarService } from 'src/app/services/escolar.service';

@Component({
  selector: 'app-edit-families',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex">
        <div>
          <app-form-family [family]="family"></app-form-family>
        </div>
      </div>
      <div class="d-flex" *ngFor="let tutor of tutors">
        <app-form-tutor [tutor]="tutor"></app-form-tutor>
      </div>      
    </div>
  `,
  styleUrls: []
})
export class EditFamiliesComponent {
  
    family: any;
    tutors: any[] = [];
  
    constructor(
      private escolarService: EscolarService,
      private route: ActivatedRoute
    ) { 
      this.route.params.subscribe((params) => {
        this.escolarService.getFamily(params['id']).subscribe((family: any) => {
          this.family = family;
        });

        this.escolarService.getTutors({ family_id: params['id'] }).subscribe((tutors: any) => {
          this.tutors = tutors.data;
        });
      });
    }

}
