import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  permissions: any = null;
  role: any = null;

  constructor(
    public authService: AuthService,
    public router: Router
  ){ }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {      
      
    return this.checkAuth(state);
  }

  private checkAuth(state: RouterStateSnapshot): boolean {
    let allowed: boolean = false;
    let currentAuth = localStorage.getItem('token');
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.role = localStorage.getItem('role') != null ? JSON.parse(localStorage.getItem('role')!) : null;

    if (currentAuth == null || this.permissions == null) {
      this.router.navigate(['sign-in']);
      allowed = false;
    } else {
      let url = state.url.split('/').filter(Boolean);
      let dic = this.dict(url[0]);
      let seg: any = this.serializer(url[0]);
      let check: any = this.permissions.modules[dic!][seg!];
      if (check == undefined) {
        if (this.role.role.group_id == 3) {
          // this.router.navigate(['tenant/dashboard']);
        } else {
          this.router.navigate(['dashboard']);
        }
        allowed = false;
      } else {
        allowed = this.permissions.modules[dic!][seg!].active;
      }

    }
    return true; // allowed;
  }

  private dict(item: any) {
    let group = null;
    if (item == 'dashboard' || item == 'schools' || item == 'comms' || item == 'calendar' || item == 'notices' || item == 'notifications' || item == 'students' || item == 'profile' || item == 'roles' || item == 'tutors' || item == 'settings' || item == 'families' || item == 'professors') {
      group = 'admin';
    } else if (item == 'technicians' || item == 'users' || item == 'tenants' || item == 'perfiles' || item == 'roles') {
      group = 'config';
    } else if (item == 'maintenances' || item == 'amenidades' || item == 'providers' || item == 'services' || item == 'combustibles') {
      group = 'operation';
    } else if (item == 'tenant') {
      group = 'tenants';
    }
    return group;
  }

  private serializer(item: any) {
    let segment = null;
    if (item == 'dashboard') {
      segment = 'dashboard';
    }
    if (item == 'schools') {
      segment = 'schools';
    }
    if (item == 'comms') {
      segment = 'comms';
    }
    if (item == 'calendar') {
      segment = 'calendar';
    }
    if (item == 'notices') {
      segment = 'notices';
    }
    if (item == 'notifications') {
      segment = 'notifications';
    }
    if (item == 'users') {
      segment = 'profile';
    }
    if (item == 'roles') {
      segment = 'roles';
    }
    if (item == 'tutors') {
      segment = 'tutors';
    }
    if (item == 'students') {
      segment = 'students';
    }
    if (item == 'settings') {
      segment = 'settings';
    }
    if (item == 'families') {
      segment = 'families';
    }
    if (item == 'professors') {
      segment = 'professors';
    }
    
    return segment;
  }
  
}
