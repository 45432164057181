import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Component, Input, SimpleChanges } from '@angular/core';
import { Notice } from 'src/app/models/notice.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-live-feed',
  template: `
      <div class="d-flex rounded-4 d-none d-sm-block px-0 py-0 ms-5 me-3 mb-4" *ngIf="user_id" [ngStyle]="{ 'background-color': school ? school.color : '#fff'  }" style="position: relative; width: 314px !important; min-height: 680px !important;">
        <div 
          class="mobile-feed"
          *ngIf="!loading && notices.length>0"
          [ngStyle]="{ 'background-color': school ? school.color : '#fff'  }" 
          >
          <div class="d-flex border border-top-0 border-start-0 border-end-0 border-bottom-1 border-dark pt-0" >
            <div class="py-3 ps-2">
              <img src="https://api.escalas.io{{school.logo?.url}}" width="38px" class="ms-2" *ngIf="school">
            </div>
            <div class="mt-3 ms-3">
              <h4 class="mb-0" style="font-size: 14px;" [style.color]="school?.text_color ? school?.text_color+' !important' : 'black'">{{school?.name}}</h4>
              <p class="mb-0" style="font-size: 12px;" [style.color]="school?.text_color ? school?.text_color+' !important' : 'black'">{{school?.description}}</p>
            </div>
          </div>
          <div *ngFor="let notice of notices">
            <div class="d-flex">
              <div class="mx-3 mt-3">
                <div class="d-flex" *ngIf="notice.generic_type == 1">
                  <div class="bg-primary text-center rounded-2 me-1" style="width: 18px; height: 18px;">
                    <fa-icon [icon]="icons.faStar" class="text-white text-center" style="position: relative; top: -5px; font-size: 10px;"></fa-icon>
                  </div>
                  <div class="text-w500 text-sm ms-2">Escuela</div>
                </div>
                <div class="d-flex" *ngIf="notice.generic_type == 3">
                  <div class="bg-primary text-center rounded-3 float-start me-2" style="width: 28px; height: 28px; padding-top: 2px;">
                    <fa-icon [icon]="icons.faStar" class="text-white text-center"></fa-icon>
                  </div>
                  <div class="float-start text-w500 pt-1">Grado</div>
                </div>
                <div class="d-flex" *ngIf="notice.generic_type == 5">
                  <div class="bg-primary text-center rounded-3 float-start me-2" style="width: 28px; height: 28px; padding-top: 2px;">
                    <fa-icon [icon]="icons.faStar" class="text-white text-center"></fa-icon>
                  </div>
                  <div class="float-start text-w500 pt-1">Familia</div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-1 mx-3">
              <h3 class="text-dark">{{notice.title}}</h3>
            </div>
            <div class="d-flex" *ngIf="notice?.images!.length>0" style="max-width: 310px !important;">
              <ngb-carousel
                [showNavigationArrows]="false"
                [showNavigationIndicators]="true"
              >
                <ng-template ngbSlide *ngFor="let file of notice?.images">
                  <div class="picsum-img-wrapper">
                    
                    <img src="{{escalasURI}}{{file.url}}" alt="Random first slide" width="320px" />
                  </div>
                </ng-template>
              </ngb-carousel>
            </div>
            <div class="d-flex" style="max-width: 314px;" [ngClass]="notice?.images!.length>0 ? 'mt-4' : 'mt-0'">
              <p class="text-sm mx-3">{{notice.description}}</p>
            </div>
          </div>
        </div>
        <div class="mobile-feed text-center" style="height: 680px;" *ngIf="loading">
          <div class="d-flex flex-column align-items-center justify-content-center mt-5 pt-5">
            <div class="d-flex mt-5 pt-5">
              <fa-icon [icon]="icons.faCircleNotch" class="fa-spin fa-2x"></fa-icon>
            </div>
            <div class="d-flex">
              <p class="">Cargando...</p>
            </div>            
          </div>
        </div>
        <div class="mobile-feed text-center" style="height: 680px;" *ngIf="!loading&&notices?.length==0">
          <div class="d-flex align-items-center justify-content-center mt-5 pt-5">
            <p class="">No hay contenido</p>
          </div>
        </div>
        <div style="background: url('/assets/images/iphone-empty.png') no-repeat; background-size: cover; z-index: 9; background-size: 350px 714px; background-position: top left; width: 350px; height: 714px; left: -19px; top: -18px; position:absolute;"></div>

      </div>
  `,
  styleUrls: []
})
export class LiveFeedComponent {
  @Input() school: any;
  @Input() user_id: any;

  notices: Notice[] = [];
  loading: boolean = false;

  escalasURI: string = environment.ESCALAS_WSPREFIX;

  constructor(
    private escolarService: EscolarService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    if (this.user_id != undefined) {
      this.loadNotices(this.user_id);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user_id'] && changes['user_id'].currentValue != null) {
      this.loadNotices(changes['user_id'].currentValue);
    }
  }

  loadNotices(user_id: any) {
    this.loading = true;
    if (!this.user_id) {
      return;
    }
    this.escolarService.getTutorFeed(this.user_id, { page: 1, per_page: 10}).subscribe({
      next: (feed: any) => {
        this.notices = feed.data;
      }, complete: () => {
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
      }
    });
  }

}
