import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalSectionComponent } from 'src/app/components/modals/modal-section.component';
import { Campus } from 'src/app/models/campus.model';
import { School } from 'src/app/models/school.model';
import { User } from 'src/app/models/user';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { Section } from 'src/app/models/section.model';
import { ModalCampusComponent } from 'src/app/components/modals/modal-campus.component';

@Component({
  selector: 'app-edit-school',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-2 mb-md-4">
        <div class="d-flex flex-wrap flex-md-nowrap">
          <div class="flex-fill">
            <div class="flex-column">
              <h2 *ngIf="school">{{school.name}}</h2>              
              <p class="text-muted text-sm mb-4">Edite la información general de tu escuela.</p>
            </div>
            <div class="d-flex">
              <app-form-school [school]="school"></app-form-school>          
            </div>
          </div>
          <div class="flex-fill ms-0 ms-md-5 mt-3" style="min-width: 400px !important;" *ngIf="campuses&&campuses.length>0&&!loadingSections">
            <hr class="d-block d-sm-block d-md-none">
            <div class="p-0 p-md-4">
              <div class="d-flex mt-4" >
                <div class="flex-fill ms-3">
                  <h4 class="mb-0 mt-1"><strong>Campus</strong></h4>
                </div>
                <div class="text-end ms-auto me-3">
                  <button class="btn btn-outline-secondary" title="Agregar sección" (click)="openModalCampus(null)">
                    <fa-icon [icon]="icons.faPlus" class="me-0 me-md-2"></fa-icon>
                    Agregar
                  </button>
                </div>
              </div>
              <div class="d-flex mt-3" *ngFor="let campus of campuses">
                <div class="list-group w-100" >
                  <div class="list-group-item">
                    <div class="d-flex">
                      <div class="flex-fill">
                        <p class="mb-0"><strong>{{campus.name}}</strong></p>
                        <p class="text-muted mb-0">{{campus.description}}</p>
                      </div>
                      <div class="ms-auto text-end mt-1">
                        <button class="btn btn-escalas btn-sm" (click)="openModalCampus(campus)"><fa-icon [icon]="icons.faEdit" class="me-2"></fa-icon>Editar</button>
                      </div>
                      <button class="btn btn-link text-danger btn-sm ms-2 py-1" title="Eliminar campus" *ngIf="campus.sections?.length===0" (click)="deleteCampus(campus)">
                        <fa-icon [icon]="icons.faTrashAlt"></fa-icon>
                      </button>
                    </div>
                    <hr>
                    <div class="d-flex mt-3 mb-2">
                      <div class="flex-fill">
                        <p class="mb-0 mt-2 text-sm text-muted">Grupos</p>
                      </div>
                      <div class="ms-auto text-end">
                        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="addSection(campus)">
                          <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
                          Agregar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item py-3" *ngFor="let section of campus.sections">
                    <div class="d-flex">
                      <div>
                        <div class="d-flex badge-escalas rounded-3 align-items-center justify-content-center"
                          [style.background]="section.color ? section.color + ' !important' : 'gray'"
                        >
                          <fa-icon [icon]="icons.faStar"></fa-icon>
                        </div>
                      </div>
                      <div class="flex-fill ms-3 mt-1" style="font-weight: 500; font-size: 1em; ">{{section.section}} {{section.grade}} {{section.group}}</div>
                      <div class="ms-auto text-end">
                        <button class="btn btn-link btn-sm text-primary" (click)="openModalSection(campus, section)">
                          <fa-icon [icon]="icons.faEdit"></fa-icon>
                        </button>
                        <button class="btn btn-link btn-sm text-danger" (click)="deleteSection(section)">
                          <fa-icon [icon]="icons.faTrashAlt"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex ms-5 mt-3" style="min-width: 400px !important;" *ngIf="loadingSections">
            <ngx-skeleton-loader count="5" appearance="circle" />
          </div>
        </div>
      </div>
    </div>
    <app-modal-campus [campus]="selectedCampus" [school]="school" (updateDismiss)="saveCampus($event)"></app-modal-campus>
    <app-modal-section [section]="selectedSection" [campus]="selectedCampus" (updateDismiss)="saveSection($event)"></app-modal-section>
  `,
  styleUrls: []
})
export class EditSchoolComponent implements OnInit {
  user: User;
  school: School | null = null;
  campuses: Campus[] = [];
  selectedSection: Section | null = null;
  selectedCampus: Campus | null = null;
  loadingSections: boolean = false;

  @ViewChild(ModalSectionComponent, { static: false }) modalSection !: ModalSectionComponent;
  @ViewChild(ModalCampusComponent, { static: false }) modalCampus !: ModalCampusComponent;

  constructor(
    private escolar: EscolarService,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.activeRoute.params.subscribe((params: any) => {
      this.escolar.getSchool(this.user.school_id).subscribe({
        next: (res: any) => {
          this.school = res;
          this.loadCampuses(this.school?.school_id!);
        },
        error: (err: any) => {
          this.toastr.error('Ocurrió un error al obtener la escuela.', 'Error');
        }
      });
    })
  }

  loadCampuses(school_id: number) {
    this.loadingSections = true;
    this.escolar.getCampuses({ school_id: school_id }).subscribe({
      next: (res: any) => {
        this.campuses = res.data;
        this.loadingSections = false;
      }
    })
  }

  ngOnInit(): void {}

  addSection(campus: Campus) {
    this.selectedCampus = campus;
    const modalTemplate: any | undefined = this.modalSection;
    if (modalTemplate) {
      this.modalSection.openModal(modalTemplate['modalSection']);
    }
  }

  openModalCampus(campus: Campus | null) {
    this.selectedCampus = campus;
    const modalTemplate: any | undefined = this.modalCampus;
    if (modalTemplate) {
      this.modalCampus.openModal(modalTemplate['modalCampus']);
    }
  }

  openModalSection(campus: Campus, section: any) {
    this.selectedSection = section;
    this.selectedCampus = campus;
    const modalTemplate: any | undefined = this.modalSection;
    if (modalTemplate) {
      this.modalSection.openModal(modalTemplate['modalSection']);
    }
  }

  saveCampus(campus: any) {
    if (campus.campus_id) {
      this.escolar.updateCampus(campus.campus_id, { campus: campus }).subscribe({
        next: (res: any) => {
          this.toastr.success('Campus actualizado correctamente', 'Éxito');
          this.loadCampuses(this.school?.school_id!);
        },
        error: (err: any) => {
          this.toastr.error('Ocurrió un error al actualizar el campus', 'Error');
        }
      });
    } else {
      this.escolar.createCampus({ campus: campus }).subscribe({
        next: (res: any) => {
          this.toastr.success('Campus creado correctamente', 'Éxito');
          this.loadCampuses(this.school?.school_id!);
        },
        error: (err: any) => {
          this.toastr.error('Ocurrió un error al crear el campus', 'Error');
        }
      });
    }
  }

  saveSection(section: any) {
    if (section.section_id) {
      this.escolar.updateSection(section.section_id, { section: section }).subscribe({
        next: (res: any) => {
          this.toastr.success('Sección actualizada correctamente', 'Éxito');
          this.loadCampuses(this.school?.school_id!);
        },
        error: (err: any) => {
          this.toastr.error('Ocurrió un error al crear la sección', 'Error');
        }
      });
    } else {

      this.escolar.createSection({ section: section }).subscribe({
        next: (res: any) => {
          this.toastr.success('Sección actualizada correctamente', 'Éxito');
          this.loadCampuses(this.school?.school_id!);
        },
        error: (err: any) => {
          this.toastr.error('Ocurrió un error al crear la sección', 'Error');
        }
      });
    }
  }

  deleteCampus(campus: any) {
    const confirmation = confirm(`Eliminar campus: ¿Estás seguro de eliminar el campus ${campus?.name ? campus?.name : ''}?`);
      
    if (!confirmation) {
      return;
    } else {
      this.escolar.deleteCampus(campus.campus_id).subscribe({
        next: (res: any) => {
          this.toastr.success('Campus eliminado correctamente', 'Éxito');
          this.loadCampuses(this.school?.school_id!);
        },
        error: (err: any) => {
          this.toastr.error('Ocurrió un error al eliminar el campus', 'Error');
        }
      });
    }
  }

  deleteSection(section: any) {
    const confirmation = confirm(`Eliminar sección: ¿Estás seguro de eliminar la sección ${section?.name ? section?.name : ''} ${section?.grade ? section?.grade : ''} ${section?.group ? section?.group : ''}?`);
      
    if (!confirmation) {
      return;
    } else {
      this.escolar.deleteSection(section.section_id).subscribe({
        next: (res: any) => {
          this.toastr.success('Sección eliminada correctamente', 'Éxito');
          this.loadCampuses(this.school?.school_id!);
        },
        error: (err: any) => {
          this.toastr.error('Ocurrió un error al eliminar la sección', 'Error');
        }
      });
    }
  }

}
