import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

@Component({
  selector: 'app-notify-floater',
  template: `
    <div *ngIf="message;" id="fcm-messenger" class="bg-warning rounded-3 shadow d-none">
      <h5>{{ message.notification.title }}</h5>
      <p>{{ message.notification.body }}</p>
      <a class="underline">Ir a tarea</a>
    </div>
    <div class="d-none" *ngIf="!message;">
      No Message Arrived
    </div>
  `,
  styles: [`
    #fcm-messenger {
      position: fixed;
      top: 10px;
      right: 10px;
      padding: 10px;
      z-index: 9999;
    }
  
  `]
})
export class NotifyFloaterComponent implements OnInit {

  message:any = null;
  interval:any = null;

  constructor() {}

  ngOnInit(): void {
    this.requestPermission();
    this.listen();
  }
  
  requestPermission() {
    const messaging = getMessaging();
    // requestToken(messaging).then((currentToken) => {});
    
  getToken(messaging, 
    { vapidKey: environment.firebaseConfig.vapidKey}).then(
      (currentToken) => {
        if (currentToken) {
          localStorage.setItem('fcm_token', currentToken);
        } else {
        }
    }).catch((err) => {
  });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.message = payload;
      this.interval = setTimeout(() => {
        this.message = null;
      }, 4000);
    });
  }

}
