import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { TextService } from 'src/app/shared/services/text.service';
import { Section } from 'src/app/models/section.model';

@Component({
  selector: '[app-tr-notices]',
  template: `
  <td class="align-middle">
    <img src="{{url}}{{notice.images[0]?.url}}" width="64" class="img-fluid ps-2 overflow-hidden" *ngIf="notice.images.length>0" />
    <img src="assets/images/empty-image.jpg" width="64" class="img-fluid ps-2 overflow-hidden" *ngIf="notice.images==0" />
  </td>
  <td class="text-start align-middle">
    <span class="mb-0"><a routerLink="{{notice.notice_id}}">{{ notice.title }}</a></span>
    <p class="text-sm mb-0" [innerHTML]="textService.truncateTextAtWord(notice.description, 100)"></p>
  </td>
  <td class="align-middle text-center">
    <div class="d-flex justify-content-center">
      <div class="flex-column">
        <div class="bg-danger rounded-1 w-24 text-center text-light float-start me-2" style="width: 18px; height: 18px;">
          <fa-icon [icon]="icons.faStar" style="font-size: 10px; position: relative; top: -5px;"></fa-icon>
        </div>
      </div>
      <div class="flex-column ms-3">
        <div class="d-flex">
          <div class="">
            <span>{{genericType(notice.generic_type).name}}</span>
          </div>
          <div class="ms-auto" *ngFor="let section of selectedSections">
            <span class="badge badge-pill bg-secondary">{{ section?.section ? section.section : '' }} {{ section?.grade ? section.grade : ''}} {{ section?.group ? section.group : ''}}</span>
          </div>
        </div>
      </div>
    </div>
  </td>
  <td class="align-middle text-center">{{notice.published_at | date: 'dd/MM/yyyy'}}</td>
  <td class="align-middle text-center">
    <span *ngIf="notice.live">Público</span>
    <span *ngIf="!notice.live">Privado</span>
  </td>
  <td class="align-middle"></td>
  `,
  styleUrls: []
})
export class TrNoticesComponent {
  @Input() notice: any;
  @Input() sections: Section[] = [];
  @Input() permissions: any;

  selectedSections: any = [];

  @Output() updateNotice = new EventEmitter<any>();
  @Output() deleteNotice = new EventEmitter<any>();

  url: string = 'https://api.escalas.io/';

  constructor(
    public catalogs: CatalogsService,
    public icons: IconsService,
    public textService: TextService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sections']&&changes['sections'].currentValue) {
      this.notice!.generic_id?.split(',').forEach((element: any) => {        
        if (this.notice.generic_type === 3) {
          this.selectedSections.push(changes['sections'].currentValue.find((section: any) => { return section.section_id === parseInt(element) }));
        } else if (this.notice.generic_type === 5) {
        }
          
      });
    }
  }

  genericType(tipo: number) {
    return this.catalogs.noticeType.find((element: any) => { return element.id === tipo});
  }

  getGenericTypeName(tipo: number) {
    
  }

}
