import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AppCheckTokenResult } from '@firebase/app-check';
import { switchMap, take } from 'rxjs/operators';
import { AppCheckService } from '../services/app-check.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppCheckInterceptor implements HttpInterceptor {

  constructor(private readonly appCheckService: AppCheckService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.appCheckService.getToken()).pipe(
      take(1), // See https://stackoverflow.com/a/60196923/828547.
      switchMap((token: AppCheckTokenResult | undefined) => {
        // console.log('AppCheckInterceptor ===> ', token);
          if (token) {
              request = request.clone({
                  setHeaders: { "X-Firebase-AppCheck": token?.token },
              });
          }
          return next.handle(request);
      }),
    );
  }
}