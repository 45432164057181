import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Campus } from 'src/app/models/campus.model';
import { Section } from 'src/app/models/section.model';

@Component({
  selector: 'app-modal-section',
  template: `
  <ng-template #modalSection let-modal>
    <div class="modal-header">Configurar grupo</div>
    <div class="modal-body p-4">
      <div class="d-flex">
        <div class="flex-fill">
          <form [formGroup]="sectionForm" (ngSubmit)="saveSection()">
            <div class="form-group mt-3">
              <label for="">Nombre</label>
              <input type="text" class="form-control" formControlName="section">
            </div>
            <div class="form-group mt-3">
              <label for="">Grado</label>
              <input type="text" class="form-control" formControlName="grade">
            </div>
            <div class="form-group mt-3">
              <label for="">Grupo</label>
              <input type="text" class="form-control" formControlName="group">
            </div>
            <!-- div class="form-group mt-4">
              <label for="">Color</label>
              <input
                [style.background]="color"
                [(colorPicker)]="color"
                [ngStyle]="{ 'color': color }"
                formControlName="color"
                class="rounded-2 border-1 border-secondary shadow text-center"
                style="width: 44px; height: 44px;"
              />
            </!-->
            <div class="form-group text-end mt-5">
              <button class="btn btn-escalas">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  `,
  styleUrls: []
})
export class ModalSectionComponent implements OnInit {
  @Input() section: Section | null = null;
  @Input() campus: Campus | null = null;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalSection') modalSection !: ElementRef;

  color: any = "";
  textColor: any = "";

  private modalRef: NgbModalRef | undefined;

  sectionForm: FormGroup = new FormGroup({});

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['section'] && changes['section'].currentValue) {
      this.sectionForm.patchValue({
        section_id: changes['section'].currentValue?.section_id,
        section: changes['section'].currentValue?.name,
        grade: changes['section'].currentValue?.grade,
        group: changes['section'].currentValue?.group,
        color: changes['section'].currentValue?.color,
        text_color: changes['section'].currentValue?.text_color,
        logo: changes['section'].currentValue?.logo,
        campus_id: changes['section'].currentValue?.campus_id,
      });
    }
    if (changes['campus'] && changes['campus'].currentValue) {
      this.sectionForm.patchValue({
        campus_id: changes['campus'].currentValue?.campus_id,
      });
    }
  }

  initForm() {    
    this.sectionForm = this.fb.group({
      section_id: [''],
      section: ['', Validators.required],
      grade: [''],
      group: [''],
      color: [''],
      // text_color: [''],
      logo: [''],
      campus_id: [''],
    });
  }

  saveSection() {
    if (this.sectionForm.invalid) {
      this.toastr.error('Complete los campos requeridos', 'Error');
      return;
    }

    this.updateDismiss.emit(this.sectionForm.value);
    this.modalRef?.close();
    this.sectionForm.reset();
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.sectionForm.reset();
      this.modalRef.dismiss();
    }
  }
}
