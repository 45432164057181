import { Component } from '@angular/core';

@Component({
  selector: 'app-create-notices-message',
  template: ``,
  styleUrls: []
})
export class CreateNoticesMessageComponent {

}
