import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-offcanvas-notice-filter',
  template: `
    <div class="offcanvas-header">
			<h5 class="offcanvas-title text-light">Filtro</h5>      
			<button
				type="button"
				class="btn-close text-light"
				aria-label="Close"
				(click)="dismiss()"
			></button>
    </div>
    <div class="offcanvas-body text-light">
      <form [formGroup]="noticeFilter" (ngSubmit)="search()">
        <div class="form-group mb-3">
          <label for="name" class="form-label text-light">Nombre</label>
          <input type="text" class="form-control bg-transparent rounded-2 border-2 border-light text-light" placeholder="Buscar por nombre" formControlName="name">
        </div>
        <div class="form-group mb-3">
          <label for="name" class="form-label text-light">Familia</label>
          <input type="text" class="form-control bg-transparent rounded-2 border-2 border-light text-light" placeholder="Buscar por familia" formControlName="family_id">
        </div>
        <div class="form-group mb-3">
          <label for="campus_id" class="form-label text-light">Campus</label>
          <select class="form-select bg-transparent rounded-2 border-2 border-light text-light" (change)="selectCampus($event)" formControlName="campus_id">
            <option value="" selected>Selecciona una sección</option>
            <option *ngFor="let campus of campuses" [value]="campus.id">{{campus.name}}</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <label for="section_id" class="form-label text-light">Grupo</label>
          <select class="form-select bg-transparent rounded-2 border-2 border-light text-light" formControlName="section_id">
            <option value="" selected>Selecciona una sección</option>
            <option *ngFor="let section of activeSections" [value]="section.id">{{section.section}} {{section.grade}} {{section.group}}</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <label for="section_id" class="form-label text-light">Resultados por página</label>
          <select class="form-select bg-transparent rounded-2 border-2 border-light text-light" formControlName="per_page">
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <button class="btn btn-link text-danger me-2" (click)="cleanSearch()"><fa-icon [icon]="icons.faTimes" class="me-2"></fa-icon>Limpiar</button>
        </div>
        <button type="submit" class="btn btn-outline-primary" (click)="close()">
				  Filtrar
			  </button>
      </form>
    </div>
  `,
  styles: [
  ]
})
export class OffcanvasNoticeFilterComponent implements OnInit {
  @Input() prefs: any;
  @Input() sections: any;
  @Input() campuses: any;
  @Input() payload: any;

  noticeFilter: FormGroup = new FormGroup({});
  activeSections: any = [];

  constructor(
    public activeOffcanvas: NgbActiveOffcanvas,
    private fb: FormBuilder,
    public icons: IconsService,
  ) { }

  ngOnInit(): void {
    this.noticeFilter = this.fb.group({
      name: [''],
      section_id: [],
      campus_id: [],
      family_id: [],
      per_page: [50],
      page: [1]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['payload']&&changes['payload'].currentValue) {
      this.noticeFilter.patchValue(changes['payload'].currentValue);
    }
  }

  dismiss() {
    this.activeOffcanvas.dismiss('Cross click');
  }

  search() {}

  selectCampus(event: any) {
    
  }

  close() {
    let payload: any = {};
    const noticeF = this.noticeFilter.value;
    if (noticeF.section_id && noticeF.section_id !== '') {
      payload.section_id = noticeF.section_id;
    }
    if (noticeF.campus_id && noticeF.campus_id !== '') {
      payload.campus_id = noticeF.campus_id;
    }
    if (noticeF.family_id && noticeF.family_id !== '') {
      payload.family_id = noticeF.family_id;
    }
    if (noticeF.per_page) {
      payload.per_page = noticeF.per_page;
    }
    this.activeOffcanvas.close(payload);
  }

  cleanSearch() {
    this.noticeFilter.reset();
    this.close();
  }

}
