import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { Campus } from 'src/app/models/campus.model';
import { Family } from 'src/app/models/family.model';
import { School } from 'src/app/models/school.model';
import { Section } from 'src/app/models/section.model';
import { Student } from 'src/app/models/student.model';
import { EscolarService } from 'src/app/services/escolar.service';

@Component({
  selector: 'app-form-student',
  template: `
    <form [formGroup]="studentForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="name" class="form-label ms-2">Nombre</label>
        <input type="text" class="form-control" id="name" formControlName="name">
        <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
          <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-4">
        <label for="p_last_name" class="form-label">Primer Apellido</label>
        <input type="text" class="form-control" id="p_last_name" formControlName="p_last_name">
        <div *ngIf="ufc['p_last_name'].touched && ufc['p_last_name'].invalid">
          <div *ngIf="ufc['p_last_name'].errors?.['required']" class="text-danger text-sm">El primer apellido es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-4">
        <label for="m_last_name" class="form-label">Segundo Apellido</label>
        <input type="text" class="form-control" id="m_last_name" formControlName="m_last_name">
        <div *ngIf="ufc['m_last_name'].touched && ufc['m_last_name'].invalid">
          <div *ngIf="ufc['m_last_name'].errors?.['required']" class="text-danger text-sm">El segundo apellido es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-4" *ngIf="campuses!.length > 1">
        <label for="section_id" class="form-label">Campus</label>
        <select class="form-select" formControlName="campus_id" (change)="selectCampus($event)">
          <option selected disabled value="">Selecciona un campus</option>
          <option *ngFor="let campus of campuses" [value]="campus.campus_id">{{campus.name}}</option>
        </select>
        <div *ngIf="ufc['section_id'].touched && ufc['section_id'].invalid">
          <div *ngIf="ufc['section_id'].errors?.['required']" class="text-danger text-sm">El campus es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-4 w-100" *ngIf="dropdownList.length > 0">
        <label for="section_id" class="form-label">Grado</label>
        <ng-multiselect-dropdown
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="selectedItems"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          class="border-secondary"
          [ngModelOptions]="{standalone: true}"
        >
        </ng-multiselect-dropdown>
        <div *ngIf="ufc['sections'].touched && ufc['sections'].invalid">
          <div *ngIf="ufc['sections'].errors?.['required']" class="text-danger text-sm">El grado es requerido.</div>
        </div>
      </div>
      <div class="from-group text-end mt-5">
        <hr>
        <button class="btn btn-link text-dark me-2" routerLink="/students">Cancelar</button>
        <button class="btn btn-escalas">Guardar</button> 
      </div>
      
    </form>
  `,
  styleUrls: []
})
export class FormStudentComponent implements OnInit {
  @Input() student: Student | null = null;
  @Input() school: School | undefined;
  @Input() sections: Section[] = [];
  @Input() campuses: Campus[] = [];
  @Output() studentData: EventEmitter<any> = new EventEmitter<any>();

  families: Family[] = [];

  studentForm: FormGroup = new FormGroup({});
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: any = {};

  searchControl: FormControl = new FormControl();

  constructor(
    private store: EscolarService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private escolarService: EscolarService,
    private router: Router,
  ) {
    this.initForm();
    this.student?.sections?.map((item: any) => {
      this.dropdownList.push({ item_id: item.section_id, item_text: `${item.section} ${item.grade} ${item.group}` });
    });
  }

  ngOnInit(): void {
    
    // this.loadSections();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar Todo',
      unSelectAllText: 'Deseleccionar Todo',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.searchControl.valueChanges.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((term: string) => this.store.getFamilies({
      name: term, 
      campus_id: this.campuses.map((item: any) => item.campus_id)
    }))).subscribe((results: any) => {
      this.families = results.data;

    });
  }

  get ufc() {
    return this.studentForm.controls;
  }

  ngOnChanges(changes: SimpleChanges) {
    const ctl = this;
    if (changes['student'] && changes['student'].currentValue) {      
      this.studentForm.patchValue(changes['student'].currentValue);
      this.loadSections(changes['student'].currentValue);
    }
    if (changes['campuses'] && changes['campuses'].currentValue) {
      if (changes['campuses'].currentValue.length == 1) {
        this.studentForm.patchValue({ campus_id: changes['campuses'].currentValue[0].campus_id });
      }
    }

    if (changes['sections'] && changes['sections'].currentValue) {
      changes['sections'].currentValue.map((item: any) => {
        this.dropdownList.push({ item_id: item.section_id, item_text: `${item.section} ${item.grade} ${item.group}` });
      });
    }
  }

  initForm() {
    this.studentForm = this.fb.group({
      student_id: [''],
      name: ['', Validators.required],
      p_last_name: ['', Validators.required],
      m_last_name: ['', Validators.required],
      sections: ['', Validators.required],
      campus: ['', Validators.required],
      family: [''],
    });
  }

  loadSections(student: any) {
    student?.sections?.map((item: any) => {
      this.selectedItems.push({ item_id: item.section_id, item_text: `${item.name} ${item.grade}` });
    });
  }

  selectCampus(campus: any) {
    this.escolarService.getSections({ campus_id: campus.target.value }).subscribe((data: any) => {
      this.sections = data.data;

      this.sections.map((item: any) => {
        this.dropdownList.push({ item_id: item.section_id, item_text: `${item.section} ${item.grade} ${item.group}` });
      });      
    });
  }

  onSubmit() {
    
    if (this.selectedItems.length > 0) {      
      let selectedSections: Section[] = [];
      this.selectedItems.map((item: any) => {
        selectedSections.push({ section_id: item.item_id, section: item.item_text });
      });
      this.studentForm.patchValue({ sections: selectedSections });
    } else {
      this.toastr.error('Por favor, selecciona al menos un grupo');
      return;
    }
    if (this.studentForm.valid) {
      this.studentData.emit(this.studentForm.value);
    } else {
      this.toastr.error('Por favor, llena todos los campos');
      this.studentForm.markAllAsTouched();
    }
  }

  onItemSelect(item: any) {
    
  }
  onSelectAll(items: any) {
    
  }

}
