import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) { 
    super(httpClient);
  }
/*
  public getVehicles(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/vehicles?${this.parseQueryParams(data)}`);
  }

  public getVehicle(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/vehicles/${id}`);
  }

  public createVehicle(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/vehicles`, data);
  }

  public updateVehicle(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/vehicles/${id}`, data);
  }

  public getUserVehicle(id: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/vehicles/user/${id}`);
  }

  public removeUserVehicle(id: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/vehicles/user/${id}`);
  }

  public deleteVehicle(id: number): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/vehicles/${id}`);
  }

  public getTools(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tools?${this.parseQueryParams(data)}`);
  }

  public getTool(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tools/${id}`);
  }

  public createTool(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/tools`, data);
  }

  public updateTool(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/tools/${id}`, data);
  }

  public getUserTools(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tools/user/${id}`);
  }

  public addUserTool(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/tools/user/${id}`, data);
  }

  public removeUserTool(id: any): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/tools/user/${id}`);
  }

  public deleteTool(id: number): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/tools/${id}`);
  }

  public getSkills(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/skills?${this.parseQueryParams(data)}`);
  }

  public getSkill(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/skills/${id}`);
  }

  public createSkill(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/skills`, data);
  }

  public updateSkill(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/skills/${id}`, data);
  }

  public deleteSkill(id: number): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/skills/${id}`);
  }

  public getUserSkills(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/skills/user/${id}`);
  }

  public addUserSkill(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/skills/user`, data);
  }

  public removeUserSkill(data: any): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/skills/user`, data);
  }

  public getTechSkills(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/skills/tech/${id}`);
  }

  public getBuilders(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/builders?${this.parseQueryParams(data)}`);
  }

  public getBuilder(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/builders/${id}`);
  }

  public createBuilder(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/builders`, data);
  }

  public updateBuilder(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/builders/${id}`, data);
  }

  public deleteBuilder(id: number): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/builders/${id}`);
  }

  public getActivities(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/activities?${this.parseQueryParams(data)}`);
  }

  public getActivity(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/activities/${id}`);
  }

  public createActivityTools(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/activities/tools`, data);
  }

  public createActivitySkills(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/activities/skills`, data);
  }

  public deleteActivitySkills(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/activities/remove/skills`, data);
  }

  public deleteActivityTools(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/activities/remove/tools`, data);
  }

  public createActivity(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/activities`, data);
  }

  public updateActivity(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/activities/${id}`, data);
  }

  public deleteActivity(id: number): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/activities/${id}`);
  }

  public getActivityTypes(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/activity_types?${this.parseQueryParams(data)}`);
  }

  public getActivityType(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/activity_types/${id}`);
  }

  public createActivityType(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/activity_types`, data);
  }

  public updateActivityType(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/activity_types/${id}`, data);
  }

  public deleteActivityType(id: number): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/activity_types/${id}`);
  }

  public getTaskCategories(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/task-categories?${this.parseQueryParams(data)}`);
  }

  public getTaskCategory(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/task-categories/${id}`);
  }

  public createTaskCategory(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/task-categories`, data);
  }

  public updateTaskCategory(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/task-categories/${id}`, data);
  }

  public deleteTaskCategory(id: number): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/task-categories/${id}`);
  }

  public getTasks(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tasks?${this.parseQueryParams(data)}`);
  }

  public getTask(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tasks/${id}`);
  }

  public getTaskReview(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tasks/review/${id}`);
  }

  public createTask(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/tasks`, data);
  }

  public updateTask(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/tasks/${id}`, data);
  }

  public cancelTask(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tasks/cancel/${id}`);
  }
  
  public getWorkHours(): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/work_hours`);
  }

  public getWorkHour(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/work_hours/${id}`);
  }

  public createWorkHour(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/work_hours`, data);
  }

  public updateWorkHour(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/work_hours/${id}`, data);
  }

  public deleteWorkHour(id: number): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/work_hours/${id}`);
  }

  public getTechStats(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tech_stats?${this.parseQueryParams(data)}`);
  }

  public getTechStat(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tech_stats/${id}`);
  }

  public getTechStatsSummary(id: number, data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tech_stats/summary/${id}?${this.parseQueryParams(data)}`);
  }

  // CRUD for holidays

  public getHolidays(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/work_schedules?${this.parseQueryParams(data)}`);
  }

  public getHoliday(id: number): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/work_schedules/${id}`);
  }

  public createHoliday(data: any): Observable<any> {
    return this.httpClient.post(`${this.taskURL}/work_schedules`, data);
  }

  public updateHoliday(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.taskURL}/work_schedules/${id}`, data);
  }

  public deleteHoliday(id: number): Observable<any> {
    return this.httpClient.delete(`${this.taskURL}/work_schedules/${id}`);
  }

  public getTechStatSummaries(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tech_stat_summaries?${this.parseQueryParams(data)}`);
  }

  public getTechStatSummariesFilter(data: any): Observable<any> {
    return this.httpClient.get(`${this.taskURL}/tech_stat_summaries/datefilter?${this.parseQueryParams(data)}`);
  }
*/

}
