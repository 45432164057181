import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: '[app-tr-tutors]',
  template: `
    <td class="align-middle"><strong>({{tutor.tutor_id}}) {{tutor.name}} {{tutor.last_name}}</strong></td>
    <td class="align-middle text-center">
      <div class="rounded-5 float-start mt-1 me-3" [ngClass]="tutor.user?.is_active ? 'bg-success' : 'bg-danger'" style="width: 8px; height: 8px;"></div>
      <span>{{tutor.user?.phone_number}}</span>
    </td>
    <!-- td class="align-middle">
      {{tutor.campus_name}}
    </!-->
    <td class="align-middle">
      <button class="btn btn-link p-0" routerLink="{{tutor.tutor_id}}"><fa-icon [icon]="icons.faEdit"></fa-icon></button>
    </td>
  `,
  styleUrls: []
})
export class TrTutorsComponent {
  @Input() tutor: any;
  @Input() permissions: any;

  @Output() updateTutor = new EventEmitter<any>();
  @Output() deleteTutor = new EventEmitter<any>();

  constructor(
    private store: EscolarService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    
  }
}
