import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EscolarService } from 'src/app/services/escolar.service';

@Component({
  selector: 'app-form-family',
  template: `
    <form [formGroup]="familyForm" (ngSubmit)="onSubmit()">
      <div class="from-group">
        <div for="code" class="form-label">Código</div>
        <input type="text" class="form-control border-secondary" id="code" formControlName="code">
      </div>
      <div class="form-group mt-2">
        <label for="name" class="form-label">Nombre</label>
        <input type="text" class="form-control border-secondary" id="name" formControlName="name">
      </div>
      <div class="form-group mt-2">
        <label for="email" class="form-label">Correo electrónico</label>
        <input type="email" class="form-control border-secondary" id="email" formControlName="email">
      </div>
      <div class="form-group mt-4 text-end">
        <button class="btn btn-outline-primary">Guardar</button>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormFamilyComponent implements OnInit {
  @Input() student: any;
  @Input() family: any;
  @Output() familyData: EventEmitter<any> = new EventEmitter<any>();

  familyForm: any = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private escolarService: EscolarService,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['family'] && changes['family'].currentValue) {
      this.familyForm.patchValue(changes['family'].currentValue);
    }
  }

  initForm() {
    this.familyForm = this.fb.group({
      code: '',
      name: '',
      email: ''
    });
  }

  onSubmit() {
    if (this.familyForm.valid) {
      this.familyData.emit(this.familyForm.value);
    } else {
      this.toastr.error('Por favor, llena los campos correctamente');
    }
  }

}
