import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Campus } from 'src/app/models/campus.model';
import { Family } from 'src/app/models/family.model';
import { School } from 'src/app/models/school.model';
import { Section } from 'src/app/models/section.model';
import { Student } from 'src/app/models/student.model';
import { Tutor } from 'src/app/models/tutor.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-edit-student',
  template: `
    
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo?.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb></xng-breadcrumb>
      </div>
      <div class="d-flex mb-2">
        <div class="flex-column">
          <h4>Perfil del Alumno</h4>
          <p>Configura la información del profesor para que pueda ver la información personalizada.</p>
        </div>
      </div>

      <div class="d-flex">
        <div class="flex-fill">
          <app-form-student [student]="student" [sections]="sections" [school]="school" (studentData)="updateStudent($event)"></app-form-student>
        </div>
        <div class="flex-shrink-0 ms-5">
          <div class="list-group border-0 bg-transparent mt-2">
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0 text-start">
              <div>
                <h4 class="mb-0" style="font-size: 1em; font-weight: 500;">Familia</h4>
                <p class="text-sm text-muted mb-0" style="font-size: 13px; font-weight: 300;">Detalle de la familia del alumno</p>
              </div>
              <div class="flex-fill text-end mt-0" *ngIf="student?.family">
                <button class="btn btn-outline-secondary btn-sm" routerLink="/families/{{student?.family?.family_id}}">Editar</button>
              </div>
            </div>

            <div *ngFor="let tutor of tutors">
              <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex align-items-start py-3 px-0 list-group-item-action">
                <div class="flex-grow-1"><strong>Nombre</strong></div>
                <div class="flex-fill text-start">{{tutor?.name}}</div>
              </div>
            </div>
          
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex align-items-start py-3 px-0 list-group-item-action">
              <div class="flex-grow-1"><strong>Nombre</strong></div>
              <div class="flex-fill text-start">{{student?.family?.name}}</div>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex align-items-start py-3 px-0 list-group-item-action">
              <div class="flex-grow-1"><strong>Código</strong></div>
              <div class="flex-fill text-start">{{student?.family?.code}}</div>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex align-items-start py-3 px-0 list-group-item-action">
              <div class="flex-grow-1"><strong>Corrreo electrónico</strong></div>
              <div class="flex-fill test-start">{{student?.family?.email}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class EditStudentComponent {
  student: Student | null = null;
  sections: Section[] = [];
  school: School | undefined;
  family: Family | undefined;
  campus: Campus | undefined;
  tutors: Tutor[] = [];
  campuses: Campus[] = [];

  constructor(
    private escolarService: EscolarService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.route.params.subscribe((params) => {
      this.escolarService.getStudent(params['id']).subscribe((student: any) => {
        this.student = student;

        this.escolarService.getTutors({ family_id: this.student?.family?.family_id }).subscribe((family: any) => {
          this.tutors = family.data;
        });

        this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
        const curr_campus = this.student?.campus?.campus_id ? this.student?.campus?.campus_id : this.campuses.map((item: any) => item.campus_id);

        this.escolarService.getSections({ campus_id: curr_campus, per_page: 1000 }).subscribe((data: any) => {
          this.sections = data.data;
        });
      });
    });

    
  }

  updateStudent(student: any) {
    this.escolarService.updateStudent(student.student_id, { student: student }).subscribe((data: any) => {
      this.toastr.success('Estudiante actualizado correctamente');
      // this.router.navigate(['/students']);
    });
  }

  updateFamily(family: any) {
    this.escolarService.updateFamily(family.id, family).subscribe((data: any) => {
      this.toastr.success('Familiar actualizado correctamente');
      // this.router.navigate(['/students']);
    });
  }

}
