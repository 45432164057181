import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Campus } from 'src/app/models/campus.model';
import { School } from 'src/app/models/school.model';

@Component({
  selector: 'app-modal-campus',
  template: `
    <ng-template #modalCampus let-modal>
      <div class="modal-header">Configurar campus</div>
      <div class="modal-body p-4">
        <div class="d-flex">
          <div class="flex-fill">
            <form [formGroup]="campusForm" (ngSubmit)="saveCampus()">
              <div class="form-group mt-3">
                <label for="">Nombre</label>
                <input type="text" class="form-control" formControlName="name">
                <div *ngIf="ff['name'].touched && ff['name'].invalid">
                  <div *ngIf="ff['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="description">Descripción</label>
                <input type="text" class="form-control" formControlName="description">
                <div *ngIf="ff['description'].touched && ff['description'].invalid">
                  <div *ngIf="ff['description'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
                </div>
              </div>
              <div class="form-group text-end mt-5">
                <button class="btn btn-escalas">Guardar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styles: []
})
export class ModalCampusComponent implements OnInit {
  @Input() school: School | null = null;
  @Input() campus: Campus | null = null;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalCampus') modalCampus !: ElementRef;

  private modalRef: NgbModalRef | undefined;
  campusForm: FormGroup = new FormGroup({});

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.campusForm = this.fb.group({
      campus_id: [''],
      name: [''],
      description: [''],
      school_id: ['']
    });
  }

  get ff() {
    return this.campusForm.controls;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['campus'] && changes['campus'].currentValue) {
      this.campusForm.patchValue({
        campus_id: changes['campus'].currentValue?.campus_id,
        name: changes['campus'].currentValue?.name,
        description: changes['campus'].currentValue?.description,
        school_id: changes['campus'].currentValue?.school_id
      });
    }
  }

  saveCampus(): void {
    this.campusForm.patchValue({ school_id: this.school?.school_id });
    
    if (this.campusForm.invalid) {
      this.toastr.error('Completa todos los campos.');
      this.campusForm.markAllAsTouched();
      return;
    }

    this.updateDismiss.emit(this.campusForm.value);
    this.modalRef?.close();
    this.campusForm.reset();
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.campusForm.reset();
      this.modalRef.dismiss();
    }
  }
}
