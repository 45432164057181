import { AfterViewInit, Component, inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbOffcanvas, NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-offcanvas-student-filter',
  template: `
    <div class="offcanvas-header">
			<h5 class="offcanvas-title text-light">Filtro</h5>      
			<button
				type="button"
				class="btn-close text-reset"
				aria-label="Close"
				(click)="dismiss()"
			></button>
    </div>
		<div class="offcanvas-body text-light">
      <form [formGroup]="studentFilter" (ngSubmit)="search()">
        <div class="form-group mb-3">
          <label for="name" class="form-label text-light">Nombre</label>
          <input type="text" class="form-control bg-transparent rounded-2 border-2 border-light text-light" placeholder="Buscar por nombre" formControlName="name">
        </div>
        <div class="form-group mb-3">
          <label for="name" class="form-label text-light">Familia</label>
          <input type="text" class="form-control bg-transparent rounded-2 border-2 border-light text-light" placeholder="Buscar por familia" formControlName="family_id">
        </div>
        <div class="form-group mb-3">
          <label for="campus_id" class="form-label text-light">Campus</label>
          <select class="form-select bg-transparent rounded-2 border-2 border-light text-light" (change)="selectCampus($event)" formControlName="campus_id">
            <option value="" selected>Selecciona una sección</option>
            <option *ngFor="let campus of campuses" [value]="campus.id">{{campus.name}}</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <label for="section_id" class="form-label text-light">Grupo</label>
          <select class="form-select bg-transparent rounded-2 border-2 border-light text-light" formControlName="section_id">
            <option value="" selected>Selecciona una sección</option>
            <option *ngFor="let section of activeSections" [value]="section.id">{{section.section}} {{section.grade}} {{section.group}}</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <label for="section_id" class="form-label text-light">Resultados por página</label>
          <select class="form-select bg-transparent rounded-2 border-2 border-light text-light" formControlName="per_page">
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <button class="btn btn-link text-danger me-2" (click)="cleanSearch()"><fa-icon [icon]="icons.faTimes" class="me-2"></fa-icon>Limpiar</button>
        </div>
        <button type="submit" class="btn btn-outline-primary" (click)="close()">
				  Filtrar
			  </button>
      </form>			
		</div>
  `,
  styleUrls: []
})
export class OffcanvasStudentFilterComponent implements OnInit {  
	@Input() name: any; // string;
  @Input() prefs: any;
  @Input() sections: any;
  @Input() campuses: any;
  @Input() payload: any;

  studentFilter: any = new FormGroup({});
  selectedCampus: any = {};
  activeSections: any = [];

  constructor(
    public activeOffcanvas: NgbActiveOffcanvas,
    private fb: FormBuilder,
    public icons: IconsService,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.studentFilter = this.fb.group({
      name: [''],
      section_id: [],
      campus_id: [],
      family_id: [],
      per_page: [50],
      page: [1]
    });
    if (this.prefs) {
      this.loadForm(this.prefs);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['payload'] && changes['payload'].currentValue) {
      this.studentFilter.patchValue(changes['payload'].currentValue);
    }
    if (changes['prefs'] && changes['prefs'].currentValue) {
      this.loadForm(changes['prefs'].currentValue);
      
    }
  }

  loadForm(prefs: any) {
    if (prefs.campus_id) {
      this.studentFilter.controls.campus_id.setValue(prefs.campus_id);
      this.activeSections = this.sections.filter((section: any) => {
        return section.campus.id === parseInt(prefs.campus_id);
      });
    }
    if (prefs.section_id) {
      this.studentFilter.controls.section_id.setValue(prefs.section_id);
    }
    // this.studentFilter.value.family_id = prefs.family_id;
    this.studentFilter.patchValue({ per_page: prefs.per_page });
  }

  dismiss() {
    this.activeOffcanvas.dismiss('Cross click');
  }

  close() {
    let payload: any = {};
    const stuF = this.studentFilter.value;
    if (stuF.section_id && stuF.section_id !== '') {
      payload.section_id = stuF.section_id;
    }
    if (stuF.campus_id && stuF.campus_id !== '') {
      payload.campus_id = stuF.campus_id;
    }
    if (stuF.family_id && stuF.family_id !== '') {
      payload.family_id = stuF.family_id;
    }
    if (stuF.per_page) {
      payload.per_page = stuF.per_page;
    }
    this.activeOffcanvas.close(payload);
  }

  selectCampus(event: any) {
    this.activeSections = this.sections.filter((section: any) => {
      return section.campus.id === parseInt(event.target.value);
    });
  }

  search() {}

  cleanSearch() {
    this.studentFilter.reset();
    this.studentFilter.patchValue({ per_page: 50 });
    this.close();
  }
}
