import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { faTimes, faEllipsisVertical, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { DateService } from 'src/app/shared/services/date.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-calendar-event',
  template: `
    <div      
      *ngIf="isVisible"
      [style.position]="'fixed'"
      [style.top.px]="top"
      [style.left.px]="left"
      [style.width.px]="380"
      [style.zIndex]="1000"
      class="cal-event bg-white border border-gray-300 rounded-4 shadow p-2"
    >
      <div class="d-flex">        
        <div class="flex-fill text-end">
          <a routerLink="{{selectedTask.id}}" class="me-2"><fa-icon [icon]="faEdit"></fa-icon></a>          
          <fa-icon [icon]="faTimes" (click)="closeWindow()" class="me-2"></fa-icon>
        </div>
      </div>
      <div class="d-flex">
        <div class="ms-2 me-3">
          <img src="https://api.escalas.io/{{selectedTask.image.url}}" width="54" alt="Avatar">
        </div>
        <div class="flex-fill">
          <h4 class="mb-0">{{selectedTask.title}}</h4>
          <p class="mb-0" style="font-size: 15px;">{{ selectedTask?.start_date | date: 'dd/MM/yy' }} {{ selectedTask?.start_date | date: 'HH:mm'}}</p>
          <p [innerHTML]="selectedTask.description"></p>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class CalendarEventComponent implements OnInit {
  @Input() top: any;
  @Input() left: any;
  @Input() selectedTask: any;
  @Input() taskCategories: any[] = [];
  
  @Input() priority: any[] = [];
  @Input() status: any[] = [];
  @Input() isVisible: boolean | undefined; // = true;
  @Input() isSelected: any;

  @Output() isVisibleChange = new EventEmitter<boolean>();

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  builder: any;

  faTimes = faTimes;
  faEdit = faEdit;
  faEye = faEye;
  faEllipsisVertical = faEllipsisVertical;

  constructor(
    private eRef: ElementRef,
    private dateService: DateService
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isVisible'] && changes['isVisible'].currentValue) {      
      this.isVisible = changes['isVisible'].currentValue;
    }
  }

  parseBuilder(task: any) {
    if (this.taskCategories.length==0 || !task) return '';
    return this.taskCategories.find((builder: any) => builder.id === task).name;
  }

  parsePriority(priority: any) {
    if (!priority) return '';
    return this.priority.find((p: any) => p.id === priority);
  }

  parseStatus(status: any) {
    if (!status) return '';
    return this.status.find((s: any) => s.id === status);
  }

  getDuration(task: any) {
    let time = this.dateService.addMinutesToDate(task.due_date, task.duration);
    return time.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':');
  }

  getAvatar(el: any) {
    if (!el) return '';
    return `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
  }

  closeWindow() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    // if (this.isVisible) {
      // if (this.selectedTask.id != null) {
      if(!this.eRef.nativeElement.contains(event.target)) {
        // this.isVisible = false;
        // this.isVisibleChange.emit(this.isVisible);
        // this.isVisible = false;
        // Handle the outside click. For example, close the div or hide it
      } else {
        // Optional: Handle the inside click if necessary
      }
    // }
    // }
  }
}
