import { Component, Input, SimpleChanges } from '@angular/core';
import { Section } from 'src/app/models/section.model';
import { Student } from 'src/app/models/student.model';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: '[app-tr-students]',
  template: `
    <td class="align-middle"><input type="checkbox" value="" id="flexCheckDefault" [checked]="selected"></td>
    <td class="align-middle">    
      <strong>{{student?.name}} {{student?.p_last_name}} {{student?.m_last_name}}</strong></td>
    <td class="align-middle text-center">
      {{student?.campus.name}} {{student?.campus.description}}
    </td>
    <td class="align-middle text-center">
      <div class="badge bg-light text-secondary me-2 mb-1 py-2" *ngFor="let section of student?.sections">
        {{section.name}} {{section.grade}} {{section.group}}
      </div>
    </td>
    <td class="text-end"><button class="btn btn-link p-0" routerLink="{{student?.student_id}}"><fa-icon [icon]="icons.faEdit"></fa-icon></button></td>
  `,
  styleUrls: []
})
export class TrStudentsComponent {
  @Input() student: Student | any;
  @Input() sections: Section[] = [];
  @Input() permissions: any;
  @Input() bulkSelect: boolean = false;

  selected: boolean = false;

  currentSection: any = {};
  
  constructor(
    public icons: IconsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes['sections'] && changes['sections'].currentValue !== undefined) {
      // this.currentSection = changes['sections'].currentValue.find((section: any) => section.id === this.student.section_id);
    // }
    if (changes['bulkSelect'] && changes['bulkSelect'].currentValue) {
      console.log('bulkSelect ===> ', this.bulkSelect);
      this.selected = this.bulkSelect;
    }
  }

}
