import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { Campus } from 'src/app/models/campus.model';
import { Professor } from 'src/app/models/professor.model';
import { School } from 'src/app/models/school.model';
import { Section } from 'src/app/models/section.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-index-professors',
  template: `
    <div class="bg-white p-4 rounded-4 shadow"> <!-- col-12 col-lg-10 offset-lg-1 mt-3 mb-3 -->
      <div class="d-flex mb-2 mb-md-4">
        <img src="https://api.escalas.io{{school.logo!.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <span class="slashy">/</span>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="d-flex mb-2">
        <div class="">
          <h1 class="mb-0">Profesores<span class="text-muted ms-2" style="font-size: 0.5em;">({{meta.total_entries}})</span></h1>
        </div>
        <div class="flex-fill text-end mt-0">
          <div class="float-end">
            <!-- div class="btn-group border border-1 border-secondary me-2" role="group" aria-label="Basic example">
              <button id="btnList" #btnList type="button" class="btn btn-outline-secondary" (click)="selectView('btnList')">
                <fa-icon [icon]="icons.faList"></fa-icon>
              </button>
              <button id="btnBloc" #btnBloc type="button" class="btn btn-outline-secondary" (click)="selectView('btnBloc')">
              <fa-icon [icon]="icons.faGrip"></fa-icon>
              </button>
            </!-->            
            <div class="d-inline-block">
              <button class="btn btn-outline-secondary ms-2 border-1" (click)="open()">
                <fa-icon [icon]="icons.faSliders" class="me-0 me-md-2"></fa-icon>
                <span class="d-none d-sm-none d-md-inline-block">Filtrar</span>
              </button>
            </div>
            <button class="btn btn-dark ms-2" routerLink="create">
              <fa-icon [icon]="icons.faPlus" class="me-0 me-md-2"></fa-icon>
              <span class="d-none d-sm-none d-md-inline-block">Agregar profesor</span>
            </button>
          </div>
          <div class="form-group float-end me-2" style="width: 200px;">
            <div class="input-group">
              <div class="input-group-addon border border-1 rounded-start-2 border-secondary border-end-0 ps-2 text-secondary" style="padding-top: 7px;">
                <fa-icon [icon]="icons.faSearch" ></fa-icon>
              </div>
              <!-- input class="form-control border-2 border-secondary bg-transparent border-start-0" placeholder="Buscar" -->
              <input id="typeahead-basic" type="text" class="form-control border-1 border-secondary bg-transparent border-start-0" [formControl]="searchControl"  /> <!-- [ngbTypeahead]="searchx" -->
            </div>
          </div>
        </div>
      </div>      
      <div class="mt-3" *ngIf="activeView=='btnList'">
        <table class="table table-hover table-responsive">
          <thead>
            <tr>
              <th class="bg-light text-secondary align-items-center align-middle rounded-top-left-3"><input type="checkbox" value="" id="flexCheckDefault"></th>
              <th class="bg-light text-secondary align-items-center align-middle text-start">Nombre</th>
              <th class="bg-light text-secondary align-items-center align-middle text-center">Teléfono</th>
              <th class="bg-light text-secondary text-center">Campus</th>
              <th class="bg-light text-secondary rounded-end-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr app-tr-professors 
              [professor]="professor" 
              [sections]="sections"
              *ngFor="let professor of professors | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }"
            >
            </tr>
          </tbody>
        </table>
        <div class="text-center py-5" *ngIf="professors.length==0&&loading">
          <fa-icon [icon]="icons.faCircleNotch" size="4x" animation="spin" class="text-escalas mt-5"></fa-icon>
          <p class="text-muted mt-5">Cargando...</p>
        </div>
        <div class="py-5 text-center" *ngIf="professors.length==0&&!loading">
            <img src="/assets/images/empty-state.png" width="240px">
            <h5 class="text-muted">No se encontraron registros</h5>
          </div>
        <div class="d-flex justify-content-center">
          <pagination-controls
            (pageChange)="p = $event"
            (pageChange)="pageChanged($event)"
            previousLabel="Prev."
            nextLabel="Sig."
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            *ngIf="professors.length>0"
          ></pagination-controls>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class IndexProfessorsComponent {
  @ViewChild('btnList') btnList!: ElementRef;
  @ViewChild('btnBloc') btnBloc!: ElementRef;
  
  school: School;
  professors: Professor[] = [];
  meta: any = {};
  permissions: any = {};
  loading: boolean = false;
  viewPrefs: any = {};
  prefs: any = {};

  campuses: Campus[] = [];
  sections: Section[] = [];

  model: any;

  activeView: string = 'btnList';

  searchControl: FormControl = new FormControl(); 

  payload: any = {
    per_page: 50,
    page: 1,
    professors: null
  };
  p: number = 1;

  constructor(
    private escolarService: EscolarService,
    private router: Router,
    private toastr: ToastrService,
    public icons: IconsService
  ) { 
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = localStorage.getItem('campuses') != null ? JSON.parse(localStorage.getItem('campuses')!) : [];
    this.payload.campus_id = this.campuses.map((item: any) => item.campus_id);
    this.loadProfessors(this.payload);
  }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => this.escolarService.getProfessors({
        name: term, 
        school_id: this.school.school_id,
        campus_id: this.payload.campus_id
      }))).subscribe((results: any) => {
        this.professors = results.data;
        this.meta = results.meta;
      });
  }

  loadProfessors(payload: any) {
    this.loading = true;
    this.escolarService.getProfessors(payload).subscribe({
      next: (professors: any) => {
        this.professors = professors.data;
        this.meta = professors.meta;
        this.loading = false;
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al obtener los professores.', 'Error');
        this.loading = false;
      }
    });
  }

  open() {}

  selectView(event: any) {
    this.btnList.nativeElement.classList.remove('active');
    this.btnBloc.nativeElement.classList.remove('active');

    if (event == 'btnList') {
      this.btnList.nativeElement.classList.add('active');
    } else if (event == 'btnBloc') {
      this.btnBloc.nativeElement.classList.add('active');
    }

    this.activeView = event; //  == '' ? 'btnList' : 'btnBloc';
    localStorage.setItem('viewPrefs', this.activeView);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadProfessors(this.payload);
  }
}
