import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Campus } from 'src/app/models/campus.model';
import { Notice } from 'src/app/models/notice.model';
import { School } from 'src/app/models/school.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-create-notices-bulletin',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="alert alert-warning alert-dismissible fade show rounded-4 overflow-hidden" role="alert" *ngIf="notice&&notice.expired_at">
        <fa-icon [icon]="icons.faCircleInfo" class="me-2"></fa-icon>
        <strong>Este aviso expiró</strong> el , ya no es visible para los usuarios a los que se les compartió su contenido.            
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <app-form-notice [notice]="notice" [school]="school" [campuses]="campuses" (noticeData)="createNotice($event)"></app-form-notice>
    </div>
  `,
  styleUrls: []
})
export class CreateNoticesBulletinComponent implements OnInit {
  notice: Notice | null = null;
  school: School;
  campuses: Campus[] = [];

  constructor(
    private escolarService: EscolarService,
    private router: Router,
    private route: ActivatedRoute,
    public icons: IconsService,
    private toastr: ToastrService
  ) {
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = JSON.parse(localStorage.getItem('campuses') || '[]');
  }

  ngOnInit(): void {
      
  }

  createNotice(data: any) {
    data.notice_id = null;
    this.escolarService.createNotice(data).subscribe({
      next: (notice: any) => {
        this.toastr.success('Aviso creado', 'Éxito');
        this.router.navigate(['/notices/bulletins']);
      },
      error: (error: any) => {
        this.toastr.error('Error al crear el aviso', 'Error');
      }
  });
  }

}
