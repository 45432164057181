import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { Professor } from 'src/app/models/professor.model';
import { Section } from 'src/app/models/section.model';
import { School } from 'src/app/models/school.model';
import { Campus } from 'src/app/models/campus.model';
import { ToastrService } from 'ngx-toastr';
import intlTelInput from 'intl-tel-input';
declare var intlTelInputUtils: any;

@Component({
  selector: 'app-form-professor',
  template: `
    <form [formGroup]="formProfessor" (ngSubmit)="onSubmit()">
      <div class="form-group mt-2">
        <label for="name" class="form-label ms-2">Nombre</label>
        <input type="text" class="form-control border-secondary" id="name" formControlName="name">
        <div *ngIf="ff['name']?.touched && ff['name']?.invalid">
          <div *ngIf="ff['name']?.errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-4">
        <label for="p_last_name" class="form-label ms-2">Apellido paterno</label>
        <input type="text" class="form-control border-secondary" id="p_last_name" formControlName="p_last_name">
        <div *ngIf="ff['p_last_name'].touched && ff['p_last_name'].invalid">
          <div *ngIf="ff['p_last_name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-4">
        <label for="m_last_name" class="form-label ms-2">Apellido materno</label>
        <input type="text" class="form-control border-secondary" id="m_last_name" formControlName="m_last_name">
        <div *ngIf="ff['m_last_name'].touched && ff['m_last_name'].invalid">
          <div *ngIf="ff['m_last_name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-4">
        <label for="phone_number" class="form-label ms-2 w-100">Teléfono</label>
        <input type="hidden" class="form-control border-secondary" id="phone_number" formControlName="phone_number">
        <input type="tel"
          class="form-control w-100 border-secondary {{isInvalid ? 'text-danger': ''}}"
          (focus)="onFocus()"
          (blur)="onBlur()"
          (keypress)="onInputKeyPress($event)"
          [(ngModel)]="phoneNumber"
          [ngModelOptions]="{standalone: true}"
          #telInput
        >
        <div *ngIf="ff['phone_number'].touched && ff['phone_number'].invalid">
          <div *ngIf="ff['phone_number'].errors?.['required']" class="text-danger text-sm">El teléfono es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-4 mb-2" *ngIf="campuses!.length > 1">
        <label for="campus_id" class="form-label">Campus</label>
        <select class="form-select border-secondary" formControlName="campus_id" (change)="selectCampus($event)">
          <option selected disabled value="">Selecciona un campus</option>
          <option *ngFor="let campus of campuses" [value]="campus.campus_id">{{campus.name}}</option>
        </select>
        <div *ngIf="ff['campus_id'].touched && ff['campus_id'].invalid">
          <div *ngIf="ff['campus_id'].errors?.['required']" class="text-danger text-sm">El campus es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3" *ngIf="dropdownList.length > 0">
        <label for="section_id" class="form-label">Grupos</label>
        <ng-multiselect-dropdown
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="selectedItems"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          [ngModelOptions]="{standalone: true}"
          class="border-secondary"
        >
        </ng-multiselect-dropdown>
        <!-- div *ngIf="ff['section_id'].touched && ff['section_id'].invalid">
          <div *ngIf="ff['section_id'].errors?.['required']" class="text-danger text-sm">El grado es requerido.</div>
        </!-->
      </div>
      <div class="form-group mt-4" *ngIf="professor?.professor_id">
        <fieldset class="rounded-2 py-3 px-4 bg-light" style="border: 1px solid rbga(0,0,0,0.5);">
          <div class="list-group border-0 bg-transparent">
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <div>
                <h4 style="font-size: 1em; font-weight: 500;">Configuración del usuario</h4>
                <p class="text-sm text-muted mb-0" style="font-size: 13px; font-weight: 300;">Estos datos representan la información del usuario en la aplicación.</p>
              </div>
            </div>
            <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <strong>Notificaciones activas</strong>
              <div class="form-check form-switch mt-3 ps-0">
                <input class="form-check-input form-check-input-lg float-end" type="checkbox" formControlName="notify">
                <label class="form-check-label" for="flexCheckDefault"></label>
              </div>
            </div>
            <div class="bg-transparent border-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
              <strong>Activo</strong>
              <div class="form-check form-switch mt-3 ps-0">
                <input class="form-check-input form-check-input-lg float-end" type="checkbox" formControlName="is_active">
                <label class="form-check-label" for="flexCheckDefault"></label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="form-group mt-5 text-end">
        <hr style="border-color: rbga(0,0,0,0.4);">
        <div class="d-flex">
          <div class="ms-auto">
            <button class="btn btn-outline-danger" (click)="deleteProfessor()" *ngIf="professor?.professor_id"><fa-icon [icon]="icons.faTrashAlt" class="me-2"></fa-icon> Eliminar</button>
          </div>
          <div class="flex-fill">
            <button type="button" class="btn btn-default text-dark me-2">Cancelar</button>
            <button type="submit" class="btn btn-escalas">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormProfessorComponent implements OnInit {
  @Input() professor: Professor | null = null;
  @Input() sections: Section[] = [];
  @Input() school: School | null = null;
  @Input() campuses: Campus[] = [];
  @Input() phoneNumber = '';
  @Output() professorData = new EventEmitter<any>();
  @ViewChild('telInput') telInput: any;

  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: any = {};

  formProfessor: any = new FormGroup({});

  
  iti: any;
  isInvalid = false;
  selectedCountryCode: string = '';

  constructor(
    private escolarService: EscolarService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService
  ) {
    this.initForm();

    this.sections?.map((item: any) => {
      this.dropdownList.push({ item_id: item.section_id, item_text: `${item.section} ${item.grade}` });
    });
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar Todo',
      unSelectAllText: 'Deseleccionar Todo',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  initForm() {
    this.formProfessor = this.fb.group({
      professor_id: [''],
      name: ['', Validators.required],
      p_last_name: ['', Validators.required],
      m_last_name: [''],
      campus_id: ['', Validators.required],
      sections: [''],
      school_id: ['', Validators.required],
      is_active: [],
      phone_number: ['', Validators.required],
      notify: [],
    });
  }

  get ff() {
    return this.formProfessor.controls;
  }

  ngAfterViewInit(){
    this.iti = intlTelInput(this.telInput.nativeElement, {
      initialCountry: "MX",
      nationalMode: false,
      formatOnDisplay: true
    });
    this.selectedCountryCode = this.iti.getSelectedCountryData().dialCode;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['professor'] && changes['professor'].currentValue) {
      this.formProfessor.patchValue({
        professor_id: changes['professor'].currentValue.professor_id,
        name: changes['professor'].currentValue.name,
        p_last_name: changes['professor'].currentValue.p_last_name,
        m_last_name: changes['professor'].currentValue.m_last_name,
        campus_id: changes['professor'].currentValue.campus.campus_id,
        school_id: changes['professor'].currentValue.campus.school_id,
        is_active: changes['professor'].currentValue.user?.is_active,
        phone_number: changes['professor'].currentValue.user?.phone_number,
        notify: changes['professor'].currentValue.user?.notify
      });

      this.selectedCountryCode = changes['professor'].currentValue.user?.phone_number?.replace('+', '').substring(0, 2);
      this.phoneNumber = changes['professor'].currentValue.user?.phone_number.replace(`+${this.selectedCountryCode}`, ''); // ?.substring(0, 3); // changes['professor'].currentValue.user?.phone_number;
      this.loadSections(changes['professor'].currentValue);
    }

    if (changes['sections'] && changes['sections'].currentValue) {
      changes['sections'].currentValue.map((item: any) => {
        this.dropdownList.push({ item_id: item.section_id, item_text: `${item.section} ${item.grade} ${item.group}` });
      });
    }
  }

  loadSections(profesor: Professor) {
    profesor?.sections?.map((item: any) => {
      this.selectedItems.push({ item_id: item.section_id, item_text: `${item.name} ${item.grade}` });
    });
  }

  selectCampus(campus: any) {
    this.escolarService.getSections({ campus_id: campus.target.value }).subscribe((data: any) => {

      data.data.map((item: any) => {
        this.dropdownList.push({ item_id: item.section_id, item_text: `${item.section} ${item.grade}` });
      });
      this.sections = data.data;
    });
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  deleteProfessor() {
    const confirmation = confirm('¿Está seguro de eliminar al profesor?');

    if (!confirmation) {
      return;
    } else {
      this.escolarService.deleteProfessor(this.formProfessor.value.professor_id).subscribe((data: any) => {
      });
    }
  }

  onSubmit() {
    
    if (this.selectedItems.length > 0) {      
      let selectedSections: Section[] = [];
      this.selectedItems.map((item: any) => {
        selectedSections.push({ section_id: item.item_id, section: item.item_text });
      });
      this.formProfessor.patchValue({ sections: selectedSections });
    } else {
      this.toastr.error('Por favor, selecciona al menos un grupo');
      return;
    }

    this.formProfessor.patchValue({ 
      school_id: this.school?.school_id,
      phone_number: `+${this.selectedCountryCode}${this.phoneNumber}`
    });

    if (this.formProfessor.invalid) {
      this.toastr.error('Faltan campos por llenar', 'Error');
      this.formProfessor.markAllAsTouched();
      return;
    }

    this.professorData.emit(this.formProfessor.value);
  }

  ngOnDestroy(){
    this.iti.destroy();
  }

  onFocus = () =>{
    if(this.formProfessor.value.phone_number == undefined || this.formProfessor.value.phone_number == ""){
      var getCode = this.iti.getSelectedCountryData().dialCode;
      this.formProfessor.value.phone_number = "+" + getCode;
    }
  }

  onBlur = ()=>{
    this.isInvalid = false;
    if(this.formProfessor.value.phone_number != undefined && this.formProfessor.value.phone_number.trim()) {
      this.isInvalid = this.iti.isValidNumber() ? false : true;
    }   
  }

  onInputKeyPress = (event: KeyboardEvent) =>{
    const allowedChars = /[0-9\+\-\ ]/;
    const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
    const allowedOtherKeys = [
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
      'Home',
      'End',
      'Insert',
      'Delete',
      'Backspace',
    ];

    if (
      !allowedChars.test(event.key) &&
      !(event.ctrlKey && allowedCtrlChars.test(event.key)) &&
      !allowedOtherKeys.includes(event.key)
    ) {
      event.preventDefault();
    }
  }

  formatIntlTelInput() {
    if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
      var currentText = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
      if (typeof currentText === 'string') { // sometimes the currentText is an object :)
        this.iti.setNumber(currentText); // will autoformat because of formatOnDisplay=true
      }
    }
  }

  onPhoneNumberChange = () =>{
    this.selectedCountryCode = this.iti.getSelectedCountryData().dialCode;
    // this.formatIntlTelInput();
    // this.phoneNumberChange.emit(this.phoneNumber);
  }

}
