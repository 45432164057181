import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, switchMap } from 'rxjs';
import { OffcanvasNoticeFilterComponent } from 'src/app/components/offcanvas/offcanvas-notice-filter.component';
import { Campus } from 'src/app/models/campus.model';
import { Notice } from 'src/app/models/notice.model';
import { School } from 'src/app/models/school.model';
import { Section } from 'src/app/models/section.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-index-notices-bulletin',
  template: `
    
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-2 mb-md-4">
        <img src="https://api.escalas.io{{school.logo!.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <span class="slashy">/</span>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="d-flex mb-3">
          <div class="">
            <h1 class="mb-0">Comunicados <span class="text-muted" style="font-size: 0.5em;">({{meta.total_entries}})</span></h1>
            <!-- xng-breadcrumb ></!-->
          </div>
          <div class="flex-fill text-end mt-0">
            <div class="float-end">
              <!-- div class="btn-group border border-1 border-secondary me-2" role="group" aria-label="Basic example">
                <button id="btnList" #btnList type="button" class="btn btn-outline-secondary" (click)="selectView('btnList')">
                  <fa-icon [icon]="icons.faList"></fa-icon>
                </button>
                <button id="btnBloc" #btnBloc type="button" class="btn btn-outline-secondary" (click)="selectView('btnBloc')">
                <fa-icon [icon]="icons.faGrip"></fa-icon>
                </button>
              </!-->
              <div class="d-inline-block">
                <button class="btn btn-outline-secondary ms-2 border-2" (click)="open()">
                  <fa-icon [icon]="icons.faSliders" class="me-0 me-md-2"></fa-icon>
                  <span class="d-none d-sm-none d-md-inline-block">Filtrar</span>
                </button>
              </div>
              <button class="btn btn-dark ms-2" routerLink="create">
                <fa-icon [icon]="icons.faPlus" class="me-0 me-md-2"></fa-icon>
                <span class="d-none d-sm-none d-md-inline-block">Agregar comunicado</span>
              </button>
            </div>
            <div class="form-group float-end" style="width: 200px;">
              <div class="input-group">
                <div class="input-group-addon border border-2 rounded-start-2 border-secondary border-end-0 ps-2 text-secondary" style="padding-top: 7px;">
                  <fa-icon [icon]="icons.faSearch" ></fa-icon>
                </div>
                <!-- input class="form-control border-2 border-secondary bg-transparent border-start-0" placeholder="Buscar" -->
                <input id="typeahead-basic" type="text" class="form-control border-2 border-secondary bg-transparent border-start-0" [formControl]="searchControl" />
              </div>
            </div>
          </div>
        </div>
      
      <div class="mt-3">

        <table class="table table-hover table-responsive">
          <thead>
            <tr>
              <th class="bg-light text-secondary rounded-top-left-3 align-items-center align-middle"></th>
              <th class="bg-light text-secondary align-items-center align-middle text-start">Título</th>
              <th class="bg-light text-secondary align-items-center align-middle text-center">Remitente</th>
              <th class="bg-light text-secondary align-items-center align-middle text-center">Fecha publicación</th>
              <th class="bg-light text-secondary align-items-center align-middle text-center">Públicado</th>
              <th class="bg-light text-secondary rounded-top-right-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr app-tr-notices
              [notice]="notice"
              [sections]="sections"
              [permissions]="permissions"
              (updateNotice)="saveNotice($event)"
              (deleteNotice)="removeNotice($event)"
              *ngFor="let notice of notices | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }"
            ></tr>
          </tbody>
        </table>
        <div *ngIf="notices.length==0&&loading" class="text-center py-5">
          <fa-icon [icon]="icons.faCircleNotch" size="2x" animation="spin" class="text-secondary"></fa-icon>
          <p class="text-muted">Cargando...</p>
        </div>
        <div class="text-center py-5" *ngIf="notices.length==0&&!loading">
          <img src="/assets/images/empty-state.png" width="240px">
          <p class="text-muted">No se encontraron registros</p>
        </div>

        <!-- div class="list-group rounded-4 border border-4 border-light bg-light" *ngIf="notices.length>0">
          <div class="list-group-item p-0 border-2 border-light rounded-3" style="background-color: #EDEDED;" *ngFor="let notice of notices | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
            <app-item-notice
              [notice]="notice"
              [profile]="profile"
              [permissions]="permissions"
              (updateNotice)="saveNotice($event)"
              (deleteNotice)="removeNotice($event)"
            ></app-item-notice>
          </div>
        </!-->
            
      </div>
      <div class="d-flex justify-content-center">
        <pagination-controls
          (pageChange)="p = $event"
          (pageChange)="pageChanged($event)"
          previousLabel="Prev."
          nextLabel="Sig."
          screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page"
          *ngIf="notices.length>0"
        ></pagination-controls>
      </div>
    </div>
  `,
  styleUrls: []
})
export class IndexNoticesBulletinComponent {
  @ViewChild('btnList') btnList!: ElementRef;
  @ViewChild('btnBloc') btnBloc!: ElementRef;
  
  school: School;
  notices: Notice[] = [];
  campuses: Campus[] = [];
  sections: Section[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};

  searchControl: FormControl = new FormControl(); 

  payload: any = {
    per_page: 100,
    page: 1,
  };

  model: any;
  p: number = 1;
  loading: boolean = false;
  
  constructor(
    private escolarService: EscolarService,
    private toastr: ToastrService,
    public icons: IconsService,
    private offcanvasService: NgbOffcanvas
  ) {
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = localStorage.getItem('campuses') != null ? JSON.parse(localStorage.getItem('campuses')!) : [];
    this.payload.campus_id = this.campuses.map((item: any) => item.campus_id);
    const curr_campus = this.campuses.map((item: any) => item.campus_id);

    this.loadNotices(this.payload);

    this.escolarService.getSections({ campus_id: curr_campus }).subscribe((data: any) => {
      this.sections = data.data;
    });
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => this.escolarService.getNotices({
        name: term, 
        school_id: this.school.school_id
      }))).subscribe((results: any) => {
        this.notices = results.data;
        this.meta = results.meta;
      });
  }

  loadNotices(payload: any): void {
    this.loading = true;
    this.escolarService.getNotices(payload).subscribe((notices: any) => {
      this.notices = notices.data;
      this.meta = notices.meta;
      this.loading = false;
    });
  }

  selectView(event: any): void {}

  open(): void {
    const offcanvasRef = this.offcanvasService.open(OffcanvasNoticeFilterComponent, {
      position: 'end', 
      animation: true, 
      backdrop: true, 
      keyboard: true,
      scroll: true, 
      backdropClass: 'bg-dark', 
      panelClass: 'bg-dark' 
    });
    //
    offcanvasRef.componentInstance.prefs = this.payload;
    offcanvasRef.componentInstance.sections = [];
    offcanvasRef.componentInstance.campuses = [];
    offcanvasRef.result.then((result: any) => {
      if (result) {
        this.payload = result;
        this.loadNotices(this.payload);
      }
    }).catch((error: any) => {
      console.log('error => ', error);
    });
  }

  saveNotice(event: any): void {
  }

  removeNotice(event: any): void {
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadNotices(this.payload);
  }
}
