import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ValidateConfirmpass } from '../../shared/validators/confirmpass.validator';
import { environment } from 'src/environments/environment';
import { IconsService } from 'src/app/shared/services/icons.service';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { EscolarService } from 'src/app/services/escolar.service';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  template: `
    <div class="d-flex pt-2 pt-md-4">
      <div class="flex-fill flex-column text-center">
        <div>
          <img src="assets/images/logo-escalas-r512x512.png" class="img-fluid text-center" width="48px" alt="escalas">
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-2 mt-md-4">
      <button class="gsi-material-button" style="width:340" (click)="signUpWithGoogle()">
        <div class="gsi-material-button-state"></div>
        <div class="gsi-material-button-content-wrapper">
          <div class="gsi-material-button-icon">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span class="gsi-material-button-contents">Continuar con Google</span>
          <span style="display: none;">Continuar con Google</span>
        </div>
      </button>
    </div>
    <div class="d-flex justify-content-center" *ngIf="!accountCreated">
      <div class="flex-grow-2 p-3 mt-5" style="width: 320px;">    
        <h2 class="text-center mb-0 mt-5 mb-3">Crear cuenta</h2>
        <form [formGroup]="signupForm" (ngSubmit)="signup()">
          <div class="form-group text-start mt-4">
            <label for="email" class="text-sm sr-only d-none">Correo electrónico</label>
            <input
              type="email"
              class="form-control form-control-lg border-2 rounded-3 bg-transparent py-3"
              formControlName="email"
              placeholder="Correo electrónico"
              #userEmail
              required
              style="font-size: 14px !important;"
            />
            <div *ngIf="lf['email'].touched && lf['email'].invalid">
              <div *ngIf="lf['email'].errors?.['required']" class="text-danger text-sm">El correo electrónico es requerido.</div>
              <div *ngIf="lf['email'].errors?.['email']" class="text-danger text-sm">El correo electrónico no es válido</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="name" class="text-sm sr-only d-none">Nombre(s)</label>
            <input
              type="text"
              class="form-control form-control-lg border-2 rounded-3 bg-transparent py-3"
              formControlName="name"
              placeholder="Nombre(s)"
              #userEmail
              required
              style="font-size: 14px !important;"
            />
            <div *ngIf="lf['name'].touched && lf['name'].invalid">
              <div *ngIf="lf['name'].errors?.['required']" class="text-danger text-sm">Su nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="last_name" class="text-sm sr-only d-none">Apellidos</label>
            <input
              type="text"
              class="form-control form-control-lg border-2 rounded-3 bg-transparent py-3"
              formControlName="last_name"
              placeholder="Apellidos"                
              #userEmail
              required
              style="font-size: 14px !important;"
            />
            <div *ngIf="lf['last_name'].touched && lf['last_name'].invalid">
              <div *ngIf="lf['last_name'].errors?.['required']" class="text-danger text-sm">Su apellido es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="password" class="text-sm sr-only d-none">Contraseña</label>
            <div class="input-group">
              <input
                [type]="showPassword ? 'text' : 'password'"
                class="form-control form-control-lg border-2 rounded-start-3 rounded-end-0 bg-transparent py-3 border-end-0"
                formControlName="password"
                placeholder="Contraseña"
                minlength="8"
                maxlength="20"
                #userPwd
                required
                style="font-size: 14px !important;"
              />
              <span class="input-group-append">
                <span class="input-group-text bg-transparent rounded-start-0 rounded-end-3 border-2 border-start-0" style="padding-top: 1.3rem !important; padding-bottom: 1.3rem !important;">
                  <!-- i class="icon ion-ios-search"></!-->
                  <fa-icon (click)="togglePassword()" *ngIf="showPassword" [icon]="icons.faEye" class="text-dark me-2"></fa-icon>
                  <fa-icon (click)="togglePassword()" *ngIf="!showPassword" [icon]="icons.faEyeSlash" class="text-dark me-2"></fa-icon>
                </span>
              </span>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="password_confirmation" class="text-sm sr-only d-none">Confirmar Contraseña</label>
            <input
              type="password"
              class="form-control form-control-lg border-2 rounded-3 bg-transparent py-3"
              formControlName="password_confirmation"
              placeholder="Confirmar Contraseña"
              #userPwd
              required
              style="font-size: 14px !important;"
            />
          </div>
          <div class="form-group mt-4">
            <password-strength-meter [password]="signupForm!.value.password" enableFeedback />
          </div>
          <small id="passwordHelpBlock" class="form-text text-muted text-start text-sm">
            Su contraseña debe tener entre 8 y 20 caracteres, contener letras, números, caracteres especiales, y no debe contener espacios, ni emojis.
          </small>

          <div *ngIf="lf['password'].touched && lf['password'].invalid" class="text-start">
            <div *ngIf="lf['password'].errors?.['required']" class="text-danger text-sm">La contraseña es requerido.</div>
            <small *ngIf="lf['password'].errors?.['minlength']" class="text-danger text-sm">La contraseña contener un mínimo 8 caracteres.</small>
            <small *ngIf="lf['password'].errors?.['maxlength']" class="text-danger text-sm">La contraseña contener un máximo 20 caracteres.</small>
            <small *ngIf="!lf['password'].errors?.['minlength'] && lf['password'].errors?.['pattern']" class="text-danger text-sm">La contraseña debe contener al menos un número (0-9), al menos una letra minúscula (a-z), al menos una letra mayúscula (A-Z), y al menos un caracter especial ($&#64;#!%*?&)</small>
          </div>
          <div *ngIf="lf['password_confirmation'].touched && lf['password_confirmation'].invalid" class="text-start">
            <div *ngIf="lf['password_confirmation'].errors?.['required']" class="text-danger text-sm">La confirmación de la contraseña es requerida.</div>
            <small *ngIf="lf['password_confirmation'].errors?.['noMatch']" class="text-danger text-sm">Las contraseñas deben coincidir.</small>
          </div>

          <div class="form-group mt-5 text-center">
            <button type="submit" class="btn btn-escalas w-100 border-0 rounded-3 py-3 shadow-sm">
              <fa-icon [icon]="icons.faCircleNotch" animation="spin" *ngIf="loading" class="text-secondary me-2"></fa-icon>
              <span>Crear cuenta</span>
            </button>
          </div>
        </form>
        <div class="redirectToLogin mt-5">
          <span class="text-sm"
            >¿Ya tienes cuenta?
            <span class="redirect btn btn-link text-sm" routerLink="/sign-in">Iniciar sesión</span></span
          >
        </div>
      </div>
    </div>
    <div *ngIf="accountCreated" class="d-flex justify-content-center">
      <div class="flex-grow-2 p-3 mt-5" style="width: 320px;">
        <h2 class="mb-5 mt-3">Cuenta creada exitosamente</h2>
        <p>Por favor revise su correo electrónico y haga clic en el enlace para verificar su dirección de correo electrónico.</p>
        <span class="text-sm">Regresar?<span class="redirect btn btn-link text-sm" routerLink="/sign-in">Iniciar sesión</span></span>
      </div>
    </div>
  `,
  styleUrls: []
})
export class SignUpComponent implements OnInit {

  protected version = environment.app_version;

  signupForm!: FormGroup;
  loading: boolean = false;
  password: any;
  showPassword: boolean = false;
  accountCreated: boolean = false;

  passwordPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@$!%*?&].{8,}';

  constructor(
    private store: EscolarService,
    public authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public icons: IconsService,
    private router: Router
  ) { }

  get lf() {
    return this.signupForm.controls;
  }

  get currPassword() {
    return this.signupForm.get('password');
  }

  ngOnInit(): void {
    this.initSignupForm();
  }

  initSignupForm(): void {
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.passwordPattern)]],
      password_confirmation: ['', [Validators.required, ValidateConfirmpass]],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  signup() {
    this.loading = true;
    if (this.signupForm.invalid) {
      this.toastr.error('No está completa la información.', 'Error');
      this.loading = false;
      return;
    }

    let payload: any = {
      user: {
        email: this.signupForm.value.email,
        name: this.signupForm.value.name,
        last_name: this.signupForm.value.last_name,
        password: this.signupForm.value.password,
        password_confirmation: this.signupForm.value.password_confirmation,
      }
    };

  }

  async signUpWithGoogle() {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(getAuth(), provider).then(async (result: any) => {
      const idToken = await result.user.getIdToken();

      this.store.verifyUser({
        email: result.user.email,
        name: result.user.displayName,
        photo: result.user.photoURL,
        uid: result.user.uid,
        idToken: idToken,
        refreshToken: result.user.refreshToken
      }, idToken).subscribe((data: any) => {
        if (!data.error) {
          if (this.setUserData(data)) {
            this.store.getRole(data.role_id, idToken).subscribe((role: any) => {
              localStorage.setItem('role', JSON.stringify({ role: role}));
              localStorage.setItem('permissions', role.permissions);

              if (data.school_id) {
                this.store.getSchool(data.school_id).subscribe((school: any) => {
                  localStorage.setItem('school', JSON.stringify(school));

                  this.store.getCampuses({ school_id: school.school_id }).subscribe((campuses: any) => {
                    localStorage.setItem('campuses', JSON.stringify(campuses.data));

                    this.router.navigate(['/dashboard']);
                  }); 
                });
              } else {
                this.router.navigate(['/schools/onboarding']);
                return;
              }
            });
          }
        } else {
          this.toastr.error(data.message, 'Error');
        }
      });
      this.toastr.success('Inicio de sesión exitoso', 'Éxito');
    });
  }

  setUserData(user: any) {
      const userData: User = {
        id: user.user_id,      
        name: user.name,
        last_name: user.last_name,
        email: user.email,      
        uid: user.uid,
        fpm_token: user.token,
        is_active: user.is_active,
        notify: user.notify,
        phone_number: user.phone_number,
        role_id: user.role_id,
        school_id: user.school_id,
      };
  
      localStorage.setItem('token', user.token);
      localStorage.setItem('refresh_token', user.refresh_token);
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('preferences', JSON.stringify({ 
        theme: 'light',
        layout: 'vertical',
        sidebarCollapsed: false,
        viewPrefs: 'btnList',
        per_page: 30,
        campus_id: null,
        section_id: null,
      }));
  
      return true;
    }
}
