import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { School } from 'src/app/models/school.model';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-form-school',
  template: `
  <form [formGroup]="schoolForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="name" class="form-label">Nombre de la escuela</label>
      <input type="text" class="form-control form-control-lg" id="name" formControlName="name">      
    </div>
    <div class="form-group mt-4">
      <label for="description" class="form-label sr-only">Descripción</label>
      <input type="text" class="form-control form-control-lg" placeholder="Descripción corta..." id="description" formControlName="description">
    </div>
    <div class="form-group mt-4">

      <div #colors class="list-group border-0 bg-transparent mt-2">
        <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0 text-start">
          <div>
            <h4 style="font-size: 1em; font-weight: 500;">Colores</h4>
            <p class="text-sm text-muted mb-0" style="font-size: 13px; font-weight: 300;">Selecciona el color de tu escuela, este color se mostrará en la aplicación con el logotipo de tu escuela.</p>
          </div>
        </div>

        <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
          <div>
            <strong>Color de fondo</strong>
          </div>
          <div class="ms-auto text-end">
            <input
              [style.background]="color"
              [(colorPicker)]="color"
              [ngStyle]="{ 'color': color }"
              formControlName="color"
              class="rounded-2 border-1 border-secondary shadow text-center"
              style="width: 44px; height: 44px;"
            />
          </div>
        </div>
        <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
          <div>
            <strong>Color de texto</strong>
          </div>
          <div class="ms-auto text-end">
            <input 
              [value]="color" 
              [style.background]="textColor" 
              [(colorPicker)]="textColor" 
              formControlName="text_color" 
              class="rounded-2 border-1 border-secondary shadow" 
              [ngStyle]="{ 'color': textColor }" 
              (change)="updateTextColor($event)" 
              style="width: 44px; height: 44px;"
            />
          </div>
        </div>
      </div>

      <div class="list-group border-0 mt-2">
        <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0 text-start">
          <div>
            <h4 style="font-size: 1em; font-weight: 500;">Logotipo & vista previa</h4>
            <p class="text-sm text-muted mb-0" style="font-size: 13px; font-weight: 300;">Selecciona el logotipo de tu escuela, Este archivo debe ser en .PNG y las dimensiones mínimas de la imagen es de 514 x 514 y máximas de 2080 x 2080.</p>
          </div>
          <div class="ms-auto text-end ms-5">
            <div class="general-wrapper ms-4">
              <input type="file" id="logo" name="logo" (change)="loadImage($event)" accept="image/*">
              <label for="file">
                Cargar logo
              </label>
            </div>
          </div>
        </div>
        <div class="bg-transparent border-start-0 border-end-0 border-top-0 list-group-item d-flex justify-content-between align-items-center py-3 px-0">
          <div>
          <strong>Logotipo</strong>
          </div>
          <div class="ms-auto text-end"></div>
          <div class="border rounded-4 me-0 text-center align-middle py-0" style="width: 84px; height: 84px; overflow: hidden;">
            <img [src]="imageURL" width="90%" class="m-0">
          </div>
          <!-- h3 class="mt-2" [ngStyle]="{ 'color': textColor }">{{sfc['name'].value}}</!-->
           <!-- class="inputfile" -->
        </div>
      </div>  
    </div>   
    <div class="form-group text-end mt-5" *ngIf="permissions?.modules?.admin?.schools?.write">
      <button type="submit" class="btn btn-escalas" [disabled]="schoolForm.invalid">
        <fa-icon [icon]="icons.faCircleNotch" *ngIf="loading" animation="spin" class="me-2"></fa-icon>
        <fa-icon [icon]="icons.faSave" *ngIf="!loading" class="me-2"></fa-icon>
        Guardar
      </button>
    </div>
  </form>
  `,
  styleUrls: []
})
export class FormSchoolComponent implements OnInit {
  @Input() school: any = {};
  
  imageURL: any = '';
  color: any = "";
  textColor: any = "";
  schoolForm: FormGroup = new FormGroup({})
  loading: boolean = false;
  permissions: any = {};

  constructor(
    private store: EscolarService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public icons: IconsService
  ) { 
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['school'] && changes['school'].currentValue) {
      this.color = this.school.color;
      this.textColor = this.school.text_color;
      this.schoolForm.patchValue({
        name: this.school.name,
        description: this.school.description,
        color: this.school.color,
        text_color: this.school.text_color,
        logo: "", // this.school.logotype,
      });
      this.imageURL = `https://api.escalas.io/${this.school.logo.url}`;
    }
  }

  initForm() {
    this.schoolForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      color: ['', Validators.required],
      text_color: ['', Validators.required],
      logo: [''],
      logotype: ['']
    });
  };

  get sfc () {
    return this.schoolForm.controls
  }

  onSubmit() {
    this.loading = true;

    this.schoolForm.patchValue({
      color: this.color,
      text_color: this.textColor
    });

    if (this.schoolForm.invalid) {
      this.toastr.error('Faltan campos por llenar', 'Error');
      this.loading = false;
      return;
    }

    this.store.updateSchool(this.school.school_id, this.schoolForm.value).subscribe({
      next: (res: any) => {
        const schoolPayload: School = {
          school_id: res.data.id,
          name: res.data.attributes.name,
          description: res.data.attributes.description,
          color: res.data.attributes.color,
          text_color: res.data.attributes.text_color,
          logo: res.data.attributes.logo.record.logo,
          created_at: res.data.attributes.created_at,
          updated_at: res.data.attributes.updated_at
        }
        this.toastr.success('Escuela actualizada correctamente', 'Éxito');
        this.loading = false;
        localStorage.setItem('school', JSON.stringify(schoolPayload));
        // window.location.reload();
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al actualizar la escuela', 'Error');
        this.loading = false;
      }
    });
  }

  updateColor(e: any): void {
    this.schoolForm.patchValue({
      color: e.target.value
    });
  }

  updateTextColor(e: any): void {
    this.schoolForm.patchValue({
      text_color: e.target.value
    });
  }

  loadImage(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    
    reader.onload = (e: any) => {
      this.imageURL = e.target.result;
      this.schoolForm.patchValue({
        logotype: { data: e.target.result, name: file.name, type: file.type }
      });
    }

    reader.readAsDataURL(file);
  }
}
