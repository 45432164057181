import { getAuth } from 'firebase/auth';

import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { AppCheck, AppCheckTokenResult, getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppCheckService {
  app: FirebaseApp;
  appCheck: AppCheck;

  constructor() {
    this.app = initializeApp(environment.firebaseConfig);
    this.appCheck = initializeAppCheck(this.app, {
      provider: new ReCaptchaEnterpriseProvider(environment.siteKey),
      isTokenAutoRefreshEnabled: true
    });
  }

  async getToken(): Promise<AppCheckTokenResult | undefined> {
    let appCheckTokenResponse;
    try {
      appCheckTokenResponse = await getToken(this.appCheck, true);
      return appCheckTokenResponse;
    } catch (err) {
      // TODO: Handle any errors if the token was not retrieved.
      return;
    }
    return appCheckTokenResponse;
  }
}
