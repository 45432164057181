import { Component } from '@angular/core';
import { Campus } from 'src/app/models/campus.model';
import { School } from 'src/app/models/school.model';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-notifications',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo?.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="d-flex mb-4">
        <div class="flex-fill">
          <h1>Notificaciones</h1>
          <!-- p>Consulta los condominios.</!-->
        </div>
        <div class="ms-auto">
        <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.admin.settlements.write" routerLink="/condominios/create/condominio">
        <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
          Agregar
        </!-->
          <!-- button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/bills/new">Crear recibo</!-->
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class NotificationsComponent {
  permissions: any = {};
  school: School;
  campuses: Campus[] = [];
  constructor(
    public icons: IconsService
  ) {
    this.permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
    this.school = JSON.parse(localStorage.getItem('school') || '{}');
    this.campuses = JSON.parse(localStorage.getItem('campuses') || '[]');
  }

}
