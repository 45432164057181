import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { DateService } from 'src/app/shared/services/date.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EscolarService } from 'src/app/services/escolar.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { Event } from 'src/app/models/event.model';
import { School } from 'src/app/models/school.model';
import { Campus } from 'src/app/models/campus.model';

@Component({
  selector: 'app-create-events',
  template: `
    <div class="bg-white p-4 rounded-4 shadow">
      <div class="d-flex mb-4">
        <img src="https://api.escalas.io{{school.logo!.url}}" width="18" height="18" class="ms-2" *ngIf="school">
        <fa-icon [icon]="icons.faChevronRight" class="mx-2" style="font-size: 0.7em; margin-top: 2px;"></fa-icon>
        <xng-breadcrumb></xng-breadcrumb>
      </div>
      <div class="d-flex mb-2">
        <div class="flex-fill">
          <div class="flex-column">
            <h4 class="mb-0 mt-2">Evento</h4>
            <p>Configura la información del evento.</p>
          </div>
          <div class="flex-column">
            <app-form-task
              (taskData)="createEvent($event)"
              [event]="event"
              [currentDay]="currentDay"
              [loading]="loading"
              [school]="school"
              [campuses]="campuses"
            ></app-form-task>
          </div>
        </div>
        <div class="flex-grow-1 ms-3 d-none d-sm-none d-md-block">
          <full-calendar #calendar [options]="calendarOptions"></full-calendar>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class CreateEventsComponent {
  event: Event | null = null;
  permissions: any = {};
  campuses: Campus[] = [];
  school: School | null = null;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;
  
  loading: boolean = false;

  fullTasks: any[] = [];

  currentDay = new Date().toISOString().split('T')[0];

  payload: any = {
    page: 1,
    per_per: 100,
    role_id: 3,
  };

  calendarOptions: CalendarOptions = {    
    plugins: [dayGridPlugin, timeGridPlugin],
    locale: esLocale,
    selectable: true,
    nowIndicator: true,
    displayEventTime: true,
    eventDisplay: 'block',
    eventTimeFormat: { // like '14:30:00'
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
  };

  constructor(
    private escolarService: EscolarService,
    private taskService: TasksService,
    private router: Router,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
    private dateService: DateService,
    public icons: IconsService
  ) {     
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.school = localStorage.getItem('school') != null ? JSON.parse(localStorage.getItem('school')!) : null;
    this.campuses = JSON.parse(localStorage.getItem('campuses') || '[]');
  }

  ngOnInit(): void {
    this.breadcrumbService.set('@index', 'Servicio técnico');
    this.breadcrumbService.set('@tasks', 'Tareas');
    this.breadcrumbService.set('@taskCreate', 'Crear tarea');
  }

  handleDateClick(arg: any) {    
  }


  createEvent(event: any) {
    this.loading = true;
    // this.event.school = this.school;
    this.escolarService.createEvent({ event: event }).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha creado la tarea correctamente.', 'Tarea creada');
        this.loading = false;
        this.router.navigateByUrl('/calendar');        
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido crear la tarea.', 'Error');
      }
    });
  }
}
