import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Section } from 'src/app/models/section.model';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: '[app-tr-professors]',
  template: `
    <td class="align-middle"><input type="checkbox" value="" id="flexCheckDefault"></td>
    <td class="align-middle"><strong>{{professor.name}} {{professor.p_last_name}} {{professor.m_last_name}}</strong></td>
    <td class="align-middle text-center">{{professor?.user?.phone_number}}</td>
    <td class="align-middle text-center">{{professor.campus?.name}}</td>
    <td class="align-middle text-center">
      <div class="badge bg-light text-secondary me-2 mb-1 py-2" *ngFor="let section of professor?.sections">
        {{section.name}} {{section.grade}} {{section.group}}
      </div>
    </td>
    <td class="align-middle text-end"><button class="btn btn-link p-0" routerLink="{{professor.professor_id}}"><fa-icon [icon]="icons.faEdit"></fa-icon></button></td>
  `,
  styleUrls: []
})
export class TrProfessorsComponent {
  @Input() professor: any;
  @Input() sections: Section[] = [];

  @Output() updateTutor = new EventEmitter<any>();
  @Output() deleteTutor = new EventEmitter<any>();

  constructor(
    public icons: IconsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes['sections']&&changes['sections'].currentValue) {
      // this.professor!.generic_id?.split(',').forEach((element: any) => {        
        // if (this.notice.generic_type === 3) {
          // this.selectedSections.push(changes['sections'].currentValue.find((section: any) => { return section.section_id === parseInt(element) }));
        // } else if (this.notice.generic_type === 5) {
        // }
      // });
    // }
  }
}
