import { environment } from '../environments/environment';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DashboardComponent } from './admin/pages/dashboard/dashboard.component';

import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire/compat';
import { AuthModule } from '@angular/fire/auth';
import { AppCheckModule } from '@angular/fire/app-check';
import { FirestoreModule } from '@angular/fire/firestore';
import { StorageModule } from '@angular/fire/storage';

import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

import { AuthService } from './shared/services/auth.service';
import { UsersComponent } from './admin/pages/users/users.component';
import { RolesComponent } from './admin/pages/roles/roles.component';

import { FullCalendarModule } from '@fullcalendar/angular';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './admin/pages/users/profile.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { FilterPipe } from './directives/filter.pipe';

import { FormsModule } from '@angular/forms';
import { FormProvidersComponent } from './components/forms/form-providers.component';
import { FormUserComponent } from './components/forms/form-user.component';
import { RoleCreateComponent } from './admin/pages/roles/role-create.component';
import { RoleEditComponent } from './admin/pages/roles/role-edit.component';
import { FormRoleComponent } from './components/forms/form-role.component';
import { ConfirmPasswordChangeComponent } from './components/confirm-password-change/confirm-password-change.component';

import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';

import { FormTaskComponent } from './components/forms/form-task.component';
import { FormTaskCategoryComponent } from './components/forms/form-task-category.component';
import { ItemTaskComponent } from './components/items/item-task.component';
import { ItemTinyTaskComponent } from './components/items/item-tiny-task.component';

import { ItemProfileComponent } from './components/items/item-profile.component';
import { FormProfileComponent } from './components/forms/form-profile.component';
// Remove the import statement for AppCheckInterceptornvoice-global.component';
import { CurrencyToTextPipe } from './pipes/currency-to-text.pipe';

import { DndModule } from 'ngx-drag-drop';
import { CountUpDirective } from './directives/count-up.directive';
import { ModalHolidayComponent } from './components/modals/modal-holiday.component';
import { MapComponent } from './components/map/map.component';
import { UserCreateComponent } from './admin/pages/users/user-create.component';
import { CalendarEventComponent } from './components/calendar-event/calendar-event.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { NotificationsComponent } from './admin/pages/notifications/notifications.component';
import { NotifyFloaterComponent } from './components/notify-floater/notify-floater.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { EditSchoolComponent } from './admin/pages/schools/edit-school.component';
import { FormSchoolComponent } from './components/forms/form-school.component';
import { IndexNoticesBulletinComponent } from './admin/pages/notices/index-notices-bulletin.component';
import { CreateNoticesBulletinComponent } from './admin/pages/notices/create-notices-bulletin.component';
import { EditNoticesBulletinComponent } from './admin/pages/notices/edit-notices-bulletin.component';
import { EditNoticesMessageComponent } from './admin/pages/notices/edit-notices-message.component';
import { CreateNoticesMessageComponent } from './admin/pages/notices/create-notices-message.component';
import { IndexNoticesMessageComponent } from './admin/pages/notices/index-notices-message.component';
import { FormNoticeComponent } from './components/forms/form-notice.component';
import { ItemNoticeComponent } from './components/items/item-notice.component';
import { MediumEditorDirective } from './directives/medium-editor.directive';
import { FileDragDropDirective } from './directives/file-drag-drop.directive';
import { IndexStudentsComponent } from './admin/pages/students/index-students.component';
import { EditStudentComponent } from './admin/pages/students/edit-student.component';
import { CreateStudentComponent } from './admin/pages/students/create-student.component';
import { ItemStudentComponent } from './components/items/item-student.component';
import { FormStudentComponent } from './components/forms/form-student.component';
import { IndexTutorsComponent } from './admin/pages/tutors/index-tutors.component';
import { FormFamilyComponent } from './components/forms/form-family.component';
import { ItemTutorComponent } from './components/items/item-tutor.component';
import { EditTutorsComponent } from './admin/pages/tutors/edit-tutors.component';
import { CreateTutorsComponent } from './admin/pages/tutors/create-tutors.component';
import { FormTutorComponent } from './components/forms/form-tutor.component';
import { OffcanvasNavbarComponent } from './components/offcanvas/offcanvas-navbar.component';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AppCheckInterceptor } from './services/app-check.interceptor';
import { ItemSectionComponent } from './components/items/item-section.component';
import { FormSectionComponent } from './components/forms/form-section.component';
import { OffcanvasStudentFilterComponent } from './components/offcanvas/offcanvas-student-filter.component';
import { OffcanvasTutorFilterComponent } from './components/offcanvas/offcanvas-tutor-filter.component';
import { OffcanvasNoticeFilterComponent } from './components/offcanvas/offcanvas-notice-filter.component';
import { IndexFamiliesComponent } from './admin/pages/families/index-families.component';
import { EditFamiliesComponent } from './admin/pages/families/edit-families.component';
import { CreateFamiliesComponent } from './admin/pages/families/create-families.component';
import { ItemFamilyComponent } from './components/items/item-family.component';
import { TrStudentsComponent } from './components/rows/tr-students.component';
import { TrFamiliesComponent } from './components/rows/tr-families.component';
import { TrTutorsComponent } from './components/rows/tr-tutors.component';
import { TrNoticesComponent } from './components/rows/tr-notices.component';
import { DashboardSettingComponent } from './admin/pages/settings/dashboard-setting.component';
import { PrivacySettingComponent } from './admin/pages/settings/privacy-setting.component';
import { IndexProfessorsComponent } from './admin/pages/professors/index-professors.component';
import { EditProfessorsComponent } from './admin/pages/professors/edit-professors.component';
import { CreateProfessorsComponent } from './admin/pages/professors/create-professors.component';
import { FormProfessorComponent } from './components/forms/form-professor.component';
import { TrProfessorsComponent } from './components/rows/tr-professors.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ModalSectionComponent } from './components/modals/modal-section.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LiveFeedComponent } from './components/items/live-feed.component';
import { OnBoardingComponent } from './admin/pages/on-boarding/on-boarding.component';
import { FormNoticeImageGeneratorComponent } from './components/forms/form-notice-image-generator.component';
import { ModalImageGeneratorComponent } from './components/modals/modal-image-generator.component';
import { ModalAddCampusComponent } from './components/modals/modal-add-campus.component';
import { IndexMultimediaComponent } from './admin/pages/multimedia/index-multimedia.component';
import { IndexEventsComponent } from './admin/pages/events/index-events.component';
import { EditEventsComponent } from './admin/pages/events/edit-events.component';
import { CreateEventsComponent } from './admin/pages/events/create-events.component';
import { ModalCampusComponent } from './components/modals/modal-campus.component';
import { PasswordStrengthMeterComponent } from 'angular-password-strength-meter';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';
import intlTelInput from 'intl-tel-input';

if (!environment.production) { (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true; }

@NgModule({ 
  declarations: [
    AppComponent,
    DashboardComponent,
    SidebarComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    UsersComponent,
    RolesComponent,
    ProfileComponent,
    FilterPipe,
    FormProvidersComponent,
    FormUserComponent,
    RoleCreateComponent,
    RoleEditComponent,
    FormRoleComponent,
    ConfirmPasswordChangeComponent,
    FormTaskComponent,
    FormTaskCategoryComponent,
    ItemTaskComponent,
    ItemTinyTaskComponent,
    ItemProfileComponent,
    FormProfileComponent,
    CurrencyToTextPipe,
    CountUpDirective,
    ModalHolidayComponent,
    MapComponent,
    UserCreateComponent,
    CalendarEventComponent,
    VideoPlayerComponent,
    NotificationsComponent,
    NotifyFloaterComponent,
    NavbarComponent,
    EditSchoolComponent,
    FormSchoolComponent,
    IndexNoticesBulletinComponent,
    CreateNoticesBulletinComponent,
    EditNoticesBulletinComponent,
    EditNoticesMessageComponent,
    CreateNoticesMessageComponent,
    IndexNoticesMessageComponent,
    FormNoticeComponent,
    ItemNoticeComponent,
    MediumEditorDirective,
    FileDragDropDirective,
    IndexStudentsComponent,
    EditStudentComponent,
    CreateStudentComponent,
    ItemStudentComponent,
    FormStudentComponent,
    IndexTutorsComponent,
    FormFamilyComponent,
    ItemTutorComponent,
    EditTutorsComponent,
    CreateTutorsComponent,
    FormTutorComponent,
    ItemSectionComponent,
    FormSectionComponent,
    OffcanvasStudentFilterComponent,
    OffcanvasTutorFilterComponent,
    OffcanvasNoticeFilterComponent,
    IndexFamiliesComponent,
    EditFamiliesComponent,
    CreateFamiliesComponent,
    ItemFamilyComponent,
    TrStudentsComponent,
    TrFamiliesComponent,
    TrTutorsComponent,
    TrNoticesComponent,
    DashboardSettingComponent,
    PrivacySettingComponent,
    IndexProfessorsComponent,
    EditProfessorsComponent,
    CreateProfessorsComponent,
    FormProfessorComponent,
    TrProfessorsComponent,
    ModalSectionComponent,
    LiveFeedComponent,
    OnBoardingComponent,
    FormNoticeImageGeneratorComponent,
    ModalImageGeneratorComponent,
    ModalAddCampusComponent,
    IndexMultimediaComponent,
    IndexEventsComponent,
    EditEventsComponent,
    CreateEventsComponent,
    ModalCampusComponent,
    OffcanvasNavbarComponent
  ],
  bootstrap: [AppComponent], 
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    NgbModule,
    NgxPaginationModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AuthModule,
    FirestoreModule,
    AppCheckModule,
    StorageModule,
    FullCalendarModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ColorPickerModule,
    ToastrModule.forRoot({
        autoDismiss: false,
        positionClass: 'toast-bottom-left',
        preventDuplicates: true,
    }),
    FormsModule,
    RecaptchaV3Module,
    DndModule,
    PasswordStrengthMeterComponent,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    BrowserAnimationsModule,
    NgxSkeletonLoaderModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    HttpClient,
    AuthService,
    {
        provide: RECAPTCHA_V3_SITE_KEY,
        useValue: environment.siteKey,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AppCheckInterceptor,
        multi: true,
    },
    provideZxvbnServiceForPSM(),
    provideHttpClient(withInterceptorsFromDi())
  ] 
})
export class AppModule { }